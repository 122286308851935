export const COMPANY_CONSTANTS = {
  COMPANY_NAME: "Bombyxx B.V.",
  STREET_AND_NUMBER: "Industrieweg 12a",
  ZIP_AND_CITY: "1775PV Middenmeer",
  COUNTRY: "Nederland",
  KVK: "..",
  VAT: "..",
  WEBSITE_URL: "bombyxx.com",
  WEBSITE_NAME: "Bombyxx",
};
export const LOGO_SRC = "/img/logo.png";
export const REVIEW_QR_CODE = "/img/qr-code/review.png";
