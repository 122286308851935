const base = process.env.REACT_APP_BASEURL;
const dashboardUrl = "/cms";
const authUrl = "/auth";
let mailService = process.env.REACT_APP_API_URL + "/api/email";
let mailchimpService = process.env.REACT_APP_API_URL + "/api/mailchimp";
let exactService = process.env.REACT_APP_API_URL + "/api/exact";

module.exports = {
  base: base,
  dashboard: dashboardUrl,
  auth: authUrl,
  login: authUrl + "/login",
  forgotPassword: authUrl + "/forgot-password",
  acceptInvite: authUrl + "/invite/",
  products: dashboardUrl + "/products",
  categories: dashboardUrl + "/categories",
  productList: dashboardUrl + "/products/product-list",
  addProduct: dashboardUrl + "/products/add-product",
  editProduct: dashboardUrl + "/products/edit-product/",
  featuredProducts: dashboardUrl + "/products/featured",
  discounts: dashboardUrl + "/discounts/",
  promotions: dashboardUrl + "/discounts/promotions",
  discountCodes: dashboardUrl + "/discounts/discount-codes",
  ordersList: dashboardUrl + "/orders/all",
  exactOrdersList: dashboardUrl + "/orders/exact",
  purchaseOrdersList: dashboardUrl + "/orders/purchase-orders",
  customerList: dashboardUrl + "/customers/all",
  customerListPending: dashboardUrl + "/customers/pending",
  users: dashboardUrl + "/admin/users",
  groups: dashboardUrl + "/admin/groups",
  collections: dashboardUrl + "/admin/collections",
  welcome_email: mailService + "/signup/welcome",
  resend_confirmation_email: mailService + "/order/confirmed/resend",
  exactAccount: exactService + "/client",
  exactOrder: exactService + "/sales_order",
  reports: dashboardUrl + "/reports",
  sessions: dashboardUrl + "/sessions",
  sendInvite: mailService + "/invite/cms",
  addUsersMailchimp: mailchimpService + "/add_users",
  getExactOrders: exactService + "/orders",
};
