import _ from "lodash";

export const getProductNameById = (id, products) => {
  if (!!id) {
    let product = _.find(products, (product) => product.id === id);
    return !!product ? product.name : null;
  } else return null;
};

export const getProductCodeById = (id, products) => {
  if (!!id) {
    let product = _.find(products, (product) => product.id === id);
    return !!product ? product.code : null;
  } else return null;
}