import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import All from "./all";
import PendingCustomers from "./pending";

const PARENT_URL = "/cms/customers";

const Customers = () => {
  return (
    <Routes>
      <Route path={`/all`} element={<All />} />
      <Route path={`/pending`} element={<PendingCustomers />} />
      <Route path={``} element={<Navigate to={`${PARENT_URL}/customers`} replace />} />
    </Routes>
  );
};

export default Customers;
