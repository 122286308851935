import i18n from "i18next";
import _ from "lodash";
import * as dayjs from "dayjs";
import { ORDER_STATUS_CREATED } from "../constants/OrderConstant";
import { findCategoryById } from "./categories";

const DATE_FORMAT = "DD-MM-YYYY";

export const getSalesDashboardData = ({ orders, categories, start, end }) => {
  const paidOrders = _.filter(orders, (item) => item.orderStatus !== ORDER_STATUS_CREATED);
  if (!!paidOrders) {
    const today = dayjs();
    let minDate = dayjs.unix(start).format(DATE_FORMAT);
    let maxDate = dayjs.unix(end).format(DATE_FORMAT);
    if (!start || !end) {
      minDate = dayjs(today).subtract(7, "days").format(DATE_FORMAT);
      maxDate = dayjs(today).subtract(1, "days").format(DATE_FORMAT);
    }
    const minDatePrevPeriod = dayjs(minDate, DATE_FORMAT).subtract(7, "days").format(DATE_FORMAT);
    const maxDatePrevPeriod = dayjs(today).subtract(8, "days").format(DATE_FORMAT);
    const dates = getRange(minDate, maxDate, "days");
    const datesPrevPeriod = getRange(minDatePrevPeriod, maxDatePrevPeriod, "days");
    const formattedDates = _.map(dates, (date) => dayjs(date, DATE_FORMAT).format("dd D MMM"));
    let revenues = [];
    let totalRevenue = 0;
    let totalRevenueToday = 0;
    let totalRevenuePrevPeriod = 0;
    let totalOrders = 0;
    let totalOrdersPrevPeriod = 0;
    let ordersInPeriod = [];
    const readableDateRange =
      dayjs(dates[0], DATE_FORMAT).format("D MMM") +
      " - " +
      dayjs(dates[dates.length - 1], DATE_FORMAT).format("D MMM YYYY");
    const boughtProducts = [];

    // Loop over all orders to calculate the desired data
    _.map(paidOrders, (order) => {
      const createdAt = dayjs.unix(order.createdAt).format(DATE_FORMAT);
      // Calculations for current period
      if (dates.includes(createdAt)) {
        ordersInPeriod.push(order);
        const index = _.findIndex(dates, (item) => item === createdAt);
        if (!revenues[index]) revenues[index] = order.totalPrice;
        else revenues[index] += order.totalPrice;
        totalRevenue += order.totalPrice;
        totalOrders++;
        // Loop over all bought products of order to determine amount of sales + revenue per product
        _.map(order.products, (product) => {
          const quantity = product.quantity;
          const paymentPrice = product.paymentPrice;
          const totalRevenue = paymentPrice * quantity;
          const productIndex = _.findIndex(boughtProducts, (item) => item.id === product.productId);
          if (productIndex !== -1) {
            boughtProducts[productIndex].timesBought += quantity;
            boughtProducts[productIndex].totalRevenue += totalRevenue;
          } else {
            const categoryName = findCategoryById(categories, product.category)?.name;
            boughtProducts.push({
              id: product.productId,
              timesBought: quantity,
              totalRevenue: totalRevenue,
              img: product.image[0],
              name: product.name,
              category: categoryName || product.category,
            });
          }
        });
      }
      // Calculations for previous period
      if (!start && !end && datesPrevPeriod.includes(createdAt)) {
        totalRevenuePrevPeriod += order.totalPrice;
        totalOrdersPrevPeriod++;
      }
      if (today.format(DATE_FORMAT) === createdAt) {
        totalRevenueToday += order.totalPrice;
      }
    });

    const bestOrders = _.orderBy(ordersInPeriod, [(o) => o.totalPrice], ["desc"]).slice(0, 5);
    const boughtProductsSorted = _.orderBy(boughtProducts, [(o) => o.totalRevenue], ["desc"]);

    // Make sure all indexes of revenues array have data so no dates are missing in the chart
    formattedDates.forEach((item, index) => {
      if (!revenues[index]) revenues[index] = 0;
    });

    const revenue = {
      dataPoints: [
        {
          name: i18n.t("revenue"),
          data: revenues,
        },
      ],
      labels: formattedDates,
      total: totalRevenue,
      totalToday: totalRevenueToday,
      totalPrevPeriod: totalRevenuePrevPeriod,
      readableDateRange: readableDateRange,
      customDates: !!start && !!end,
    };

    const facts = {
      totalOrders: totalOrders,
      totalOrdersPrevPeriod: totalOrdersPrevPeriod,
      totalRevenue: totalRevenue,
      totalRevenuePrevPeriod: totalRevenuePrevPeriod,
      bestOrder: bestOrders.length > 0 ? bestOrders[0] : null,
    };

    return {
      revenue: revenue,
      facts: facts,
      productSales: { sales: boughtProductsSorted, readableDateRange: readableDateRange },
      bestOrders: { bestOrders: bestOrders, readableDateRange: readableDateRange },
    };
  }
};

function getRange(startDate, endDate, type) {
  let fromDate = dayjs(startDate, DATE_FORMAT);
  let toDate = dayjs(endDate, DATE_FORMAT);
  let diff = toDate.diff(fromDate, type);
  let range = [];
  for (let i = 0; i <= diff; i++) {
    range.push(dayjs(startDate, DATE_FORMAT).add(i, type).format(DATE_FORMAT));
  }
  return range;
}
