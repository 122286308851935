import _ from "lodash";

export const getUserCompanyNameById = (id, users) => {
  let user = _.find(users, (user) => user.id === id);
  return !!user ? user.company.name : null;
};

export const getUserById = (id, users) => {
  let user = _.find(users, (user) => user.id === id);
  return !!user ? user : null;
};

export const getAuthorizedUsers = (users) => {
  let userArray = [];
  _.forEach(users, (user) => {
    if (!!user.authorization && user.authorization.isAuthorized) userArray.push(user);
  });
  return userArray;
};

export const getSalesPersonNameById = (users, id) => {
  let salesPerson = _.find(users, (item) => item.id === id);
  if (!!salesPerson) return salesPerson.firstName + " " + salesPerson.lastName + " - " + salesPerson.company.name;
  else return "-";
};
