import React, { useState } from "react";
import { Button, Card, Form, Input, Menu, message, Modal, Popconfirm, Select, Table, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, InfoCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Flex from "components/shared-components/Flex";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { FIRESTORE_COLLECTIONS_TABLE } from "../../../../constants/FirestoreConstant";
import EllipsisDropdown from "../../../../components/shared-components/EllipsisDropdown";
import _ from "lodash";
import { getProductNameById } from "../../../../functions/products";
import { getGroupNameById } from "../../../../functions/groups";

const { Option } = Select;

const Collections = () => {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const collections = useSelector((state) => state.firestoreReducer.ordered.collections);
  const groups = useSelector((state) => state.firestoreReducer.ordered.groups);
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const [addCollectionForm] = Form.useForm();
  const [editCollectionForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [addCollectionModalVisible, setAddCollectionModalVisible] = useState(false);
  const [editCollectionModalVisible, setEditCollectionModalVisible] = useState(false);
  const [collectionToEdit, setCollectionToEdit] = useState(null);
  const [productsForSelect, setProductsForSelect] = useState(products);

  const addCollection = () => {
    setAddCollectionModalVisible(true);
  };

  const openEditModal = (collection) => {
    setCollectionToEdit(collection);
    let newProductsForSelect = _.clone(products);
    let selectedProducts = [];
    let selectedGroups = [];
    if (!!collection.products) {
      collection.products.forEach((item) => {
        let productName = getProductNameById(item, products);
        newProductsForSelect.push({
          id: item,
          name: productName,
        });
        selectedProducts.push(item);
      });
    }
    if (!!collection.hiddenForGroups) {
      collection.hiddenForGroups.forEach((item) => {
        selectedGroups.push(item);
      });
    }
    setProductsForSelect(newProductsForSelect);
    editCollectionForm.setFieldsValue({
      products: selectedProducts,
      name: collection.name,
      hiddenForGroups: selectedGroups,
    });
    setEditCollectionModalVisible(true);
  };

  const handleUpdateCollection = () => {
    let collectionObj = editCollectionForm.getFieldsValue();
    if (collectionObj.hiddenForGroups.length === 0) collectionObj.hiddenForGroups = null;
    firestore
      .collection(FIRESTORE_COLLECTIONS_TABLE)
      .doc(collectionToEdit.id)
      .update(collectionObj)
      .then(() => {
        closeEditModal();
        messageApi.success(t("collection_saved"), 2);
      });
  };

  const deleteCollection = (collection) => {
    firestore
      .collection(FIRESTORE_COLLECTIONS_TABLE)
      .doc(collection.id)
      .delete()
      .then((r) => {
        messageApi.success(t("collection_removed"));
      });
  };

  const closeAddModal = () => {
    setAddCollectionModalVisible(false);
    addCollectionForm.resetFields();
  };

  const closeEditModal = () => {
    setEditCollectionModalVisible(false);
    setCollectionToEdit(null);
    setProductsForSelect(products);
    editCollectionForm.resetFields();
  };

  const handleAddCollection = () => {
    addCollectionForm.validateFields().then((values) => {
      let object = {
        name: values.name,
        products: !!values.products ? values.products : null,
        hiddenForGroups: !!values.hiddenForGroups ? values.hiddenForGroups : null,
      };
      firestore
        .collection(FIRESTORE_COLLECTIONS_TABLE)
        .add(object)
        .then((response) => {
          addCollectionForm.resetFields();
          closeAddModal();
        })
        .catch((err) => messageApi.error(err.message));
    });
  };

  const tableColumns = [
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("products"),
      dataIndex: "products",
      render: (products) => <span>{!!products ? products.length : 0}</span>,
      sorter: {
        compare: (a, b) => a.products.length - b.products.length,
      },
    },
    {
      title: t("hidden_for"),
      dataIndex: "hiddenForGroups",
      render: (groupIds) => (
        <div>
          {!!groupIds &&
            groupIds.map((item, index) => {
              return (
                <span className={"d-block"} key={index}>
                  {getGroupNameById(item, groups)}
                </span>
              );
            })}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, row) => (
        <div className="text-right">
          <EllipsisDropdown
            menu={
              <Menu>
                <Menu.Item onClick={() => openEditModal(row)}>
                  <Flex alignItems="center">
                    <EditOutlined />
                    <span style={{ marginLeft: 4 }}>{t("edit")}</span>
                  </Flex>
                </Menu.Item>
                <Menu.Item>
                  <Flex alignItems="center">
                    <DeleteOutlined />
                    <Popconfirm
                      placement="bottom"
                      title={t("confirm_collection_products", {
                        name: row.name,
                        product_amount: !!row.products ? row.products.length : 0,
                      })}
                      onConfirm={() => deleteCollection(row)}
                      okText={t("yes")}
                      cancelText={t("no")}
                    >
                      <span style={{ marginLeft: 4 }}>{t("delete")}</span>
                    </Popconfirm>
                  </Flex>
                </Menu.Item>
              </Menu>
            }
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Card>
        <Flex alignItems="between" justifyContent="between" mobileFlex={false}>
          <Flex alignItems="end" className="mb-1" mobileFlex={false}>
            <div>
              <Button onClick={addCollection} type="primary" icon={<PlusCircleOutlined />} block>
                {t("add_collection")}
              </Button>
            </div>
          </Flex>
        </Flex>
        <div className="table-responsive">
          <Table columns={tableColumns} dataSource={collections} rowKey="id" />
        </div>
        <Modal
          title={t("add_collection")}
          visible={addCollectionModalVisible}
          onCancel={() => closeAddModal()}
          onOk={handleAddCollection}
          okText={t("save")}
          cancelText={t("close")}
        >
          <Form layout="vertical" form={addCollectionForm} name="advanced_search" className="ant-advanced-search-form">
            <Form.Item
              name="name"
              label={t("collection_name")}
              rules={[
                {
                  required: true,
                  message: t("form.enter_collection_name"),
                },
              ]}
            >
              <Input
                placeholder={t("name")}
                suffix={
                  <Tooltip title={t("form.add_collection_name_tooltip")}>
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item name="products" label={t("products")}>
              <Select mode="multiple" placeholder={t("products")} showSearch optionFilterProp="children">
                {products.map((product, index) => {
                  return (
                    <Option value={product.id} key={index}>
                      {product.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="hiddenForGroups" label={t("hidden_for_groups")}>
              <Select mode="multiple" placeholder={t("hidden_for_groups_placeholder")}>
                {groups.map((group, index) => {
                  return (
                    <Option value={group.id} key={index}>
                      {group.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>

        {editCollectionModalVisible && (
          <Modal
            title={t("edit_collection")}
            visible={editCollectionModalVisible}
            onCancel={() => closeEditModal()}
            onOk={handleUpdateCollection}
            okText={t("save")}
            cancelText={t("close")}
          >
            <Form
              layout="vertical"
              form={editCollectionForm}
              name="advanced_search"
              className="ant-advanced-search-form"
            >
              <Form.Item
                name="name"
                label={t("collection_name")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_collection_name"),
                  },
                ]}
              >
                <Input placeholder={t("collection_name")} />
              </Form.Item>
              <Form.Item name="products" label={t("products")}>
                <Select mode="multiple" placeholder={t("products")} showSearch optionFilterProp="children">
                  {productsForSelect.map((product, index) => {
                    return (
                      <Option value={product.id} key={index}>
                        {product.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item name="hiddenForGroups" label={t("hidden_for_groups")}>
                <Select mode="multiple" placeholder={t("hidden_for_groups_placeholder")}>
                  {groups.map((group, index) => {
                    return (
                      <Option value={group.id} key={index}>
                        {group.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </Card>
    </>
  );
};

export default Collections;
