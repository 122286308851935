import { FIRESTORE_PRODUCTS_TABLE } from "../../constants/FirestoreConstant";
import firebase from "../../configs/FirebaseConfig";

const firestore = firebase.firestore();

/**
 * Set the property productVariants for all products that have been selected
 * @param firestore Firestore Instance
 * @param productId The ID product where the related products where added to
 * @param productIds The ID of the related products.
 */
export const setRelatedProductVariants = (firestore, productId, productIds) => {
  productIds.forEach((product) => {
    let filteredProductIds = productIds.filter((id) => id.productId !== product.productId);
    let originalProduct = {
      productId: productId,
    };
    filteredProductIds.push(originalProduct);
    let colorVariants = {
      productVariants: filteredProductIds,
    };
    firestore
      .collection(FIRESTORE_PRODUCTS_TABLE)
      .doc(product.productId)
      .update(colorVariants)
      .catch((err) => console.log(err));
  });
};

/**
 * Delete product from product variants in linked products
 * @param firestore Firestore Instance
 * @param products All products
 * @param productId The product id to delete
 */
export const deleteProductLinkedVariants = (firestore, products, productId) => {
  products.forEach((product) => {
    if (product.productVariants && product.id !== productId) {
      let productHasVariant = product.productVariants.find((variant) => {
        return variant.productId === productId;
      });
      if (!!productHasVariant) {
        let updatedProductVariants = product.productVariants.filter((variant) => {
          return variant.productId !== productId;
        });
        let updatedProduct = { productVariants: updatedProductVariants };
        firestore
          .collection(FIRESTORE_PRODUCTS_TABLE)
          .doc(product.id)
          .update(updatedProduct)
          .catch((err) => console.log(err));
      }
    }
  });
};

export const updateProductPrice = (productId, price) => {
  if (!!productId && !!price) {
    firestore
      .collection(FIRESTORE_PRODUCTS_TABLE)
      .doc(productId)
      .update({ price: price })
      .catch((err) => console.log(err));
  }
};
