import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  Empty,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  notification,
  Popconfirm,
  Table,
  Tooltip,
  TreeSelect,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  FileExcelOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AvatarStatus from "components/shared-components/AvatarStatus";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import Flex from "components/shared-components/Flex";
import { useNavigate } from "react-router-dom";
import utils from "utils";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isLoaded, useFirebase, useFirestore } from "react-redux-firebase";
import { deleteAssociatedFilesFromStorage } from "functions/firebase/storage";
import urls from "urls";
import { formatPrice } from "../../../../functions/formatters";
import Loading from "../../../../components/shared-components/Loading";
import {
  getProductCatalogueExcelExportData,
  getProductExcelExportData,
  getProductUrlsExcelExportData,
} from "functions/excel";
import _, { concat } from "lodash";
import { deleteProductLinkedVariants, updateProductPrice } from "../../../../functions/firestore/product";
import { findCategoryById, findCategoryBySlug, getProductsByCategory } from "../../../../functions/categories";
import { useReactToPrint } from "react-to-print";
import CataloguePDF from "../../../../components/util-components/PDF/CataloguePDF";
import { COMPANY_CONSTANTS } from "../../../../constants/CompanyConstants";
import { DATE_FORMAT_DD_MM_YYYY } from "../../../../constants/DateConstant";
import JSZip from "jszip";
import QRCode from "qrcode";
import * as dayjs from "dayjs";
import ExcelExport from "../../../../components/shared-components/ExcelExport";

const ProductList = () => {
  const navigate = useNavigate();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const categories = useSelector((state) => state.firestoreReducer.ordered.categories);
  const purchaseOrders = useSelector((state) => state.firestoreReducer.ordered.purchaseOrders);
  const catalogueRef = useRef(null);
  const [list, setList] = useState(products);
  const [filteredList, setFilteredList] = useState(products);
  const [clickedCategory, setClickedCategory] = useState(localStorage.getItem("categoryFilter") || "all");
  const [tablePage, setTablePage] = useState(parseInt(localStorage.getItem("prdListPagination")) || 1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [exportTableVisible, setExportTableVisible] = useState(false);

  const handlePrintCatalogue = useReactToPrint({
    content: () => catalogueRef.current,
    onAfterPrint: () => {
      return new Promise((resolve, reject) => {
        resolve(setExportTableVisible(false));
      });
    },
    onBeforeGetContent: () => {
      return new Promise((resolve, reject) => {
        resolve(setExportTableVisible(true));
      });
    },
  });

  useEffect(() => {
    if (!!products) setIsLoading(false);
    handleShowCategory(!!clickedCategory ? clickedCategory : "All", tablePage);
  }, [products]);

  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item onClick={() => viewDetails(row)}>
        <Flex alignItems="center">
          <EditOutlined />
          <span style={{ marginLeft: 4 }}>{t("edit")}</span>
        </Flex>
      </Menu.Item>{" "}
      <Menu.Item onClick={() => duplicateRow(row)}>
        <Flex alignItems="center">
          <CopyOutlined />
          <span style={{ marginLeft: 4 }}>{t("duplicate")}</span>
        </Flex>
      </Menu.Item>
      <Menu.Item>
        <Popconfirm
          placement="bottom"
          title={t("confirm_delete_product", { name: row.name })}
          onConfirm={() => beforeDeleteRow(row)}
          okText={t("yes")}
          cancelText={t("no")}
        >
          <a onClick={(e) => e.preventDefault}>
            <DeleteOutlined />
            {t("delete")}
          </a>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const addProduct = () => {
    navigate(urls.addProduct);
  };

  const viewDetails = (row) => {
    navigate(urls.editProduct + row.id);
  };

  const duplicateRow = (row) => {
    const object = _.clone(row);
    delete object.id;
    object.image = null;
    object.createdAt = dayjs().unix();
    object.createdBy = email;
    object.updatedAt = dayjs().unix();
    object.updatedBy = email;
    object.name = object.name + " - Kopie";
    object.url = object.url + "-kopie";
    firestore
      .collection("products")
      .add(object)
      .then((doc) => {
        messageApi.success(t("notifications.product_duplicated", 4));
        viewDetails({ object, id: doc.id });
      })
      .catch((error) => messageApi.error(error.message, 4));
  };

  const beforeDeleteRow = (row) => {
    let associatedProducts = _.filter(products, {
      productItems: [{ productId: row.id }],
    });
    if (associatedProducts.length > 0) {
      let itemsString = "";
      _.forEach(associatedProducts, (item) => (itemsString += item.name + "\n"));
      notification.open({
        message: t("notifications.linked_products_title"),
        description: t("notifications.linked_products_description") + itemsString,
        duration: 0,
        key: row.id,
        btn: (
          <Button
            type="primary"
            onClick={() => {
              deleteRow(row);
              notification.close(row.id);
            }}
          >
            {t("proceed")}
          </Button>
        ),
      });
    } else deleteRow(row);
  };

  const deleteRow = (row) => {
    const objKey = "id";
    let data = list;
    let images = row.image;
    deleteAssociatedFilesFromStorage(firebase, images);
    firestore
      .collection("products")
      .doc(row.id)
      .delete()
      .then(() => {
        data = utils.deleteArrayRow(data, objKey, row.id);
        setList(data);
        messageApi.success(t("notifications.product_deleted", 4));
        deleteAssociatedProductItems(row.id);
      })
      .catch((error) => {
        messageApi.error(error.message, 4);
      });
    deleteProductLinkedVariants(firestore, products, row.id);
  };

  const deleteAssociatedProductItems = (id) => {
    let associatedProducts = _.filter(products, {
      productItems: [{ productId: id }],
    });
    if (associatedProducts.length > 0) {
      _.forEach(associatedProducts, (product) => {
        let productItems = product.productItems;
        let remainingItems = _.filter(productItems, (item) => {
          return item.productId !== id;
        });

        if (remainingItems.length === 0) remainingItems = null;
        firestore.collection("products").doc(product.id).update({ productItems: remainingItems });
      });
    }
  };

  const tableColumns = [
    {
      title: t("product"),
      dataIndex: "name",
      render: (_, record) => (
        <div className="d-flex">
          <AvatarStatus
            size={60}
            type="square"
            src={!!record.image ? record.image[0] : null}
            name={record.name}
            linkTo={urls.editProduct + record.id}
          />
        </div>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "name"),
    },
    {
      title: t("code"),
      dataIndex: "code",
      sorter: (a, b) => utils.antdTableSorter(a, b, "code"),
    },
    {
      title: t("category"),
      dataIndex: "category",
      render: (category) =>
        typeof category === "object" ? (
          category.map((cat, i) => (
            <span key={i}>
              {cat}
              {i !== category.length - 1 && ","}{" "}
            </span>
          ))
        ) : (
          <span>{findCategoryById(categories, category)?.name}</span>
        ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "category"),
    },
    {
      title: t("color"),
      dataIndex: "color",
    },
    {
      title: t("price"),
      dataIndex: "price",
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: "price",
        title: t("price"),
        handleSave,
      }),
      render: (price) => <div style={{ textWrap: "nowrap" }}>€ {formatPrice(price)}</div>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "price"),
    },
    {
      title: t("discount"),
      dataIndex: "discount",
      render: (discount) => <span>{!!discount ? discount : 0}%</span>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "discount"),
    },
    {
      title: t("alternatives"),
      dataIndex: "groupAlternatives",
      render: (groupAlternatives) => <span>{!!groupAlternatives ? t("yes") : t("no")}</span>,
    },
    {
      title: t("stock"),
      dataIndex: "stock",
      sorter: (a, b) => utils.antdTableSorter(a, b, "stock"),
      render: (stock, record) => {
        const orderedQuantity =
          purchaseOrders
            ?.filter((order) => order.status === "ordered")
            .reduce((total, order) => {
              const productOrder = order.products.find((p) => p.productId === record.id);
              return total + (productOrder ? productOrder.amount : 0);
            }, 0) || 0;

        return (
          <Tooltip
            title={
              <div>
                <div>
                  {t("current_stock")}: {stock}
                </div>
                <div>
                  {t("including_ordered")}: {stock + orderedQuantity}
                </div>
              </div>
            }
          >
            <span>{stock}</span>
          </Tooltip>
        );
      },
    },
    {
      title: t("visible_in_shop"),
      dataIndex: "hideVariant",
      render: (hideVariant) => (
        <span>
          {hideVariant ? <CloseOutlined style={{ color: "red" }} /> : <CheckOutlined style={{ color: "green" }} />}
        </span>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "stock"),
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  const blobToBuffer = async (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener("loadend", (e) => resolve(new Uint8Array(e.target.result)));
      reader.addEventListener("error", reject);
      reader.readAsArrayBuffer(blob);
    });
  };

  const handleExportQrCodes = () => {
    if (products.length > 0) {
      const qrPromises = products.map(async (product) => {
        const base64 = await QRCode.toDataURL(process.env.REACT_APP_PRODUCT_URL + product.url, {
          width: 512,
          height: 512,
          margin: 1,
        });
        // Verwijder de 'data:image/png;base64,' prefix
        const base64Data = base64.replace(/^data:image\/png;base64,/, "");
        // Converteer base64 naar binaire data
        const binaryData = atob(base64Data);
        // Converteer binaire string naar Uint8Array
        const uint8Array = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
        return { filename: `${product.code}.png`, fileContent: uint8Array };
      });

      Promise.all(qrPromises)
        .then((files) => {
          const zip = new JSZip();
          files.forEach((file) => {
            zip.file(file.filename, file.fileContent);
          });
          return zip.generateAsync({ type: "blob" });
        })
        .then((content) => {
          const url = window.URL.createObjectURL(content);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Product QR-codes.zip`);
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }, 0);
        })
        .catch((error) => {
          console.error("Error in handleExportQrCodes:", error);
          // Hier kun je een foutmelding aan de gebruiker tonen
        });
    }
  };

  const exportMenu = (
    <Menu>
      <Menu.Item key="1">
        <ExcelExport
          data={getProductExcelExportData(products, categories)}
          fileName={COMPANY_CONSTANTS.WEBSITE_NAME + " producten " + dayjs().format(DATE_FORMAT_DD_MM_YYYY)}
        >
          {t("excel_products")}
        </ExcelExport>
      </Menu.Item>
      <Menu.Item key="2">
        <ExcelExport
          data={getProductUrlsExcelExportData(products)}
          fileName={COMPANY_CONSTANTS.WEBSITE_NAME + " product urls " + dayjs().format(DATE_FORMAT_DD_MM_YYYY)}
        >
          {t("excel_product_urls")}
        </ExcelExport>
      </Menu.Item>
      <Menu.Item key="3">
        <ExcelExport
          data={getProductCatalogueExcelExportData(products, categories)}
          fileName={COMPANY_CONSTANTS.WEBSITE_NAME + " product catalogus " + dayjs().format(DATE_FORMAT_DD_MM_YYYY)}
        >
          {t("excel_product_catalogue")}
        </ExcelExport>
      </Menu.Item>
      <Menu.Item key="4">
        <a href={"#"} onClick={handleExportQrCodes}>
          {t("product_images_qr_codes")}
        </a>
      </Menu.Item>
    </Menu>
  );

  const onSearch = (e) => {
    const value = e.currentTarget.value;
    setSearchText(value);
    const data = utils.wildCardSearchProductName(list, value);
    setFilteredList(data);
  };

  const handleShowCategory = (slug) => {
    localStorage.setItem("categoryFilter", slug);
    localStorage.setItem("prdListPagination", 1);
    setClickedCategory(slug);
    setSearchText("");
    if (slug !== "all") {
      const category = findCategoryBySlug(categories, slug);
      const filteredProducts = getProductsByCategory(products, category);
      setList(filteredProducts);
      setFilteredList(filteredProducts);
    } else {
      setList(products);
      setFilteredList(products);
    }
  };

  const onChangeTablePage = (value) => {
    localStorage.setItem("prdListPagination", value);
    setTablePage(value);
  };

  const handleSave = (row, initialPrice) => {
    const newPrice = row.price;
    if (newPrice !== initialPrice && newPrice > 0) {
      const id = row.id;
      updateProductPrice(id, newPrice);
    }
  };

  const getCatalogueData = () => {
    const clonedProducts = _.cloneDeep(products);
    // Remove all products where code begins with HG or MG
    const filteredProducts = _.filter(clonedProducts, (product) => {
      return !product.code.startsWith("HG") && !product.code.startsWith("MG");
    });

    // Create an object to store the categories and their products
    let categories = {};

    // Create a set to store the ids of the products that have been added
    let addedProductIds = new Set();

    // Go through each product
    filteredProducts.forEach((product) => {
      // For each product, check if its category exists in the categories object
      const category = product.category[product.category.length - 1];
      if (!categories[category]) {
        // If the category does not exist, create a new array for it
        categories[category] = [];
      }

      // If the product has variants and it has not been added yet, add it and its variants
      if (product.productVariants && !addedProductIds.has(product.id)) {
        categories[category].push(product);
        addedProductIds.add(product.id);
        product.productVariants.forEach((variant) => {
          const variantProduct = filteredProducts.find((p) => p.id === variant.productId);
          if (variantProduct && !addedProductIds.has(variantProduct.id)) {
            categories[category].push(variantProduct);
            addedProductIds.add(variantProduct.id);
          }
        });
      } else if (!product.productVariants && !addedProductIds.has(product.id)) {
        // If the product does not have variants and it has not been added yet, add it
        categories[category].push(product);
        addedProductIds.add(product.id);
      }
    });

    return Object.values(categories).flat();
  };

  const components = {
    body: {
      row: PriceRow,
      cell: PriceCell,
    },
  };

  return (
    <>
      {contextHolder}
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 8,
            gap: 8,
            flexWrap: "wrap",
          }}
        >
          <div style={{ display: "flex", gap: 8 }}>
            <Input
              value={searchText}
              placeholder={t("search")}
              prefix={<SearchOutlined />}
              onChange={(e) => onSearch(e)}
            />
            <TreeSelect
              showSearch
              onChange={handleShowCategory}
              style={{ width: "100%", minWidth: 150 }}
              placeholder={t("form.choose_category")}
              allowClear
              treeDefaultExpandAll
              value={clickedCategory || "all"}
              treeData={concat([{ slug: "all" }], categories).map((category) => {
                return {
                  key: category?.slug,
                  title: category?.slug === "all" ? t("all_categories") : category?.name,
                  value: category?.slug,
                  children:
                    !!category?.subcategories &&
                    category.subcategories.map((subcategory) => {
                      return {
                        title: subcategory.name,
                        value: subcategory.slug,
                        key: subcategory.slug,
                      };
                    }),
                };
              })}
            />
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <Button onClick={addProduct} type="primary" icon={<PlusCircleOutlined />} block className="w-auto">
              {t("add_product")}
            </Button>
            <Dropdown overlay={exportMenu}>
              <Button className="ant-btn-primary">
                <FileExcelOutlined /> {t("export")} <DownOutlined />
              </Button>
            </Dropdown>
            <Button onClick={handlePrintCatalogue} icon={<DownloadOutlined />}>
              {t("export_catalogue")}
            </Button>
          </div>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="table-responsive">
            <Table
              components={components}
              columns={tableColumns}
              dataSource={filteredList}
              rowKey="id"
              pagination={{
                current: !!tablePage ? tablePage : 1,
                onChange: onChangeTablePage,
              }}
              locale={{
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("table_no_products")} />,
              }}
              scroll={{ x: 1300 }}
              loading={!isLoaded(products)}
            />
          </div>
        )}
      </Card>
      <div style={{ display: "none" }}>
        {exportTableVisible && (
          <>
            <CataloguePDF ref={catalogueRef} data={getCatalogueData()} />
          </>
        )}
      </div>
    </>
  );
};

const EditableContext = React.createContext(null);

const PriceRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const PriceCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [initialPrice, setInitialPrice] = useState(null);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      if (!initialPrice) setInitialPrice(record.price);
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values }, initialPrice);
    } catch (err) {
      console.log("Save failed:", err);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: t("form.enter_price"),
          },
        ]}
      >
        <InputNumber
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          className="w-100"
          formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default ProductList;
