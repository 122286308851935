import {
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_CREATED,
  ORDER_STATUS_PAID,
  ORDER_STATUS_PAYMENT_PENDING,
  ORDER_STATUS_RETURNED,
  ORDER_STATUS_SHIPPED,
} from "constants/OrderConstant";
import i18n from "translations/i18n";
import { GROUP_TYPE_PRODUCTS, GROUP_TYPE_SETTINGS } from "../constants/GroupConstants";
import {
  CATEGORY_ACCESSORIES,
  CATEGORY_GLASS,
  CATEGORY_LAMPS,
  CATEGORY_MOUTH_VASES,
  CATEGORY_PLATES,
  CATEGORY_SALE,
  CATEGORY_TABLES,
} from "../constants/CategoryConstants";
import { LIST_TYPE_FEATURED, LIST_TYPE_PRIORITY } from "../constants/FeaturedProductsConstants";
import * as dayjs from "dayjs";

export const formatPrice = function (value) {
  if (!!value) {
    return value.toLocaleString("nl-NL", {
      minimumFractionDigits: value % 1 === 0 ? 0 : 2,
      maximumFractionDigits: 2,
    });
  } else return 0;
};

export const getPaymentStatus = (status) => {
  if (status === ORDER_STATUS_PAID) return i18n.t("order.status_paid");
  else if (status === ORDER_STATUS_SHIPPED) return i18n.t("order.status_shipped");
  else if (status === ORDER_STATUS_COMPLETED) return i18n.t("order.status_completed");
  else if (status === ORDER_STATUS_RETURNED) return i18n.t("order.status_returned");
  else if (status === ORDER_STATUS_CREATED) return i18n.t("order.status_created");
  else if (status === ORDER_STATUS_PAYMENT_PENDING) return i18n.t("order.status_payment_pending");
  else if (status === "all") return i18n.t("all");
};

export const formatNameToURL = (name) => {
  name = name.replace(/^\s+|\s+$/g, "");
  name = name.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  let to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    name = name.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // remove invalid chars
  // collapse whitespace and replace by -
  // collapse dashes
  name = name
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return name;
};

export const formatGroupType = (type) => {
  if (type === GROUP_TYPE_PRODUCTS) return i18n.t("product_alternatives");
  else if (type === GROUP_TYPE_SETTINGS) return i18n.t("group_settings");
};

export const getFeaturedListTypeName = (type) => {
  if (type === LIST_TYPE_FEATURED) return i18n.t("featured");
  if (type === LIST_TYPE_PRIORITY) return i18n.t("priority");
};

export const formatOrderDate = (timestamp) => {
  return dayjs.unix(timestamp).format("DD/MM/YYYY");
};

export const formatInvoiceFileName = (invoice, orderId) => {
  return invoice + "-" + orderId + ".pdf";
};

export const formatCategory = (cat, lang) => {
  if (lang === "nl") {
    switch (cat) {
      case CATEGORY_GLASS:
        return "Glaswerk";
      case CATEGORY_LAMPS:
        return "Lampen";
      case CATEGORY_MOUTH_VASES:
        return "Toetervazen";
      case CATEGORY_PLATES:
        return "Dienbladen";
      case CATEGORY_SALE:
        return "Uitverkoop";
      case CATEGORY_ACCESSORIES:
        return "Accessoires";
      case CATEGORY_TABLES:
        return "Tafels";
      default:
        return cat;
    }
  } else {
    switch (cat) {
      case CATEGORY_GLASS:
        return "Glass";
      case CATEGORY_LAMPS:
        return "Lamps";
      case CATEGORY_MOUTH_VASES:
        return "Mouth vases";
      case CATEGORY_PLATES:
        return "Plates";
      case CATEGORY_SALE:
        return "Sale";
      case CATEGORY_ACCESSORIES:
        return "Accessories";
      case CATEGORY_TABLES:
        return "Tables";
      default:
        return cat;
    }
  }
};

export const formatAddress = (address) => {
  return (
    address.street +
    " " +
    address.houseNumber +
    (!!address.addition ? " " + address.addition : "") +
    ", " +
    address.zipCode +
    " " +
    address.city
  );
};
