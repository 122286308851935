import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, InputNumber, Row, Select, Switch } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const { Option } = Select;

const GroupField = ({ groups, alternatives }) => {
  const { t } = useTranslation();
  const [assignedGroups, setAssignedGroups] = useState([]);

  useEffect(() => {
    if (!!alternatives && alternatives.length > 0) {
      let array = [];
      alternatives.forEach((item, index) => {
        array.push(item.groupId);
      });
      setAssignedGroups(array);
    }
  }, [alternatives]);

  const onChangeGroup = (groupId, index) => {
    let cloneAssignedGroups = _.clone(assignedGroups);
    cloneAssignedGroups[index] = groupId;
    setAssignedGroups(cloneAssignedGroups);
  };

  const onRemoveGroupRow = (index) => {
    let cloneAssignedGroups = _.clone(assignedGroups);
    cloneAssignedGroups.splice(index, 1);
    setAssignedGroups(cloneAssignedGroups);
  };

  return (
    <Card title={t("alternatives_for_product")}>
      <Form.List name="groupAlternatives">
        {(fields, { add, remove }) => {
          return (
            <div className="m-3">
              {fields.map((field, index) => (
                <Row key={field.key} gutter={10}>
                  <Col sm={24} md={9}>
                    <Form.Item
                      {...field}
                      label={t("group")}
                      className="w-100"
                      name={[field.name, "groupId"]}
                      fieldKey={[field.fieldKey, "groupId"]}
                      rules={[
                        {
                          required: true,
                          message: t("form.choose_group"),
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        key={index}
                        onChange={(id) => onChangeGroup(id, index)}
                        optionFilterProp="children"
                        filterOption={true}
                      >
                        {groups.map((group) => {
                          if (!assignedGroups.includes(group.id) || assignedGroups[index] === group.id)
                            return (
                              <Option key={group.id} value={group.id}>
                                {group.name}
                              </Option>
                            );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={9}>
                    <Form.Item
                      {...field}
                      label={t("price")}
                      name={[field.name, "price"]}
                      fieldKey={[field.fieldKey, "price"]}
                      className="w-100"
                      initialValue={null}
                    >
                      <InputNumber
                        key={index}
                        className="w-100"
                        formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={4}>
                    <Form.Item
                      {...field}
                      name={[field.name, "visible"]}
                      fieldKey={[field.fieldKey, "visible"]}
                      label={t("visible")}
                      valuePropName="checked"
                      initialValue={true}
                    >
                      <Switch checkedChildren={t("yes")} unCheckedChildren={t("no")} defaultChecked />
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={2} style={{ alignSelf: "center" }}>
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(field.name);
                        onRemoveGroupRow(field.name);
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <hr className="mt-2" />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  className="w-100"
                >
                  <PlusOutlined /> {t("form.add_field")}
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </Card>
  );
};

export default GroupField;
