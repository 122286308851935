import { SAVE_EXACT_ORDERS } from "../constants/Exact";

export function saveExactOrders(orders) {
  return (dispatch) => {
    dispatch({
      type: SAVE_EXACT_ORDERS,
      payload: orders,
    });
  };
}
