import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Orders from "./orders";
import Exact from "./exact";
import PurchaseOrders from "./purchase-orders";

const PARENT_URL = "/cms/orders";

const Bestellingen = () => {
  return (
    <Routes>
      <Route path={`/all`} element={<Orders />} />
      <Route path={`/exact`} element={<Exact />} />
      <Route path={`/purchase-orders`} element={<PurchaseOrders />} />
      <Route path={``} element={<Navigate to={`${PARENT_URL}/all`} replace />} />
    </Routes>
  );
};

export default Bestellingen;
