import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Popconfirm,
  Select,
  Switch,
  Table,
} from "antd";
import Flex from "components/shared-components/Flex";
import * as dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import utils from "utils";
import Modal from "antd/lib/modal/Modal";
import { FIRESTORE_DISCOUNT_CODES_TABLE } from "constants/FirestoreConstant";
import locale from "antd/es/date-picker/locale/nl_NL";
import { DATE_FORMAT_DD_MM_YYYY } from "../../../../constants/DateConstant";
import { InfoCircleOutlined } from "@ant-design/icons";
import { uniqueMergeArrays } from "../../../../functions/arrays";
import { formatCategory } from "../../../../functions/formatters";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

const DiscountList = () => {
  const firestore = useFirestore();
  const discountCodes = useSelector((state) => state.firestoreReducer.ordered.discountCodes);
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  const [editForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [codeToEdit, setCodeToEdit] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [active, setActive] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const categories = uniqueMergeArrays(products, "category");
  const { Option } = Select;

  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          setCodeToEdit(row);
          editForm.setFieldsValue({
            code: row.code,
            discount: !!row.discountPercentage ? row.discountPercentage : 0,
            amount: !!row.discountAmount ? row.discountAmount : 0,
            categories: !!row.categories ? row.categories : [],
            start_date: dayjs(row.startDate, DATE_FORMAT_DD_MM_YYYY),
            expiration_date: dayjs(row.expirationDate, DATE_FORMAT_DD_MM_YYYY),
          });
          setActive(row.active);
          setStartDate(dayjs(row.startDate, DATE_FORMAT_DD_MM_YYYY));
          setEditModalVisible(true);
        }}
      >
        <Flex alignItems="center">
          <span className="ml-2">{t("edit")}</span>
        </Flex>
      </Menu.Item>
      <Menu.Item>
        <Popconfirm
          placement="bottom"
          title={t("delete_discount_code")}
          onConfirm={() => deleteDiscountCode(row)}
          okText={t("yes")}
          cancelText={t("no")}
        >
          <a onClick={(e) => e.preventDefault}>{t("delete")}</a>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const tableColumns = [
    {
      title: t("discount_code"),
      dataIndex: "code",
    },
    {
      title: t("discount"),
      render: (data) => (
        <span>{!!data.discountPercentage ? data.discountPercentage + "%" : "€" + data.discountAmount}</span>
      ),
    },
    {
      title: t("categories"),
      dataIndex: "categories",
      render: (category) =>
        !!category ? (
          category.map((cat, i) => (
            <span key={i}>
              {formatCategory(cat, lang)}
              {i !== category.length - 1 && ","}{" "}
            </span>
          ))
        ) : (
          <span>{t("all_categories")}</span>
        ),
    },
    {
      title: t("start_date"),
      dataIndex: "startDate",
      render: (date) => <span>{date}</span>,
    },
    {
      title: t("expiration_date"),
      dataIndex: "expirationDate",
      render: (date) => <span>{date}</span>,
    },
    {
      title: t("status"),
      render: (_) => <div style={{ display: "flex", gap: 4 }}>{getActiveStatus(_)}</div>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "stock"),
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  const getActiveStatus = (discount) => {
    const today = dayjs();
    const isBetween = today.isBetween(
      dayjs(discount.startDate, DATE_FORMAT_DD_MM_YYYY),
      dayjs(discount.expirationDate, DATE_FORMAT_DD_MM_YYYY),
      undefined,
      "[]",
    );
    if (!discount.active)
      return (
        <>
          <Badge status="warning" />
          <span>{t("inactive")}</span>
        </>
      );
    if (!isBetween)
      return (
        <>
          <Badge status="warning" />
          <span>{t("inactive_period")}</span>
        </>
      );
    else
      return (
        <>
          <Badge status="success" />
          <span>{t("active")}</span>
        </>
      );
  };

  /**
   * Submit edit form
   */
  const onSubmitEdit = () => {
    let originalDiscountCode = codeToEdit;
    editForm
      .validateFields()
      .then(() => {
        setSubmitLoading(true);
        let fields = editForm.getFieldsValue();
        if (!!fields.discount && fields.discount > 0 && !!fields.amount && fields.amount > 0) {
          messageApi.error(t("notifications.discount_percentage_and_amount"));
          setSubmitLoading(false);
        } else if (fields.discount === 0 && fields.amount === 0) {
          messageApi.error(t("notifications.discount_percentage_and_amount_null"));
          setSubmitLoading(false);
        } else {
          let expirationDate = dayjs(fields.expiration_date).format(DATE_FORMAT_DD_MM_YYYY);
          let startDate = dayjs(fields.start_date).format(DATE_FORMAT_DD_MM_YYYY);
          let active = typeof fields.active == "undefined" ? originalDiscountCode.active : fields.active;
          let categories = !!fields.categories && fields.categories.length > 0 ? fields.categories : null;
          firestore
            .collection(FIRESTORE_DISCOUNT_CODES_TABLE)
            .doc(originalDiscountCode.id)
            .update({
              discountPercentage: !!fields.discount ? fields.discount : null,
              discountAmount: !!fields.amount ? fields.amount : null,
              categories: categories,
              startDate: startDate,
              expirationDate: expirationDate,
              active: active,
              updatedAt: firestore.FieldValue.serverTimestamp(),
              updatedBy: email,
            })
            .then(() => {
              setEditModalVisible(false);
              setCodeToEdit(null);
              setSubmitLoading(false);
              messageApi.success(t("notifications.entry_updated", 4));
            })
            .catch((err) => {
              setSubmitLoading(false);
              messageApi.error(err.message);
            });
        }
      })
      .catch((err) => {
        setSubmitLoading(false);
        console.log(err);
      });
  };

  /**
   * Remove discount code from firestore
   * @param discountCodeObj Promotion to delete
   */
  const deleteDiscountCode = (discountCodeObj) => {
    firestore
      .collection(FIRESTORE_DISCOUNT_CODES_TABLE)
      .doc(discountCodeObj.id)
      .delete()
      .then(() => {
        messageApi.success(t("notifications.discount_code_deleted", 4));
      })
      .catch((err) => {
        messageApi.error(err.message);
      });
  };

  return (
    <>
      {contextHolder}
      <Col sm={24} md={18}>
        <Card>
          <div className="table-responsive">
            <Table
              columns={tableColumns}
              dataSource={discountCodes}
              rowKey="id"
              locale={{
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("table_no_discount_code")} />,
              }}
              scroll={{ x: 800 }}
            />
          </div>
        </Card>
        <Modal
          title={t("edit_promotion")}
          visible={editModalVisible}
          footer={null}
          onCancel={() => setEditModalVisible(false)}
        >
          <Form layout="vertical" form={editForm} name="advanced_search" className="ant-advanced-search-form">
            <Form.Item
              name="code"
              label={t("discount_code")}
              rules={[
                {
                  required: true,
                  message: t("form.enter_discount_code"),
                },
              ]}
            >
              <Input placeholder={t("discount_code")} disabled />
            </Form.Item>
            <Form.Item
              name="discount"
              label={t("discount_percentage")}
              tooltip={{ title: i18n.t("form.discount_percentage_tooltip"), icon: <InfoCircleOutlined /> }}
              rules={[
                {
                  required: true,
                  message: t("form.enter_discount_percentage"),
                },
              ]}
            >
              <InputNumber
                className="w-100"
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
              />
            </Form.Item>
            <Form.Item
              name="amount"
              label={t("discount_amount")}
              tooltip={{ title: i18n.t("form.discount_amount_tooltip"), icon: <InfoCircleOutlined /> }}
              rules={[
                {
                  required: true,
                  message: t("form.enter_discount_amount"),
                },
              ]}
            >
              <InputNumber
                className="w-100"
                formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </Form.Item>
            <Form.Item name="categories" label={i18n.t("categories")}>
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder={i18n.t("form.enter_category")}
                notFoundContent={<span>{i18n.t("table_no_categories")}</span>}
              >
                {categories.map((category) => (
                  <Option key={category}>{category}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="start_date"
              label={t("start_date")}
              rules={[
                {
                  required: true,
                  message: t("form.choose_start_date"),
                },
              ]}
            >
              <DatePicker
                locale={locale}
                format={DATE_FORMAT_DD_MM_YYYY}
                placeholder={t("start_date_placeholder")}
                disabledDate={(current) => {
                  return current < dayjs().startOf("day");
                }}
                style={{ width: "100%" }}
                onChange={(value) => {
                  setStartDate(value);
                  let expDate = editForm.getFieldValue("expiration_date");
                  if (!!expDate && expDate <= value) editForm.resetFields(["expiration_date"]);
                }}
              />
            </Form.Item>
            <Form.Item
              name="expiration_date"
              label={t("expiration_date")}
              rules={[
                {
                  required: true,
                  message: t("form.choose_expiration_date"),
                },
              ]}
            >
              <DatePicker
                locale={locale}
                format={DATE_FORMAT_DD_MM_YYYY}
                placeholder={t("expiration_date_placeholder")}
                disabledDate={(current) => {
                  return current < dayjs().endOf("day") || (!!startDate && current < startDate.endOf("day"));
                }}
                showToday={false}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item name="active" label={t("status")}>
              <Switch onClick={() => setActive(!active)} checked={active} />
            </Form.Item>
            <Button type="primary" onClick={() => onSubmitEdit()} htmlType="submit" loading={submitLoading}>
              {t("save")}
            </Button>
          </Form>
        </Modal>
      </Col>
    </>
  );
};

export default DiscountList;
