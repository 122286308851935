import React, { useEffect, useState } from "react";
import PageHeaderAlt from "components/layout-components/PageHeaderAlt";
import { Button, Form, message, Tabs } from "antd";
import Flex from "components/shared-components/Flex";
import GeneralField from "./GeneralField";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { find, findIndex, forEach } from "lodash";
import { compressImage, getBase64 } from "functions/images";
import { useFirebase, useFirestore } from "react-redux-firebase";
import * as dayjs from "dayjs";
import {
  PRODUCT_DIMENSION_TYPE_HEIGHT_DIAMETER,
  PRODUCT_DIMENSION_TYPE_LENGTH_WIDTH,
  PRODUCT_DIMENSION_TYPE_LENGTH_WIDTH_HEIGHT,
  PRODUCT_IMAGE_COMPRESSION_QUALITY,
  PRODUCT_IMAGE_MAX_WIDTH,
} from "constants/ProductConstant";
import { formatNameToURL } from "../../../../functions/formatters";
import VariantField from "./VariantField";
import GroupField from "./GroupField";
import { FIRESTORE_PRODUCTS_TABLE } from "../../../../constants/FirestoreConstant";
import { deleteFilesFromStorage, saveFilesToStorage } from "../../../../functions/firebase/storage";
import { setRelatedProductVariants } from "../../../../functions/firestore/product";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { editProduct } from "../../../../urls";

const { TabPane } = Tabs;

const ADD = "ADD";
const EDIT = "EDIT";
const imagesPath = "products";

const ProductForm = (props) => {
  const { mode = ADD, param } = props;
  const firestore = useFirestore();
  const firebase = useFirebase();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [imageList, setImageList] = useState([]);
  const [originalImageList, setOriginalImageList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [imageOrderChanged, setImageOrderChanged] = useState(false);
  const [newImagesUploaded, setNewImagesUploaded] = useState(false);
  const [showReorderImageNotification, setShowReorderImageNotification] = useState(false);
  const [productDimensionType, setProductDimensionType] = useState(PRODUCT_DIMENSION_TYPE_HEIGHT_DIAMETER);
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const categories = useSelector((state) => state.firestoreReducer.ordered.categories);
  const groups = useSelector((state) => state.firestoreReducer.ordered.groups);
  const productId = !!props.param ? props.param.id : null;
  const clickedProduct = find(products, ["id", productId]);
  const [prevProduct, setPrevProduct] = useState(null);
  const [nextProduct, setNextProduct] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (mode === EDIT) {
      getStorageFiles().then((result) => {
        setImageList(result);
        setOriginalImageList(result);
      });
      form.setFieldsValue({
        url: !!clickedProduct.url ? clickedProduct.url : formatNameToURL(clickedProduct.name),
        name: clickedProduct.name,
        code: clickedProduct.code,
        shortDescription: !!clickedProduct.shortDescription ? clickedProduct.shortDescription : null,
        description: clickedProduct.description,
        category: clickedProduct.category,
        price: clickedProduct.price,
        taxRate: clickedProduct.taxRate,
        discount: clickedProduct.discount,
        stock: clickedProduct.stock,
        min_amount: !!clickedProduct.min_amount ? clickedProduct.min_amount : 1,
        productItems: clickedProduct.productItems,
        additionalInfo: clickedProduct.additionalInfo,
        productVariants: clickedProduct.productVariants,
        variantName: clickedProduct.variantName,
        hideVariant: clickedProduct.hideVariant || false,
        orderUnit: clickedProduct.orderUnit || 1,
        preorder_available_date: !!clickedProduct.pre_order
          ? dayjs(clickedProduct.pre_order.availableAt, "DD-MM-YYYY")
          : false,
        preorder_active: !!clickedProduct.pre_order ? clickedProduct.pre_order.active : false,
        groupAlternatives: clickedProduct.groupAlternatives,
        color: clickedProduct.color,
        height: clickedProduct.height,
        diameter: clickedProduct.diameter,
        length: clickedProduct.length,
        width: clickedProduct.width,
        amountInOuterBox: clickedProduct.amountInOuterBox || 1,
      });
      if (!!clickedProduct.length && !!clickedProduct.width && !!clickedProduct.height)
        setProductDimensionType(PRODUCT_DIMENSION_TYPE_LENGTH_WIDTH_HEIGHT);
      else if (!!clickedProduct.height || !!clickedProduct.diameter)
        setProductDimensionType(PRODUCT_DIMENSION_TYPE_HEIGHT_DIAMETER);
      else setProductDimensionType(PRODUCT_DIMENSION_TYPE_LENGTH_WIDTH);

      const productIndex = findIndex(products, ["id", productId]);
      setPrevProduct(products[(productIndex + products.length - 1) % products.length].id);
      setNextProduct(products[(productIndex + 1) % products.length].id);
    }
  }, [form, mode, param, props, clickedProduct]);

  /**
   * Retrieve files from firebase storage with their links
   * @returns Array of images
   */
  function getStorageFiles() {
    let promises = [];
    if (!!clickedProduct.image) {
      clickedProduct.image.forEach((imageUrl, index) => {
        let imageRef = firebase.storage().refFromURL(imageUrl);
        promises.push(
          imageRef
            .getMetadata()
            .then((info) => {
              return {
                uid: info.name,
                url: imageUrl,
                name: info.name,
                path: info.fullPath,
              };
            })
            .catch(() => {
              // Image does not exist in storage anymore
              return {};
            }),
        );
      });
    }
    return Promise.all(promises);
  }

  const handleUploadChange = ({ fileList }) => {
    let uploadedFile = fileList[fileList.length - 1];
    if (!!uploadedFile && uploadedFile.status === "done") {
      setNewImagesUploaded(true);
      // Compress image before setting imageList state
      compressImage(uploadedFile.originFileObj, PRODUCT_IMAGE_COMPRESSION_QUALITY, PRODUCT_IMAGE_MAX_WIDTH).then(
        (compressedImage) => {
          let compressedImageClone = new Blob([compressedImage], {
            type: compressedImage.type,
          });
          compressedImageClone.name = compressedImage.lastModified + "-" + compressedImage.name;
          // Clone object, it may have some read-only properties
          fileList[fileList.length - 1] = {
            ...fileList[fileList.length - 1],
            originFileObj: compressedImageClone,
            name: compressedImage.lastModified + "-" + compressedImage.name,
            size: compressedImage.size,
          };
          setImageList(fileList);
        },
      );
    } else setImageList(fileList);
  };

  const handleUploadOrderChange = (fileList) => {
    setImageList(fileList);
    setImageOrderChanged(true);
    if (newImagesUploaded) setShowReorderImageNotification(true);
  };

  const handlePreviewCancel = () => {
    setPreviewVisible(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const setProductLoggingProperties = (product, mode) => {
    if (mode === ADD) return { ...product, createdAt: dayjs().unix(), createdBy: email };
    else if (mode === EDIT) return { ...product, updatedAt: dayjs().unix(), updatedBy: email };
  };

  const formatProductUrl = (productName) => {
    if (mode === ADD) {
      let formattedName = formatNameToURL(productName);
      form.setFieldsValue({ url: formattedName });
    }
  };

  const onFinish = () => {
    setSubmitLoading(true);
    const omitFormValues = ["preorder_available_date", "preorder_active"];
    const newProduct = form.getFieldsValue(true, (obj) => !omitFormValues.includes(obj.name[0]));
    console.log("new product: ", newProduct);
    // Set additional items to null instead of undefined if not set
    let productItems = form.getFieldValue("productItems") || null;
    let productVariants = form.getFieldValue("productVariants") || null;
    let additionalInfo = form.getFieldValue("additionalInfo") || null;
    let shortDescription = form.getFieldValue("shortDescription") || null;
    let description = form.getFieldValue("description") || null;
    let preorderAvailableDate = form.getFieldValue("preorder_available_date");
    let preorderActive = form.getFieldValue("preorder_active");
    let groupAlternatives = form.getFieldValue("groupAlternatives") || null;
    let preorderObject = !!preorderAvailableDate
      ? {
          availableAt: dayjs(preorderAvailableDate).format("DD-MM-YYYY"),
          active: preorderActive,
        }
      : null;
    // Some attributes are set to an empty array when deleting them, set to null instead
    if (productItems && productItems.length === 0) productItems = null;
    if (productVariants && productVariants.length === 0) productVariants = null;
    if (groupAlternatives && groupAlternatives.length === 0) groupAlternatives = null;
    newProduct.productItems = productItems;
    newProduct.productVariants = productVariants;
    newProduct.additionalInfo = additionalInfo;
    newProduct.shortDescription = shortDescription;
    newProduct.description = description;
    newProduct.pre_order = preorderObject;
    newProduct.groupAlternatives = groupAlternatives;
    if (productDimensionType === PRODUCT_DIMENSION_TYPE_LENGTH_WIDTH_HEIGHT) {
      newProduct.diameter = null;
    } else if (productDimensionType === PRODUCT_DIMENSION_TYPE_HEIGHT_DIAMETER) {
      newProduct.length = null;
      newProduct.width = null;
    } else {
      newProduct.height = null;
      newProduct.diameter = null;
    }

    // Add logging to product
    let productWithLogging = setProductLoggingProperties(newProduct, mode);

    console.log(productWithLogging);
    console.log(imageList);

    if (imageList.length >= 1) {
      form
        .validateFields()
        .then(() => {
          if (!!productVariants) setRelatedProductVariants(firestore, productId, productVariants);

          /** ADD PRODUCT */
          if (mode === ADD) {
            let imageUrlArray = [];
            let fileArray = [];
            // Push images to array, we will add those to product image array.
            imageList.forEach((item) => {
              fileArray.push(item.originFileObj);
            });
            // Update the product with the new form data
            firestore
              .collection(FIRESTORE_PRODUCTS_TABLE)
              .add(productWithLogging)
              .then((docRef) => {
                saveFilesToStorage(firebase, imagesPath, fileArray, imageUrlArray, docRef.id).catch((err) =>
                  messageApi.error(err.message),
                );
                form.resetFields();
                setImageList([]);
                messageApi.success(t("product_added"), 4);
              })
              .catch((err) => messageApi.error(err.message));
          }
          /** EDIT PRODUCT */
          if (mode === EDIT) {
            let newFiles = [];
            let imageUrlArray = [];

            // Push existing files to imageUrlArray, we do not need to re-upload those.
            // Push new files to newFiles array, we will add those to product image array.
            imageList.forEach((item) => {
              if (!!item.url) imageUrlArray.push(item.url);
              else newFiles.push(item.originFileObj);
            });

            // Go over the original image list and check which ones are removed. Put those in corresponding arrays
            let toDeleteFromStorage = [];
            let toDeleteFromFirestore = [];
            forEach(originalImageList, function (value) {
              if (value.status === "removed") {
                toDeleteFromStorage.push(value.path);
                toDeleteFromFirestore.push(value.url);
              }
            });

            let imageUrls = [];
            // Save new files to storage if added
            if (newFiles.length > 0) {
              saveFilesToStorage(firebase, imagesPath, newFiles, imageUrlArray, productId)
                .then(() => {
                  updateProduct(productWithLogging);
                })
                .catch((err) => messageApi.error(err.message));
            } else if (imageOrderChanged) {
              imageList.forEach((image) => {
                let foundImage = find(imageList, (item) => item.url === image.url);
                if (!!foundImage) {
                  imageUrls.push(image.url);
                }
              });
              productWithLogging.image = imageUrls;
              updateProduct(productWithLogging);
            } else updateProduct(productWithLogging);

            // Delete files from storage if removed
            if (toDeleteFromStorage.length > 0) {
              deleteFilesFromStorage(firebase, firestore, toDeleteFromStorage, productId, toDeleteFromFirestore);
            }

            function updateProduct(updatedProduct) {
              // Update the product with the new form data
              firestore
                .collection(FIRESTORE_PRODUCTS_TABLE)
                .doc(productId)
                .update(updatedProduct)
                .then(() => {
                  messageApi.success(t("product_saved"), 4);
                  setOriginalImageList(imageList);
                  setShowReorderImageNotification(false);
                  setNewImagesUploaded(false);
                })
                .catch((err) => messageApi.error(err.message));
            }
          }
          setSubmitLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setSubmitLoading(false);
          messageApi.error(t("enter_required_data"));
        });
    } else {
      setSubmitLoading(false);
      if (imageList.length < 1) messageApi.error(t("notifications.minimum_product_images"));
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        initialValues={{
          discount: 0,
          stock: 0,
          min_amount: 1,
          hideVariant: false,
          orderUnit: 1,
          amountInOuterBox: 1,
          preorder_active: false,
        }}
      >
        <PageHeaderAlt className="border-bottom" overlap>
          <div className="container">
            <Flex className="py-2" mobileFlex={false} justifyContent="between" alignItems="center">
              <div className={"mb-4"}>
                <h2>{mode === "ADD" ? t("add_new_product") : t("edit_product")} </h2>
                {mode === EDIT && !!prevProduct && !!nextProduct && (
                  <PrevNextProduct>
                    <Link to={editProduct + prevProduct}>
                      <LeftOutlined />
                    </Link>
                    <p>{clickedProduct.name}</p>
                    <Link to={editProduct + nextProduct}>
                      <RightOutlined />
                    </Link>
                  </PrevNextProduct>
                )}
              </div>
              <div className="mb-3">
                <Button type="primary" onClick={() => onFinish()} htmlType="submit" loading={submitLoading}>
                  {mode === "ADD" ? t("add") : t("save")}
                </Button>
              </div>
            </Flex>
          </div>
        </PageHeaderAlt>
        <div className="container">
          <Tabs defaultActiveKey="1" style={{ marginTop: 30 }}>
            <TabPane tab={t("general")} key="1">
              <GeneralField
                form={form}
                formatProductUrl={formatProductUrl}
                products={products}
                fileList={imageList}
                handleUploadChange={handleUploadChange}
                handleUploadOrderChange={handleUploadOrderChange}
                categories={categories}
                handlePreview={handlePreview}
                previewVisible={previewVisible}
                previewImage={previewImage}
                previewTitle={previewTitle}
                handlePreviewCancel={handlePreviewCancel}
                productId={productId}
                showReorderNotification={showReorderImageNotification}
                clickedProduct={clickedProduct || null}
                productDimensionType={productDimensionType}
                setProductDimensionType={setProductDimensionType}
              />
            </TabPane>
            <TabPane tab={t("product_variants")} key="2">
              <VariantField products={products} productId={productId} />
            </TabPane>
            <TabPane tab={t("product_groups_tab")} key="3">
              <GroupField groups={groups} alternatives={!!clickedProduct ? clickedProduct.groupAlternatives : null} />
            </TabPane>
          </Tabs>
        </div>
      </Form>
    </>
  );
};

const PrevNextProduct = styled.div`
  display: flex;

  p {
    font-size: 15px;
    width: 170px;
    margin-left: 4px;
    margin-right: 4px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }

  a {
    font-size: 15px;
    line-height: 27px;
  }
`;

export default ProductForm;
