import _ from "lodash";

export const getUsersWithoutGroup = (users, groups) => {
  let userArray = [];
  _.forEach(users, (user) => {
    let id = user.id;
    let userFound = _.find(groups, (group) => !!group.customers && group.customers.includes(id));
    if (!userFound) userArray.push(user);
  });
  return userArray;
};

/**
 * Get a list of users that is not already in the same group type of another group
 **/
export const getUsersForSelectGroupType = (users, groups, groupType, groupId) => {
  let userArray = [];
  _.forEach(users, (user) => {
    let userId = user.id;
    let userGroupTypes = [!!groupType && groupType];
    let userInSameGroupType = false;
    _.map(groups, (group) => {
      if (!!group.customers && group.customers.includes(userId)) {
        if (userGroupTypes.includes(group.type) && group.id !== groupId) userInSameGroupType = true;
        else userGroupTypes.push(group.type);
      }
    });
    if (!userInSameGroupType) {
      userArray.push(user);
    }
  });
  return userArray;
};

export const getGroupNameById = (id, groups) => {
  if (!!id) {
    let group = _.find(groups, (group) => group.id === id);
    return !!group ? group.name : null;
  } else return null;
};
