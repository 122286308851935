/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Popconfirm,
  Select,
  Switch,
  Table,
} from "antd";
import Flex from "components/shared-components/Flex";
import * as dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import _ from "lodash";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import utils from "utils";
import Modal from "antd/lib/modal/Modal";
import { FIRESTORE_PRODUCTS_TABLE, FIRESTORE_PROMOTIONS_TABLE } from "constants/FirestoreConstant";
import { formatCategory } from "../../../../functions/formatters";

const DiscountList = (props) => {
  const firestore = useFirestore();
  const promotions = useSelector((state) => state.firestoreReducer.ordered.promotions);
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  const [editForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [promotionToEdit, setPromotionToEdit] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [active, setActive] = useState(null);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { products } = props;

  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          setPromotionToEdit(row);
          editForm.setFieldsValue({
            name: row.name,
            discount: row.discount,
            categories: row.categories,
          });
          setActive(row.active);
          setEditModalVisible(true);
        }}
      >
        <Flex alignItems="center">
          <span className="ml-2">{t("edit")}</span>
        </Flex>
      </Menu.Item>
      <Menu.Item>
        <Popconfirm
          placement="bottom"
          title={t("delete_promotion")}
          onConfirm={() => deletePromotion(row)}
          okText={t("yes")}
          cancelText={t("no")}
        >
          <a onClick={(e) => e.preventDefault}>{t("delete")}</a>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const tableColumns = [
    {
      title: t("promotion_name"),
      dataIndex: "name",
    },
    {
      title: t("discount"),
      dataIndex: "discount",
      render: (data) => <span>{data}%</span>,
    },
    {
      title: t("categories"),
      dataIndex: "categories",
      render: (category) =>
        !!category &&
        category.map((cat, i) => (
          <span key={i}>
            {formatCategory(cat, lang)}
            {i !== category.length - 1 && ","}{" "}
          </span>
        )),
    },
    {
      title: t("status"),
      dataIndex: "active",
      render: (active) => <div style={{ display: "flex", gap: 4 }}>{getStockStatus(active)}</div>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "stock"),
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  const getStockStatus = (active) => {
    if (active)
      return (
        <>
          <Badge status="success" />
          <span>{t("active")}</span>
        </>
      );
    else
      return (
        <>
          <Badge status="warning" />
          <span>{t("inactive")}</span>
        </>
      );
  };

  /**
   * Submit edit form
   */
  const onSubmit = () => {
    editForm
      .validateFields()
      .then(() => {
        setSubmitLoading(true);
        let fields = editForm.getFieldsValue();
        let discount = fields.discount;

        updatePromotionInFirestore(discount);
      })
      .catch((err) => {
        setSubmitLoading(false);
        console.log(err);
      });
  };

  /**
   * Apply updates to promotion firestore object
   */
  const updatePromotionInFirestore = () => {
    let originalPromotion = promotionToEdit;
    let updatedPromotion = editForm.getFieldsValue();
    // We need the id in the updatedPromotion object later on
    updatedPromotion.id = originalPromotion.id;
    if (updatedPromotion.active == undefined) updatedPromotion.active = active;
    firestore
      .collection(FIRESTORE_PROMOTIONS_TABLE)
      .doc(originalPromotion.id)
      .update({
        ...updatedPromotion,
        updatedAt: dayjs().unix(),
        updatedBy: email,
      })
      .then(() => {
        // If promotion status changed, either add discounts or remove them
        if (originalPromotion.active !== updatedPromotion.active) {
          if (updatedPromotion.active) applyDiscountToProducts(updatedPromotion);
          else removeDiscountFromProducts(updatedPromotion);
          // If discount percentage changed, apply the changes
        } else if (originalPromotion.discount !== updatedPromotion.discount) {
          applyDiscountToProducts(updatedPromotion);
          // Nothing changed besides name, close the modal
        } else {
          setEditModalVisible(false);
          setPromotionToEdit(null);
          setSubmitLoading(false);
          messageApi.success(t("notifications.entry_updated", 4));
        }
      })
      .catch((err) => {
        setSubmitLoading(false);
        messageApi.error(err.message);
      });
  };

  /**
   * Remove promotion from firestore
   * @param {} promotion Promotion to delete
   */
  const deletePromotion = (promotion) => {
    firestore
      .collection(FIRESTORE_PROMOTIONS_TABLE)
      .doc(promotion.id)
      .delete()
      .then(() => {
        // After deleting promotion, remove discount from associated products
        removeDiscountFromProducts(promotion);
        messageApi.success(t("notifications.promotion_deleted", 4));
      })
      .catch((err) => {
        messageApi.error(err.message);
      });
  };

  /**
   * Apply updated discount to all associated products of promotion
   * @param {} updatedPromotion The promotion object containing discount %
   */
  const applyDiscountToProducts = (updatedPromotion) => {
    let selectedCategories = updatedPromotion.categories;
    let updatedDiscount = updatedPromotion.discount;
    let filteredProducts = getFilteredProducts(selectedCategories);

    if (!!filteredProducts) {
      // Get a new write batch
      let batch = firestore.batch();

      // Apply discount to all products that belong to (categories in) promotion
      filteredProducts.forEach((item) => {
        let ref = firestore.collection(FIRESTORE_PRODUCTS_TABLE).doc(item.id);
        batch.update(ref, { discount: updatedDiscount });
      });

      // Commit the batch
      batch
        .commit()
        .then(() => {
          editForm.resetFields();
          setEditModalVisible(false);
          setPromotionToEdit(null);
          setSubmitLoading(false);
          messageApi.success(t("notifications.entry_updated", 4));
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * Remove discount from all products associated with promotion
   * @param {*} promotion Promotion that has been deleted/deactivated
   */
  const removeDiscountFromProducts = (promotion) => {
    let selectedCategories = promotion.categories;
    let filteredProducts = getFilteredProducts(selectedCategories);

    let activePromotions = _.filter(promotions, function (item) {
      return item.active && item.id !== promotion.id;
    });

    if (!!filteredProducts) {
      // Get a new write batch
      let batch = firestore.batch();

      // Remove discount from all products that belong to (categories in) promotion
      filteredProducts.forEach((item) => {
        let ref = firestore.collection(FIRESTORE_PRODUCTS_TABLE).doc(item.id);
        let newDiscount = 0;

        // Check if other promotion is active with discount. If so, apply that discount %
        let categoryHasPromotion = _.find(activePromotions, function (o) {
          return o.categories.some((r) => item.category.includes(r));
        });
        if (!!categoryHasPromotion) newDiscount = categoryHasPromotion.discount;

        batch.update(ref, { discount: newDiscount });
      });

      // Commit the batch
      batch
        .commit()
        .then(() => {
          editForm.resetFields();
          setEditModalVisible(false);
          setPromotionToEdit(null);
          setSubmitLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  /**
   * Get products by category
   * @param {*} categories Categories of which we want the products
   * @returns Filtered products in array
   */
  const getFilteredProducts = (categories) => {
    let filteredProducts = _.filter(products, function (item) {
      return _.intersection(item.category, categories).length > 0;
    });

    return filteredProducts;
  };

  return (
    <>
      {contextHolder}
      <Col sm={24} md={18}>
        <Card>
          <div className="table-responsive">
            <Table
              columns={tableColumns}
              dataSource={promotions}
              rowKey="id"
              locale={{
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("table_no_promotions")} />,
              }}
            />
          </div>
        </Card>
        <Modal
          title={t("edit_promotion")}
          visible={editModalVisible}
          footer={null}
          onCancel={() => setEditModalVisible(false)}
        >
          <Form layout="vertical" form={editForm} name="advanced_search" className="ant-advanced-search-form">
            <Form.Item
              name="name"
              label={t("promotion_name")}
              rules={[
                {
                  required: true,
                  message: t("form.enter_promotion_name"),
                },
              ]}
            >
              <Input placeholder={t("promotion_name")} />
            </Form.Item>
            <Form.Item
              name="discount"
              label={t("discount")}
              rules={[
                {
                  required: true,
                  message: t("form.enter_discount_category"),
                },
              ]}
            >
              <InputNumber
                className="w-100"
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
              />
            </Form.Item>
            <Form.Item
              name="categories"
              label={t("categories")}
              rules={[
                {
                  required: true,
                  message: t("form.enter_category"),
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder={t("form.enter_category")}
                notFoundContent={<span>{t("table_no_categories")}</span>}
                disabled
              ></Select>
            </Form.Item>
            <Form.Item name="active" label={t("status")}>
              <Switch onClick={() => setActive(!active)} checked={active} />
            </Form.Item>
            <Button type="primary" onClick={() => onSubmit()} htmlType="submit" loading={submitLoading}>
              {t("save")}
            </Button>
          </Form>
        </Modal>
      </Col>
    </>
  );
};

export default DiscountList;
