import React from "react";
import Barcode from "react-barcode";

const BarcodePDF = React.forwardRef((props, ref) => {
  const { data } = props;

  // Maak een aparte component voor elke pagina
  const BarcodePages =
    data && data.length > 0
      ? data.map((product, index) => (
          <div key={index} style={styles.page}>
            <div style={styles.container}>
              <div style={styles.contentContainer}>
                <p style={styles.textBold}>{product.name}</p>
                <p style={styles.text}>{product.code}</p>
                <Barcode value={product.barcode} style={styles.barcode} height={30} fontSize={6} />
              </div>
            </div>
          </div>
        ))
      : null;

  return <div ref={ref}>{BarcodePages}</div>;
});

const TEXT_SIZE = 8;
const TEXT_COLOR = "#3a3a3a";

const styles = {
  page: {
    padding: 12,
    pageBreakAfter: "always",
  },
  container: {
    display: "block",
    height: "100%",
    width: "100%",
    flex: 1,
  },
  contentContainer: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontSize: TEXT_SIZE,
    color: TEXT_COLOR,
    margin: 0,
  },
  textBold: {
    fontSize: TEXT_SIZE,
    fontWeight: 700,
    color: TEXT_COLOR,
    margin: 0,
  },
  barcode: {
    display: "block",
  },
};

export default BarcodePDF;
