import * as dayjs from "dayjs";
import { DATE_FORMAT_DD_MM_YYYY, DATE_FORMAT_DD_MM_YYYY_HH_mm } from "constants/DateConstant";
import i18n from "translations/i18n";
import { Trans } from "react-i18next";
import { formatPrice, getPaymentStatus } from "./formatters";
import _ from "lodash";
import { PRODUCT_COLORS } from "../constants/ProductConstant";

export function getExcelExportData(rows) {
  return [
    {
      columns: [
        { title: "Bestelnummer" },
        { title: "Besteldatum" },
        { title: "Bedrijfsnaam" },
        { title: "Naam" },
        { title: "Straat" },
        { title: "Huisnr + toev." },
        { title: "Postcode" },
        { title: "Plaats" },
        { title: "Telefoon" },
        { title: "Email" },
        { title: "Status" },
        { title: "Bezorg/afhaaldatum" },
        { title: "Bestelnotities" },
        { title: "Producten" },
        { title: "Totaalprijs" },
      ],
      data:
        rows.length > 0
          ? rows.map((row) => {
              let address = row.customer.address.delivery;
              let products = row.products;
              let productString = "";
              if (!!products) {
                products.forEach((product, index) => {
                  if (index !== products.length - 1) productString += product.quantity + "x " + product.name + "\n";
                  else productString += product.quantity + "x " + product.name;
                });
              }
              return [
                { value: row.orderId },
                { value: dayjs.unix(row.createdAt).format(DATE_FORMAT_DD_MM_YYYY_HH_mm) },
                { value: address.companyName || "" },
                { value: address.firstName + " " + address.lastName },
                { value: address.street },
                { value: address.houseNumber + (address.addition || "") },
                { value: address.zipCode },
                { value: address.city },
                { value: row.customer.phoneNumber },
                { value: row.customer.email },
                { value: getPaymentStatus(row.orderStatus) },
                { value: dayjs(row.delivery.date, DATE_FORMAT_DD_MM_YYYY).format(DATE_FORMAT_DD_MM_YYYY) },
                { value: row.orderNotes || "-" },
                { value: productString },
                { value: formatPrice(row.totalPrice) },
              ];
            })
          : [],
    },
  ];
}

export function getCustomersExcelExportData(users, countryCode) {
  const countryUsers = users.filter((user) => user.company?.country === countryCode);
  return {
    sheetName: countryCode,
    columns: [
      { title: "Bedrijf" },
      { title: "Land" },
      { title: "Stad" },
      { title: "BTW-nummer" },
      { title: "Naam" },
      { title: "E-mail" },
      { title: "Telefoon" },
      { title: "Toegang" },
      { title: "Straat (bezorging)" },
      { title: "Huisnr + toev. (bezorging)" },
      { title: "Postcode (bezorging)" },
      { title: "Plaats (bezorging)" },
      { title: "Straat (factuur)" },
      { title: "Huisnr + toev. (factuur)" },
      { title: "Postcode (factuur)" },
      { title: "Plaats (factuur)" },
    ],
    data:
      countryUsers.length > 0
        ? countryUsers.map((row) => {
            let delivery = !!row.address && row.address.delivery;
            let invoice = !!row.address && row.address.invoice ? row.address.invoice : null;
            return [
              { value: row.company?.name },
              { value: row.company?.country || "-" },
              { value: row.company?.city || "-" },
              { value: row.company?.vatNumber || "-" },
              { value: row.firstName + " " + row.lastName },
              { value: row.email || "" },
              { value: row.phoneNumber || "" },
              { value: !!row.authorization && row.authorization.isAuthorized ? "Ja" : "Nee" },
              { value: !!delivery && delivery.street ? delivery.street : "-" },
              {
                value:
                  !!delivery && delivery.houseNumber
                    ? delivery.houseNumber + (!!delivery && !!delivery.addition ? delivery.addition || "" : "")
                    : "-",
              },
              { value: !!delivery && delivery.zipCode ? delivery.zipCode : "-" },
              { value: !!delivery && delivery.city ? delivery.city : "-" },
              { value: !!invoice && !!invoice.street ? invoice.street : "-" },
              {
                value:
                  !!invoice && !!invoice.houseNumber
                    ? invoice.houseNumber + (!!invoice && !!invoice.addition ? invoice.addition || "" : "")
                    : "-",
              },
              { value: !!invoice && !!invoice.zipCode ? invoice.zipCode : "-" },
              { value: !!invoice && !!invoice.city ? invoice.city : "-" },
            ];
          })
        : [],
  };
}

export const productImportSchema = {
  Naam: {
    prop: "name",
    type: String,
  },
  Beschrijving: {
    prop: "description",
    type: String,
    required: true,
  },
  Voorraad: {
    prop: "stock",
    type: Number,
    required: true,
  },
  Min_Aantal: {
    prop: "min_amount",
    type: Number,
    required: true,
  },
  Prijs: {
    prop: "price",
    type: Number,
    required: true,
  },
  BTW_Tarief: {
    prop: "taxRate",
    type: Number,
    required: true,
  },
  Korting: {
    prop: "discount",
    type: Number,
    required: true,
  },
  Categorieën: {
    prop: "category",
    type: [String],
    required: true,
  },
  Type: {
    prop: "type",
    type: [String],
    required: false,
  },
  Variaties: {
    prop: "variations",
    type: {
      Var_Prijs: {
        prop: "price",
        type: [Number],
      },
      Var_Gewicht: {
        prop: "weight",
        type: [String],
      },
      Var_Type: {
        prop: "type",
        type: [String],
      },
    },
  },
};

export const getExcelImportErrorMessage = (error) => {
  return (
    <Trans
      i18nKey="notifications.excel_import_error"
      values={{
        column: error.column,
        row: error.row + 1,
        error: i18n.t(error.error),
      }}
    />
  );
};

export const getShopifyProductsCsvExportData = (products) => {
  const productData = products.map((product) => {
    return [
      "bombyxx-" + product.url,
      "Bombyxx " + product.name,
      ,
      "Bombyxx",
      product.category[0],
      product.category[0],
      ,
      "TRUE",
      ,
      ,
      ,
      ,
      ,
      ,
      product.code,
      ,
      ,
      ,
      ,
      ,
      product.price,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      "g",
      ,
      product.price,
      ,
      ,
      "active",
    ];
  });

  return [
    [
      "Handle",
      "Title",
      "Body (HTML)",
      "Vendor",
      "Product Category",
      "Type",
      "Tags",
      "Published",
      "Option1 Name",
      "Option1 Value",
      "Option2 Name",
      "Option2 Value",
      "Option3 Name",
      "Option3 Value",
      "Variant SKU",
      "Variant Grams",
      "Variant Inventory Tracker",
      "Variant Inventory Qty",
      "Variant Inventory Policy",
      "Variant Fulfillment Service",
      "Variant Price",
      "Variant Compare At Price",
      "Variant Requires Shipping",
      "Variant Taxable",
      "Variant Barcode",
      "Image Src",
      "Image Position",
      "Image Alt Text",
      "Gift Card",
      "SEO Title",
      "SEO Description",
      "Google Shopping / Google Product Category",
      "Google Shopping / Gender",
      "Google Shopping / Age Group",
      "Google Shopping / MPN",
      "Google Shopping / AdWords Grouping",
      "Google Shopping / AdWords Labels",
      "Google Shopping / Condition",
      "Google Shopping / Custom Product",
      "Google Shopping / Custom Label 0",
      "Google Shopping / Custom Label 1",
      "Google Shopping / Custom Label 2",
      "Google Shopping / Custom Label 3",
      "Google Shopping / Custom Label 4",
      "Variant Image",
      "Variant Weight Unit",
      "Variant Tax Code",
      "Cost per item",
      "Price / International",
      "Compare At Price / International",
      "Status",
    ],
    ...productData,
  ];
};

export function getProductExcelExportData(products, categories) {
  const clonedProducts = _.cloneDeep(products);
  // Remove all products where code begins with HG or MG
  const filteredProducts = _.filter(clonedProducts, (product) => {
    return !product.code?.startsWith("HG") && !product.code?.startsWith("MG");
  });

  // Create an object to store the categories and their products
  let productCategories = {};

  // Create a set to store the ids of the products that have been added
  let addedProductIds = new Set();

  // Go through each product
  filteredProducts.forEach((product) => {
    // For each product, check if its category exists in the categories object
    const category = product.category;
    if (!productCategories[category]) {
      // If the category does not exist, create a new array for it
      productCategories[category] = [];
    }

    // If the product has variants and it has not been added yet, add it and its variants
    if (product.productVariants && !addedProductIds.has(product.id)) {
      productCategories[category].push(product);
      addedProductIds.add(product.id);
      product.productVariants.forEach((variant) => {
        const variantProduct = filteredProducts.find((p) => p.id === variant.productId);
        if (variantProduct && !addedProductIds.has(variantProduct.id)) {
          productCategories[category].push(variantProduct);
          addedProductIds.add(variantProduct.id);
        }
      });
    } else if (!product.productVariants && !addedProductIds.has(product.id)) {
      // If the product does not have variants and it has not been added yet, add it
      productCategories[category].push(product);
      addedProductIds.add(product.id);
    }
  });

  const allProducts = Object.values(productCategories).flat();

  return [
    {
      columns: [
        { title: "Productnaam" },
        { title: "Code" },
        { title: "Prijs" },
        { title: "Minimale afname" },
        { title: "Besteleenheid" },
        { title: "Aantal in omdoos" },
        { title: "Voorraad" },
      ],
      data:
        allProducts.length > 0
          ? allProducts.map((row) => {
              return [
                { value: row.name },
                { value: row.code },
                { value: `€ ${row.price}` },
                { value: row.min_amount || 1 },
                { value: row.orderUnit || 1 },
                { value: row.amountInOuterBox || 1 },
                { value: row.stock },
              ];
            })
          : [],
    },
  ];
}

export function getProductUrlsExcelExportData(products) {
  let clonedProducts = _.cloneDeep(products);
  // Sort by code
  const sortedProducts = _.sortBy(clonedProducts, (product) => product.code);

  return [
    {
      columns: [{ title: "Code" }, { title: "Productnaam" }, { title: "URL" }],
      data:
        sortedProducts.length > 0
          ? sortedProducts.map((row) => {
              return [{ value: row.code }, { value: row.name }, { value: `https://bombyxx.com/product/${row.url}` }];
            })
          : [],
    },
  ];
}

export function getProductCatalogueExcelExportData(products, categories) {
  let clonedProducts = _.cloneDeep(products);
  // Sort by code
  const sortedProducts = _.sortBy(clonedProducts, (product) => product.code);

  // Remove certain categories
  const categorySlugs = ["accessories", "sale"];
  const categoryIds = categories
    ?.filter((category) => categorySlugs.includes(category.slug))
    .map((category) => category.id);
  const filteredProducts = _.filter(sortedProducts, (product) => {
    return !categoryIds?.includes(product.category);
  });

  return [
    {
      columns: [
        { title: "Code" },
        { title: "Productnaam" },
        { title: "Hoogte" },
        { title: "Diameter" },
        { title: "Lengte" },
        { title: "Breedte" },
        { title: "Kleur" },
        { title: "Verkoopprijs" },
        { title: "URL" },
        { title: "@Image" },
        { title: "QR Image" },
      ],
      data:
        filteredProducts.length > 0
          ? filteredProducts.map((row) => {
              const color = PRODUCT_COLORS?.find((color) => color.id === row.color);
              const colorName = color ? color.name : "";
              const imageUrl = !!row.image && row.image.length > 0 ? row.image[0] : "";
              const imageName = extractImageName(imageUrl);

              return [
                { value: row.code?.trim() },
                { value: row.name },
                { value: row.height || "" },
                { value: row.diameter || "" },
                { value: row.length || "" },
                { value: row.width || "" },
                { value: colorName },
                { value: row.price },
                { value: `https://bombyxx.com/product/${row.url}` },
                { value: imageName },
                { value: `${row.code?.trim()}.png` },
              ];
            })
          : [],
    },
  ];
}

export const extractImageName = (imageUrl) => {
  const match = imageUrl.match(/(?:.*\/)?(\d{13}-.*)$/);
  if (match) {
    return match[1].split("?")[0];
  }
  return "";
};
