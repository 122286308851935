import { EMAIL_API_FAILURE_RESPONSE } from "../../constants/ResponseConstant";
import { welcome_email } from "../../urls";
import { LOCALE_NL } from "../../constants/LocaleConstants";

export const sendWelcomeEmail = (emailAddress, firstName, locale, createdBySalesPerson) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: emailAddress,
      firstName: firstName,
      locale: !!locale ? locale : LOCALE_NL,
      sendResetPasswordMail: createdBySalesPerson,
    }),
  };
  fetch(welcome_email, requestOptions).catch(() => console.error(EMAIL_API_FAILURE_RESPONSE));
};