import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Modal, Popconfirm, Row, Select, Space, Table } from "antd";
import { FIRESTORE_CATEGORIES_TABLE } from "../../../constants/FirestoreConstant";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFirestore } from "react-redux-firebase";
import { formatNameToURL } from "../../../functions/formatters";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import * as dayjs from "dayjs";

export default function Categories() {
  const { t } = useTranslation();
  const categories = useSelector((state) => state.firestoreReducer.ordered.categories);
  const firestore = useFirestore();
  const [addForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [list, setList] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  useEffect(() => {
    setList(categories);
  }, [categories]);

  const deleteCategory = async (category) => {
    // Delete product from Firestore
    firestore
      .collection(FIRESTORE_CATEGORIES_TABLE)
      .doc(category.id)
      .delete()
      .then(() => {
        setList(list.filter((item) => item.id !== category.id));
      });
  };

  const handleCreateCategory = () => {
    addForm.validateFields().then(async (values) => {
      values.categoryId = uuidv4();
      values.synonyms = !!values.synonyms ? formatSynonyms(values.synonyms) : [];
      values.subcategories = !!values.subcategories ? values.subcategories : [];
      values.subcategories = values.subcategories.map((subcategory) => ({
        ...subcategory,
        synonyms: formatSynonyms(subcategory.synonyms),
        subcategoryId: uuidv4(),
      }));
      values.subcategoryIds = !!values.subcategories
        ? values.subcategories.map((subcategory) => subcategory.subcategoryId)
        : [];
      values.subcategorySlugs = !!values.subcategories
        ? values.subcategories.map((subcategory) => subcategory.slug)
        : [];
      values.createdAt = dayjs().unix();
      console.log(values);
      await firestore.collection(FIRESTORE_CATEGORIES_TABLE).add(values);
      closeAddModal();
    });
  };

  const handleUpdateCategory = () => {
    editForm.validateFields().then(async (values) => {
      values.synonyms = !!values.synonyms ? formatSynonyms(values.synonyms) : [];
      values.subcategories = !!values.subcategories ? values.subcategories : [];
      values.subcategories = values.subcategories.map((subcategory) =>
        subcategory.subcategoryId
          ? { ...subcategory, synonyms: formatSynonyms(subcategory.synonyms) }
          : {
              ...subcategory,
              synonyms: formatSynonyms(subcategory.synonyms),
              subcategoryId: uuidv4(),
            },
      );
      values.subcategoryIds = !!values.subcategories
        ? values.subcategories.map((subcategory) => subcategory.subcategoryId)
        : [];
      values.subcategorySlugs = !!values.subcategories
        ? values.subcategories.map((subcategory) => subcategory.slug)
        : [];
      values.updatedAt = dayjs().unix();
      await firestore.collection(FIRESTORE_CATEGORIES_TABLE).doc(currentCategory.id).update(values);
      closeEditModal();
    });
  };

  const openAddModal = () => {
    setAddModalOpen(true);
  };

  const openEditModal = (category) => {
    setCurrentCategory(category);
    editForm.setFieldsValue(category);
    setEditModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
    addForm.resetFields();
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setCurrentCategory(null);
    editForm.resetFields();
  };

  const formatCategoryUrl = (productName, field) => {
    let formattedName = formatNameToURL(productName);
    addForm.setFields([{ name: field, value: formattedName }]);
  };

  const formatSynonyms = (items) => {
    if (!items) return [];
    const lowerCaseItems = [];
    items.forEach((item) => lowerCaseItems.push(item.toLowerCase()));
    return lowerCaseItems;
  };

  return (
    <>
      <h2>{t("categories")}</h2>
      <Card>
        <Space className={"mb-2 d-flex justify-content-between"}>
          <Input placeholder={t("search")} />
          <Button type={"primary"} onClick={openAddModal}>
            {t("add")}
          </Button>
        </Space>
        <Table
          columns={[
            {
              title: t("category"),
              render: (_, category) => <a onClick={() => openEditModal(category)}>{category.name}</a>,
            },
            {
              title: t("subcategories"),
              dataIndex: "subcategories",
              render: (data) => (
                <div>
                  {data.map((item, index) => (
                    <div key={index}>{item.name}</div>
                  ))}
                </div>
              ),
            },
            {
              title: t("action"),
              key: "action",
              render: (_, category) => (
                <Space size="middle">
                  <Popconfirm
                    title={t("delete_item", { name: category.name })}
                    onConfirm={() => deleteCategory(category)}
                    okText={t("yes")}
                    cancelText={t("no")}
                  >
                    <a>{t("delete")}</a>
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
          dataSource={list}
          rowKey={"id"}
          scroll={{ x: true }}
        />
      </Card>
      <Modal
        visible={addModalOpen}
        title={t("add_category")}
        onCancel={closeAddModal}
        onOk={handleCreateCategory}
        okText={t("save")}
        cancelText={t("close")}
        width={1000}
      >
        <Form layout={"vertical"} form={addForm}>
          <h4>{t("category_info")}</h4>
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item
                name={"name"}
                label={t("category_name_nl")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input placeholder={t("category_name_nl")} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                name={"nameEN"}
                label={t("category_name_en")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input
                  placeholder={t("category_name_en")}
                  onChange={(e) => formatCategoryUrl(e.target.value, "slug")}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                name={"nameDE"}
                label={t("category_name_de")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input placeholder={t("category_name_de")} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={"slug"}
                label={t("slug")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input placeholder={t("slug")} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name={"synonyms"} label={t("synonyms")}>
                <Select mode="tags" style={{ width: "100%" }} placeholder={t("synonyms")} options={[]} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <h4>{t("subcategories")}</h4>
              <Form.List name="subcategories">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <Row key={field.key} gutter={10}>
                          <Col md={7}>
                            <Form.Item
                              name={[field.name, "name"]}
                              label={t("category_name_nl")}
                              rules={[
                                {
                                  required: true,
                                  message: t("form.enter_value"),
                                },
                              ]}
                            >
                              <Input placeholder={t("category_name_nl")} />
                            </Form.Item>
                          </Col>
                          <Col md={7}>
                            <Form.Item
                              name={[field.name, "nameEN"]}
                              label={t("category_name_en")}
                              rules={[
                                {
                                  required: true,
                                  message: t("form.enter_value"),
                                },
                              ]}
                            >
                              <Input
                                placeholder={t("category_name_en")}
                                onChange={(e) => formatCategoryUrl(e.target.value, ["subcategories", index, "slug"])}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={7}>
                            <Form.Item
                              name={[field.name, "nameDE"]}
                              label={t("category_name_de")}
                              rules={[
                                {
                                  required: true,
                                  message: t("form.enter_value"),
                                },
                              ]}
                            >
                              <Input placeholder={t("category_name_de")} />
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={3} style={{ alignSelf: "center" }}>
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </Col>{" "}
                          <Col xs={24} md={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, "slug"]}
                              label={t("slug")}
                              rules={[
                                {
                                  required: true,
                                  message: t("form.enter_value"),
                                },
                              ]}
                            >
                              <Input placeholder={t("slug")} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12}>
                            <Form.Item {...field} name={[field.name, "synonyms"]} label={t("synonyms")}>
                              <Select mode="tags" style={{ width: "100%" }} placeholder={t("synonyms")} options={[]} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <hr className="mt-2" />
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          className="w-100"
                        >
                          <PlusOutlined /> {t("form.add_field")}
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        visible={editModalOpen}
        title={t("edit_item", { name: currentCategory?.name })}
        onCancel={closeEditModal}
        onOk={handleUpdateCategory}
        okText={t("save")}
        cancelText={t("close")}
        width={1000}
      >
        <Form layout={"vertical"} form={editForm}>
          <h4>{t("category_info")}</h4>
          <Row gutter={16}>
            <Col md={8}>
              <Form.Item
                name={"name"}
                label={t("category_name_nl")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input placeholder={t("category_name_nl")} />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                name={"nameEN"}
                label={t("category_name_en")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input
                  placeholder={t("category_name_en")}
                  onChange={(e) => formatCategoryUrl(e.target.value, "slug")}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                name={"nameDE"}
                label={t("category_name_de")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input placeholder={t("category_name_de")} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name={"slug"}
                label={t("slug")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_value"),
                  },
                ]}
              >
                <Input placeholder={t("slug")} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name={"synonyms"} label={t("synonyms")}>
                <Select mode="tags" style={{ width: "100%" }} placeholder={t("synonyms")} options={[]} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <h4>{t("subcategories")}</h4>
              <Form.List name="subcategories">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <Row key={field.key} gutter={10}>
                          <Col md={7}>
                            <Form.Item
                              name={[field.name, "name"]}
                              label={t("category_name_nl")}
                              rules={[
                                {
                                  required: true,
                                  message: t("form.enter_value"),
                                },
                              ]}
                            >
                              <Input placeholder={t("category_name_nl")} />
                            </Form.Item>
                          </Col>
                          <Col md={7}>
                            <Form.Item
                              name={[field.name, "nameEN"]}
                              label={t("category_name_en")}
                              rules={[
                                {
                                  required: true,
                                  message: t("form.enter_value"),
                                },
                              ]}
                            >
                              <Input
                                placeholder={t("category_name_en")}
                                onChange={(e) => formatCategoryUrl(e.target.value, ["subcategories", index, "slug"])}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={7}>
                            <Form.Item
                              name={[field.name, "nameDE"]}
                              label={t("category_name_de")}
                              rules={[
                                {
                                  required: true,
                                  message: t("form.enter_value"),
                                },
                              ]}
                            >
                              <Input placeholder={t("category_name_de")} />
                            </Form.Item>
                          </Col>
                          <Col sm={24} md={3} style={{ alignSelf: "center" }}>
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </Col>
                          <Col xs={24} md={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, "slug"]}
                              label={t("slug")}
                              rules={[
                                {
                                  required: true,
                                  message: t("form.enter_value"),
                                },
                              ]}
                            >
                              <Input placeholder={t("slug")} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12}>
                            <Form.Item {...field} name={[field.name, "synonyms"]} label={t("synonyms")}>
                              <Select mode="tags" style={{ width: "100%" }} placeholder={t("synonyms")} options={[]} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <hr className="mt-2" />
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          className="w-100"
                        >
                          <PlusOutlined /> {t("form.add_field")}
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
