import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Col, Form, Input, InputNumber, message, Modal, Row, Select, Space, Table, Tooltip } from "antd";
import { MinusCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import * as dayjs from "dayjs";
import { useFirestore } from "react-redux-firebase";
import { FIRESTORE_PURCHASE_ORDERS_TABLE } from "../../../../constants/FirestoreConstant";
import { useTranslation } from "react-i18next";
import utils from "utils";

const PurchaseOrders = () => {
  const [addForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [orderDetailsModalOpen, setOrderDetailsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const purchaseOrders = useSelector((state) => state.firestoreReducer.ordered.purchaseOrders);
  const config = useSelector((state) => state.firestoreReducer.ordered.config);
  const [searchText, setSearchText] = useState("");
  const [tempOrderData, setTempOrderData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const firestore = useFirestore();
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const { email } = profile;

  const getStatusColor = (status) => {
    switch (status) {
      case "ordered":
        return "#1890ff";
      case "completed":
        return "#52c41a";
      default:
        return "#000";
    }
  };

  const handleUpdateOrderStatus = async (order, newStatus) => {
    try {
      const updateData = {
        status: newStatus,
      };

      if (newStatus === "completed") {
        updateData.completedAt = dayjs().unix();
      } else if (newStatus === "ordered") {
        updateData.completedAt = null;
      }

      await firestore.collection(FIRESTORE_PURCHASE_ORDERS_TABLE).doc(order.id).update(updateData);

      messageApi.success(
        newStatus === "completed"
          ? t("notifications.purchase_order_completed")
          : t("notifications.purchase_order_reopened"),
      );
    } catch (error) {
      messageApi.error(t("notifications.purchase_order_status_update_failed"));
    }
  };

  const handleCreateOrder = () => {
    addForm.validateFields().then((values) => {
      const orderProducts = values.products.map((product) => ({
        productId: product.product,
        amount: product.amount,
        productName: products.find((p) => p.id === product.product)?.name,
        productColor: products.find((p) => p.id === product.product)?.color,
      }));

      const totalProducts = orderProducts.reduce((sum, product) => sum + product.amount, 0);
      const configDoc = config.find((doc) => doc.id === "orders");
      const currentOrderCounter = configDoc ? configDoc.currentOrderCounter : 1;

      const newOrder = {
        orderNumber: `IO-${dayjs().format("YYYYMMDD")}-${currentOrderCounter + 1}`,
        totalProducts,
        status: "ordered",
        orderDate: dayjs().unix(),
        products: orderProducts,
        createdBy: email,
        createdAt: dayjs().unix(),
      };

      setTempOrderData(newOrder);
      setConfirmModalOpen(true);
    });
  };

  const handleConfirmOrder = async () => {
    if (tempOrderData) {
      setIsSubmitting(true);
      try {
        const configDocRef = firestore.collection("config").doc("orders");

        // Use a transaction to atomically increment the counter
        await firestore.runTransaction(async (transaction) => {
          const configDoc = await transaction.get(configDocRef);
          const newCounter = configDoc.data().currentOrderCounter + 1;

          // Update the counter
          transaction.update(configDocRef, { currentOrderCounter: newCounter });

          // Add the purchase order
          await firestore.collection(FIRESTORE_PURCHASE_ORDERS_TABLE).add(tempOrderData);
        });

        messageApi.success(t("notifications.purchase_order_successful"));
        closeAddModal();
        setConfirmModalOpen(false);
        setTempOrderData(null);
      } catch (error) {
        messageApi.error(t("notifications.purchase_order_failed") + error.message);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const openAddModal = () => {
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
    addForm.resetFields();
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    // Implement search logic here
  };

  const handleOrderRowClick = (record) => {
    setSelectedOrder(record);
    setOrderDetailsModalOpen(true);
  };

  const tableColumns = [
    {
      title: t("order.order_id"),
      dataIndex: "orderNumber",
      sorter: (a, b) => utils.antdTableSorter(a, b, "orderNumber"),
      render: (text, record) => <a onClick={() => handleOrderRowClick(record)}>{text}</a>,
    },
    {
      title: t("total_products"),
      dataIndex: "totalProducts",
      sorter: (a, b) => utils.antdTableSorter(a, b, "totalProducts"),
    },
    {
      title: t("order.status"),
      dataIndex: "status",
      render: (status, record) => (
        <Tooltip
          title={
            <Space direction="vertical">
              {status === "ordered" && (
                <Button type="link" size="small" onClick={() => handleUpdateOrderStatus(record, "completed")}>
                  {t("set_to_complete")}
                </Button>
              )}
              {status === "completed" && (
                <Button type="link" size="small" onClick={() => handleUpdateOrderStatus(record, "ordered")}>
                  {t("set_to_ordered")}
                </Button>
              )}
            </Space>
          }
        >
          <span
            style={{
              color: getStatusColor(status),
            }}
          >
            {t(`order.${status}`)}
          </span>
        </Tooltip>
      ),
    },
    {
      title: t("order.order_date"),
      dataIndex: "orderDate",
      render: (date) => dayjs.unix(date).format("DD-MM-YYYY"),
      sorter: (a, b) => utils.antdTableSorter(a, b, "orderDate"),
      defaultSortOrder: "descend",
    },
  ];

  const isProductInExistingOrder = (productId) => {
    return purchaseOrders.some((order) => order.products.some((product) => product.productId === productId));
  };

  return (
    <>
      {contextHolder}
      <h2>{t("order.purchase_orders")}</h2>
      <Card>
        <Space className="mb-2 d-flex justify-content-between">
          <Input placeholder={t("search")} prefix={<SearchOutlined />} onChange={onSearch} />
          <Button type="primary" onClick={openAddModal}>
            {t("order.add_purchase_order")}
          </Button>
        </Space>
        <Table columns={tableColumns} dataSource={purchaseOrders} rowKey="id" scroll={{ x: true }} />
      </Card>

      <Modal
        open={addModalOpen}
        title={t("order.add_purchase_order")}
        onCancel={closeAddModal}
        onOk={handleCreateOrder}
        okText={t("save")}
        cancelText={t("close")}
        width={800}
      >
        <Form form={addForm} layout="vertical">
          <Form.List name="products">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <>
                    <Row gutter={16}>
                      <Col xs={18}>
                        <Form.Item
                          {...field}
                          name={[field.name, "product"]}
                          rules={[
                            { required: true, message: t("please_select_a_product") },
                            {
                              validator: (_, value) => {
                                if (isProductInExistingOrder(value)) {
                                  return Promise.reject(new Error(t("form.product_in_existing_order")));
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder={t("select_a_product")}
                            optionFilterProp="label"
                            filterOption={(input, option) => {
                              const product = products.find((p) => p.id === option.value);
                              if (!product) return false;
                              const searchStr = `${product.name} ${product.code}`.toLowerCase();
                              return searchStr.includes(input.toLowerCase());
                            }}
                          >
                            {products?.map((product) => (
                              <Select.Option key={product.id} value={product.id}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <div
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      marginRight: "20px",
                                    }}
                                  >
                                    {product.name} ({product.code})
                                  </div>
                                  <div style={{ marginRight: "20px", color: "#666" }}>
                                    {t("color")}: {product.color || "-"}
                                  </div>
                                  <div
                                    style={{
                                      color:
                                        product.stock === 0 ? "#f5222d" : product.stock < 30 ? "#faad14" : "#52c41a",
                                    }}
                                  >
                                    {t("stock")}: {product.stock || 0}
                                  </div>
                                </div>
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={4}>
                        <Form.Item
                          {...field}
                          name={[field.name, "amount"]}
                          rules={[{ required: true, message: t("form.enter_value") }]}
                        >
                          <InputNumber min={1} placeholder={t("order.amount")} style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col xs={2}>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      </Col>
                    </Row>
                  </>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    {t("add_product")}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>

      <Modal
        visible={confirmModalOpen}
        title={t("order.confirm_purchase_order")}
        onCancel={() => setConfirmModalOpen(false)}
        footer={[
          <Button key="back" onClick={() => setConfirmModalOpen(false)}>
            {t("cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleConfirmOrder} loading={isSubmitting}>
            {t("place_order")}
          </Button>,
        ]}
      >
        {tempOrderData && (
          <div>
            <h3>{t("order.order_summary")}</h3>
            <p>
              <strong>{t("order.order_id")}:</strong> {tempOrderData.orderNumber}
            </p>
            <p>
              <strong>{t("total_products")}:</strong> {tempOrderData.totalProducts}
            </p>
            <h4>{t("products")}:</h4>
            {tempOrderData.products.map((product, index) => (
              <p key={index}>
                {product.productName} | {product.productColor} | {product.amount} {t("units")}
              </p>
            ))}
          </div>
        )}
      </Modal>
      <Modal
        visible={orderDetailsModalOpen}
        title={t("order.order_details")}
        onCancel={() => setOrderDetailsModalOpen(false)}
        footer={null}
        width={600}
      >
        {selectedOrder && (
          <div>
            <p>
              <strong>{t("order.order_id")}:</strong> {selectedOrder.orderNumber}
            </p>
            <p>
              <strong>{t("order.order_date")}:</strong> {dayjs.unix(selectedOrder.orderDate).format("DD-MM-YYYY")}
            </p>
            <p>
              <strong>{t("order.status")}:</strong> {t(`order.${selectedOrder.status}`)}
            </p>
            <p>
              <strong>{t("total_products")}:</strong> {selectedOrder.totalProducts}
            </p>
            <p>
              <strong>{t("order.ordered_by")}:</strong> {`${selectedOrder.createdBy}`}
            </p>
            <h4>{t("products")}:</h4>
            <Table
              columns={[
                {
                  title: t("product_name"),
                  dataIndex: "productName",
                },
                {
                  title: t("color"),
                  dataIndex: "productColor",
                },
                {
                  title: t("order.amount"),
                  dataIndex: "amount",
                },
              ]}
              dataSource={selectedOrder.products}
              rowKey="productId"
              pagination={false}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default PurchaseOrders;
