import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  Tag,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  FileExcelOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import utils from "utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EllipsisDropdown from "../../../../components/shared-components/EllipsisDropdown";
import {
  updateFirebaseClientIsAuthorized,
  updateFirebaseClientType,
  updateUser,
} from "../../../../functions/firestore/users";
import { getSalesPersonNameById } from "../../../../functions/users";
import styled from "styled-components";
import _ from "lodash";
import { COMPANY_CONSTANTS } from "../../../../constants/CompanyConstants";
import { DATE_FORMAT_DD_MM_YYYY } from "../../../../constants/DateConstant";
import { getCustomersExcelExportData } from "../../../../functions/excel";
import * as dayjs from "dayjs";
import { formatAddress, formatPrice } from "../../../../functions/formatters";
import { CURRENCY } from "../../../../constants/DeliveryConstants";
import { isCountryInEuropeanUnion, validateVatNumber } from "../../../../functions/validator";
import countryData from "../../../../data/countries/countries.json";
import relativeTime from "dayjs/plugin/relativeTime";
import ExcelExport from "../../../../components/shared-components/ExcelExport";

dayjs.extend(relativeTime);

const { Option } = Select;

const Customers = () => {
  const { t } = useTranslation();
  const allUsers = useSelector((state) => state.firestoreReducer.ordered.webshopUsers);
  const carts = useSelector((state) => state.firestoreReducer.ordered.carts);
  const [editForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const salesPersonUsers = _.filter(allUsers, (user) => user.isSalesPerson);
  const [customerList, setCustomerList] = useState(null);
  const [filteredList, setFilteredList] = useState(null);
  const [drawerUser, setDrawerUser] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isSalesPerson, setIsSalesPerson] = useState(false);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [customerCountries, setCustomerCountries] = useState(null);
  const [drawerUserCart, setDrawerUserCart] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [invoiceDiffers, setInvoiceDiffers] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (!!allUsers) {
      const customers = allUsers.filter((item) => !!item.authorization && item.authorization.isReviewed);
      const sortedCustomers = _.orderBy(
        customers,
        (item) => {
          return !!item.created_at && item.created_at;
        },
        ["desc"],
      );
      setCustomerList(sortedCustomers);
      if (!filteredList) setFilteredList(sortedCustomers);
      onSearch(searchText, sortedCustomers);
      // Get all unique countries
      let countries = [];
      customers.map((item) => {
        if (!!item.company && !!item.company.country && !countries.includes(item.company.country)) {
          countries.push(item.company.country);
        }
      });
      setCustomerCountries(countries);
    }
  }, [allUsers]);

  const userTypeMenu = (client) => (
    <Menu>
      <Menu.Item>
        <a onClick={() => updateFirebaseClientType(client, false)}>{t("client")}</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => updateFirebaseClientType(client, true)}>{t("sales_person")}</a>
      </Menu.Item>
    </Menu>
  );

  const userAuthorizatedMenu = (client) => (
    <Menu>
      <Menu.Item>
        <a onClick={() => updateFirebaseClientIsAuthorized(client, true)}>{t("access")}</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => updateFirebaseClientIsAuthorized(client, false)}>{t("no_access")}</a>
      </Menu.Item>
    </Menu>
  );

  const exportMenu = (
    <Menu>
      <Menu.Item key="1">
        <ExcelExport
          data={
            customerCountries?.length > 0
              ? customerCountries.map((item, index) => getCustomersExcelExportData(customerList, item))
              : []
          }
          fileName={COMPANY_CONSTANTS.WEBSITE_NAME + " Klanten " + dayjs().format(DATE_FORMAT_DD_MM_YYYY)}
        >
          {t("excel")}
        </ExcelExport>
      </Menu.Item>
    </Menu>
  );

  const tableColumns = [
    {
      title: t("company"),
      dataIndex: "company",
      render: (data) => <span>{!!data && data.name}</span>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "name"),
    },
    {
      title: t("vat_number"),
      dataIndex: "company",
      render: (data) => <span>{!!data && !!data.vatNumber ? data.vatNumber : "-"}</span>,
    },
    {
      title: t("name"),
      render: (_, data) => <span>{data.firstName + " " + data.lastName}</span>,
    },
    {
      title: t("email"),
      dataIndex: "email",
      render: (data) => <span>{data}</span>,
    },
    {
      title: t("phone"),
      dataIndex: "phoneNumber",
      render: (data) => <span>{data}</span>,
    },
    {
      title: t("user_type"),
      render: (_, data) => (
        <Dropdown overlay={userTypeMenu(data)} trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <Tag color={data.isSalesPerson ? "lime" : "pink"}>
              {data.isSalesPerson ? t("sales_person") : t("client")}
            </Tag>
          </a>
        </Dropdown>
      ),
    },
    {
      title: t("status"),
      render: (_, data) => (
        <Dropdown overlay={userAuthorizatedMenu(data)} trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <Tag color={data.authorization.isAuthorized ? "geekblue" : "red"}>
              {data.authorization.isAuthorized ? t("access") : t("no_access")}
            </Tag>
          </a>
        </Dropdown>
      ),
    },
    {
      title: t("last_order"),
      dataIndex: "lastOrderTimestamp",
      render: (data) => <span>{!!data ? dayjs.unix(data).fromNow() : "-"}</span>,
    },
    {
      title: t("last_login_timestamp"),
      dataIndex: "lastLoginTimestamp",
      render: (data) => <span>{!!data ? dayjs.unix(data).fromNow() : "-"}</span>,
      sorter: (a, b) => {
        const aTimestamp = a.lastLoginTimestamp || 0;
        const bTimestamp = b.lastLoginTimestamp || 0;
        return aTimestamp - bTimestamp;
      },
    },
    {
      title: t("last_login_location"),
      dataIndex: "lastLoginLocation",
      render: (data) => <span>{data || "-"}</span>,
    },
    {
      title: t("items_in_cart"),
      dataIndex: "id",
      render: (uid) => {
        const cart = carts?.find((cart) => cart.id === uid);
        if (!!cart) return <span>{cart.products.length}</span>;
        else return 0;
      },
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown
            menu={
              <Menu>
                <Menu.Item onClick={() => openEditModal(elm)}>
                  <Flex alignItems="center">
                    <EditOutlined />
                    <span style={{ marginLeft: 4 }}>{t("edit")}</span>
                  </Flex>
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    setDrawerUser(elm);
                    const userCart = carts.find((cart) => cart.id === elm.id);
                    if (!!userCart) setDrawerUserCart(userCart);
                    setDrawerVisible(true);
                  }}
                >
                  <Flex alignItems="center">
                    <EyeOutlined />
                    <span style={{ marginLeft: 4 }}>{t("view")}</span>
                  </Flex>
                </Menu.Item>
              </Menu>
            }
          />
        </div>
      ),
    },
  ];

  const cartColumns = [
    {
      title: t("name"),
      render: (_, data) => (
        <div className="d-flex">
          <span>{`${data.name} (${data.code})`}</span>
        </div>
      ),
    },
    {
      title: t("price"),
      dataIndex: "price",
      render: (data) => <span style={{ whiteSpace: "nowrap" }}>{CURRENCY + formatPrice(data)}</span>,
      align: "center",
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      render: (data) => <span>{data}</span>,
      align: "center",
    },
    {
      title: t("sub_total"),
      dataIndex: "paymentPrice",
      render: (_, data) => (
        <span style={{ whiteSpace: "pre-line" }}>{CURRENCY + formatPrice(data.price * data.quantity)}</span>
      ),
      align: "right",
    },
  ];

  const saveUser = () => {
    editForm.validateFields().then((values) => {
      setSubmitLoading(true);
      const deliveryAddress = {
        street: values.street,
        houseNumber: values.houseNumber,
        addition: values.addition || null,
        zipCode: values.zipCode,
        city: values.city,
      };
      const invoiceAddress = invoiceDiffers
        ? {
            street: values.invoiceStreet,
            houseNumber: values.invoiceHouseNumber,
            addition: values.invoiceAddition || null,
            zipCode: values.invoiceZipCode,
            city: values.invoiceCity,
          }
        : null;

      updateUser(editUser.id, {
        salesPerson: !isSalesPerson ? selectedSalesPerson : null,
        isSalesPerson: isSalesPerson,
        "address.delivery": deliveryAddress,
        "address.invoice": invoiceAddress,
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email.trim(),
        phoneNumber: values.phone,
        company: {
          name: values.companyName.trim(),
          vatNumber: values.vatNumber?.trim() || null,
          country: values.country.trim(),
        },
        companyName: values.companyName.trim(),
      })
        .then(() => {
          closeEditModal();
        })
        .catch((err) => {
          console.log(err);
          setSubmitLoading(false);
          messageApi.error(err);
        });
    });
  };

  const openEditModal = (user) => {
    setEditUser(user);
    setIsSalesPerson(user.isSalesPerson || false);
    if (!!user.salesPerson) setSelectedSalesPerson(user.salesPerson);
    const deliveryAddress = user.address.delivery;
    const invoiceAddress = user.address.invoice;
    editForm.setFieldsValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phoneNumber,
      companyName: user.company.name,
      vatNumber: user.company.vatNumber,
      country: user.company.country,
      addition: deliveryAddress.addition,
      city: deliveryAddress.city,
      houseNumber: deliveryAddress.houseNumber,
      street: deliveryAddress.street,
      zipCode: deliveryAddress.zipCode,
    });
    // Invoice address
    if (!!invoiceAddress) {
      setInvoiceDiffers(true);
      editForm.setFieldsValue({
        invoiceAddition: invoiceAddress.addition,
        invoiceCity: invoiceAddress.city,
        invoiceHouseNumber: invoiceAddress.houseNumber,
        invoiceStreet: invoiceAddress.street,
        invoiceZipCode: invoiceAddress.zipCode,
      });
    }
    setEditModalVisible(true);
  };

  const closeEditModal = () => {
    setEditModalVisible(false);
    setEditUser(null);
    setEditModalVisible(false);
    setIsSalesPerson(false);
    setSelectedSalesPerson(null);
    editForm.resetFields();
    setInvoiceDiffers(false);
    setSubmitLoading(false);
  };

  const onSearch = (value, customers) => {
    setSearchText(value);
    const data = utils.wildCardSearchOnCustomer(customers ? customers : customerList, value);
    setFilteredList(data);
  };

  return (
    <>
      {contextHolder}
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 8,
            gap: 8,
            flexWrap: "wrap",
          }}
        >
          <div style={{ display: "flex", gap: 8 }}>
            <div className="mr-md-3 " style={{ gap: 6, display: "flex" }}>
              <Input
                value={searchText}
                placeholder={t("search")}
                prefix={<SearchOutlined />}
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
          </div>
          <Dropdown overlay={exportMenu}>
            <Button className="ant-btn-primary">
              <FileExcelOutlined /> {t("export")} <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <div className="table-responsive">
          <Table columns={tableColumns} dataSource={filteredList} rowKey="email" scroll={{ x: 1300 }} />
        </div>
      </Card>
      {editModalVisible && (
        <Modal
          title={t("edit_user", { name: editUser.company.name })}
          visible={editModalVisible}
          onCancel={closeEditModal}
          onOk={saveUser}
          okText={t("save")}
          cancelText={t("close")}
          width={1000}
          confirmLoading={submitLoading}
        >
          <Form form={editForm} layout="vertical">
            <InputLabel>{t("is_sales_person")}</InputLabel>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={isSalesPerson}
              onChange={setIsSalesPerson}
            />
            {!isSalesPerson && (
              <>
                <InputLabel className={"mt-3"}>{t("sales_person")}</InputLabel>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={true}
                  className="w-100"
                  onChange={setSelectedSalesPerson}
                  value={selectedSalesPerson}
                >
                  <Option key={0} value={null} className={"w-100"}>
                    {t("none")}
                  </Option>
                  {salesPersonUsers.map((user) => (
                    <Option key={user.id} value={user.id} className={"w-100"}>
                      {user.firstName + " " + user.lastName + " - " + user.company.name}
                    </Option>
                  ))}
                </Select>
              </>
            )}
            <Divider />
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="firstName"
                  label={t("first_name")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input placeholder={t("first_name")} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="lastName"
                  label={t("last_name")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input placeholder={t("last_name")} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="companyName"
                  label={t("company_name")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input placeholder={t("company_name")} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="vatNumber"
                  label={t("vat_number")}
                  rules={[
                    () => ({
                      validator(rule, value) {
                        return validateVatNumber(value)
                          ? Promise.resolve()
                          : Promise.reject(
                              isCountryInEuropeanUnion(countryCode)
                                ? t("form.enter_valid_vat_number")
                                : t("form.enter_valid_or_empty_vat_number"),
                            );
                      },
                    }),
                  ]}
                >
                  <Input
                    onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                    placeholder={t("vat_number")}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="country"
                  label={t("country")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={t("country")}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={setCountryCode}
                  >
                    {countryData.map((country, index) => {
                      return (
                        <Option value={country.value} key={index}>
                          {country.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6}>
                <Form.Item
                  name="zipCode"
                  label={t("zip_code")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input placeholder={t("zip_code")} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={5}>
                <Form.Item
                  name="houseNumber"
                  label={t("house_number")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input placeholder={t("house_number")} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={5}>
                <Form.Item name="addition" label={t("house_number_addition")}>
                  <Input placeholder={t("house_number_addition")} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="street"
                  label={t("street")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input placeholder={t("street")} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="city"
                  label={t("city")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input placeholder={t("city")} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="phone"
                  label={t("phone")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_value"),
                    },
                  ]}
                >
                  <Input placeholder={t("phone")} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="email"
                  label={t("email")}
                  disabled
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_email"),
                    },
                    {
                      type: "email",
                      message: t("form.enter_valid_email"),
                    },
                  ]}
                >
                  <Input placeholder={t("email")} />
                </Form.Item>
              </Col>
            </Row>
            <Checkbox checked={!invoiceDiffers} onChange={() => setInvoiceDiffers(!invoiceDiffers)}>
              {t("invoice_address_match_delivery")}
            </Checkbox>
            {invoiceDiffers && (
              <div style={{ marginTop: "1rem" }}>
                <h4>{t("invoice_details")}</h4>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={10}>
                    <Form.Item
                      name="invoiceStreet"
                      label={t("street")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_value"),
                        },
                      ]}
                    >
                      <Input placeholder={t("street")} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8}>
                    <Form.Item
                      name="invoiceHouseNumber"
                      label={t("house_number")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_value"),
                        },
                      ]}
                    >
                      <Input placeholder={t("house_number")} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={6}>
                    <Form.Item name="invoiceAddition" label={t("house_number_addition")}>
                      <Input placeholder={t("house_number_addition")} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="invoiceZipCode"
                      label={t("zip_code")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_value"),
                        },
                      ]}
                    >
                      <Input placeholder={t("zip_code")} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="invoiceCity"
                      label={t("city")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_value"),
                        },
                      ]}
                    >
                      <Input placeholder={t("city")} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
          </Form>
        </Modal>
      )}
      <Drawer
        title={!!drawerUser && drawerUser.company.name}
        placement="right"
        onClose={() => {
          setDrawerVisible(false);
          setDrawerUserCart(null);
          setDrawerUser(null);
        }}
        visible={drawerVisible}
        width={550}
      >
        {!!drawerUser && (
          <>
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label={t("company_name")}>{drawerUser.company.name}</Descriptions.Item>
              <Descriptions.Item label={t("vat_number")}>
                {!!drawerUser.company.vatNumber ? drawerUser.company.vatNumber : "-"}
              </Descriptions.Item>
              <Descriptions.Item label={t("country")}>{drawerUser.company.country}</Descriptions.Item>
              <Descriptions.Item label={t("customer.city")}>
                {!!drawerUser.company.city ? drawerUser.company.city : "-"}
              </Descriptions.Item>
              {!!drawerUser.address && !!drawerUser.address.delivery && (
                <Descriptions.Item label={t("delivery_address")}>
                  {formatAddress(drawerUser.address.delivery)}
                </Descriptions.Item>
              )}
              {!!drawerUser.address && !!drawerUser.address.invoice && (
                <Descriptions.Item label={t("invoice_address")}>
                  {formatAddress(drawerUser.address.invoice)}
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t("first_name")}>{drawerUser.firstName}</Descriptions.Item>
              <Descriptions.Item label={t("last_name")}>{drawerUser.lastName}</Descriptions.Item>
              <Descriptions.Item label={t("email")}>{drawerUser.email}</Descriptions.Item>
              <Descriptions.Item label={t("phone")}>{drawerUser.phoneNumber}</Descriptions.Item>
              <Descriptions.Item label={t("user_type")}>
                {drawerUser.isSalesPerson ? t("sales_person") : t("client")}
              </Descriptions.Item>
              {!drawerUser.isSalesPerson && (
                <Descriptions.Item label={t("sales_person")}>
                  {!!drawerUser.salesPerson ? getSalesPersonNameById(allUsers, drawerUser.salesPerson) : "-"}
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t("status")}>
                {drawerUser.authorization.isAuthorized ? t("access") : t("no_access")}
              </Descriptions.Item>
              <Descriptions.Item label={t("review_note")}>
                {!!drawerUser.authorization.note ? drawerUser.authorization.note : "-"}
              </Descriptions.Item>
              <Descriptions.Item label={t("last_order")}>
                {!!drawerUser.lastOrderTimestamp ? dayjs.unix(drawerUser.lastOrderTimestamp).format("DD-MM-YYYY") : "-"}
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label={t("status")}>
                {!!drawerUser.authorization.status ? t(drawerUser.authorization.status) : t("open")}
              </Descriptions.Item>
              <Descriptions.Item label={t("status_edited_at")}>
                {!!drawerUser.authorization.updatedAt
                  ? dayjs.unix(drawerUser.authorization.updatedAt).format("DD-MM-YYYY HH:mm")
                  : "-"}
              </Descriptions.Item>
              <Descriptions.Item label={t("in_progress_by")}>
                {!!drawerUser.authorization.updatedBy ? drawerUser.authorization.updatedBy : "-"}
              </Descriptions.Item>
            </Descriptions>
            {!!drawerUserCart && (
              <div className="table-responsive pt-5">
                <h4 style={{ margin: 0 }}>{t("products")}</h4>
                <p>
                  {t("cart_last_updated", {
                    dateTime: dayjs.unix(drawerUserCart.updatedAt).format("DD-MM-YYYY HH:mm"),
                  })}
                </p>
                <Table
                  columns={cartColumns}
                  dataSource={drawerUserCart.products}
                  pagination={false}
                  rowKey="id"
                  className="w-100"
                  bordered={true}
                />
              </div>
            )}
          </>
        )}
      </Drawer>
    </>
  );
};

const InputLabel = styled.p`
  color: #333;
  margin-bottom: 0;
`;

export default Customers;
