import React, { useState } from "react";
import { Button, Card, Col, Form, Input, InputNumber, message, Select } from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import * as dayjs from "dayjs";
import { formatCategory } from "../../../../functions/formatters";

const { Option } = Select;

const AddDiscount = (props) => {
  const { t, i18n } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const lang = i18n.language;
  const firestore = useFirestore();
  const { products, categories } = props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  const promotions = useSelector((state) => state.firestoreReducer.ordered.promotions);

  /**
   * Submit promotion form
   */
  const onSubmit = () => {
    form
      .validateFields()
      .then(() => {
        let enteredCategories = form.getFieldValue().categories;
        let categoryAlreadyHasPromotion = _.find(promotions, function (o) {
          return o.categories.some((r) => enteredCategories.includes(r));
        });
        if (!!categoryAlreadyHasPromotion) {
          messageApi.warning(t("notifications.promotion_category_overlap") + categoryAlreadyHasPromotion.name);
        } else {
          setSubmitLoading(true);
          let fields = form.getFieldsValue();
          let discount = fields.discount;
          let selectedCategories = fields.categories;
          let filteredProducts = _.filter(products, function (item) {
            return _.intersection(item.category, selectedCategories).length > 0;
          });
          storePromotionToFirestore(discount, filteredProducts);
        }
      })
      .catch((err) => {
        setSubmitLoading(false);
        console.log(err);
      });
  };

  /**
   * Store promotion to firestore before applying discount to associated products
   * @param {*} discount Discount percentage to apply to associated products
   * @param {*} filteredProducts The products to apply the discount to
   */
  const storePromotionToFirestore = (discount, filteredProducts) => {
    let promotion = form.getFieldsValue();
    firestore
      .collection("promotions")
      .add({
        ...promotion,
        createdAt: dayjs().unix(),
        createdBy: email,
        active: true,
      })
      .then(() => {
        applyDiscountToProducts(discount, filteredProducts);
      })
      .catch((err) => messageApi.error(err.message));
  };

  /**
   * Apply discount to all products associated to created promotion
   * @param {*} discount Discount percentage
   * @param {*} products All products to apply the discount to
   */
  const applyDiscountToProducts = (discount, products) => {
    // Get a new write batch
    let batch = firestore.batch();

    products.forEach((item) => {
      let ref = firestore.collection("products").doc(item.id);
      batch.update(ref, { discount: discount });
    });

    // Commit the batch
    batch
      .commit()
      .then(() => {
        form.resetFields();
        setSubmitLoading(false);
        messageApi.success(t("notifications.discount_added", 4));
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {contextHolder}
      <Col xs={24} sm={24} md={6}>
        <Form layout="vertical" form={form} name="advanced_search" className="ant-advanced-search-form">
          <Card title={t("create_promotion")}>
            <Form.Item
              name="name"
              label={t("promotion_name")}
              rules={[
                {
                  required: true,
                  message: t("form.enter_promotion_name"),
                },
              ]}
            >
              <Input placeholder={t("promotion_name")} />
            </Form.Item>
            <Form.Item
              name="discount"
              label={t("discount")}
              rules={[
                {
                  required: true,
                  message: t("form.enter_discount_category"),
                },
              ]}
            >
              <InputNumber
                className="w-100"
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
              />
            </Form.Item>
            <Form.Item
              name="categories"
              label={t("categories")}
              rules={[
                {
                  required: true,
                  message: t("form.enter_category"),
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder={t("form.enter_category")}
                notFoundContent={<span>{t("table_no_categories")}</span>}
              >
                {!!categories &&
                  categories.map((category) => <Option key={category}>{formatCategory(category, lang)}</Option>)}
              </Select>
            </Form.Item>
            <Button type="primary" onClick={() => onSubmit()} htmlType="submit" loading={submitLoading}>
              {t("save")}
            </Button>
          </Card>
        </Form>
      </Col>
    </>
  );
};

export default AddDiscount;
