import firebase from "../../configs/FirebaseConfig";
import { FIRESTORE_USERS_TABLE } from "../../constants/FirestoreConstant";
import _ from "lodash";

const firestore = firebase.firestore();

export const updateUser = (id, dataObject) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection(FIRESTORE_USERS_TABLE)
      .doc(id)
      .update(dataObject)
      .then(() => resolve())
      .catch((err) => reject(err));
  });
};

export const updateFirebaseClientType = (client, boolean) => {
  if (client.isSalesPerson !== boolean) {
    firestore
      .collection(FIRESTORE_USERS_TABLE)
      .doc(client.id)
      .update({ isSalesPerson: boolean })
      .catch((err) => console.log(err));
  }
};

export const updateFirebaseClientIsAuthorized = (client, boolean) => {
  if (client.authorization.isAuthorized !== boolean) {
    const authorizationObj = _.clone(client.authorization);
    authorizationObj.isAuthorized = boolean;
    authorizationObj.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    firestore
      .collection(FIRESTORE_USERS_TABLE)
      .doc(client.id)
      .update({ authorization: authorizationObj })
      .catch((err) => console.log(err));
  }
};
