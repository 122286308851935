import Compressor from "compressorjs";
import i18n from "translations/i18n";

/**
 * Takes an array of image files (objects) and turn each image in base64,
 * which gets put in an array
 * @param {*} fileList Array of Objects containing image files
 * @returns Array of base64 images
 */
export const imageFilesToBase64Array = (fileList) => {
  return new Promise((resolve, reject) => {
    if (fileList.length !== 0) {
      // Init empty array to put the images in
      let imageArray = [];
      // For every uploaded file, turn it into a base64 string and push to image array
      fileList.forEach((item, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(item.originFileObj);
        reader.onload = () => {
          imageArray.push(reader.result);
          if (imageArray.length === fileList.length) resolve(imageArray);
        };
        reader.onerror = (error) => reject(error);
      });
    } else reject(i18n.t("form.choose_image"));
  });
};

/**
 * Turn file into base64 image
 * @param {*} file File to be converted to base64
 * @returns Base64 string
 */
export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

/**
 * Compress and return image
 * @param {*} file The file to be compressed
 * @param {*} quality Quality expressed in format 0.7, not recommended to go below 0.6
 * @param {*} maxWidth The max width of the output image
 * @returns
 */
export const compressImage = (file, quality, maxWidth) => {
  const image = file;
  return new Promise((resolve) => {
    new Compressor(image, {
      quality: quality,
      maxWidth: maxWidth,
      success: (compressedResult) => {
        resolve(compressedResult);
      },
    });
  });
};

/**
 * Antd Upload component expects a request. We use this as a custom request
 */
export const uploaderRequest = ({filename, onSuccess}) => {
  onSuccess("ok");
};
