/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Card, Descriptions, Drawer, Menu, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import Flex from "components/shared-components/Flex";
import * as dayjs from "dayjs";
import { DATE_FORMAT_DD_MM_YYYY_HH_mm } from "constants/DateConstant";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isMobile } from "../../../functions/browser";
import { getUserById, getUserCompanyNameById } from "../../../functions/users";
import _ from "lodash";
import { getProductCodeById } from "../../../functions/products";
import { formatPrice } from "../../../functions/formatters";
import { CURRENCY } from "../../../constants/DeliveryConstants";
import { useFirestoreConnect } from "react-redux-firebase";
import { FIRESTORE_SESSIONS_TABLE } from "../../../constants/FirestoreConstant";

const Sessions = () => {
  useFirestoreConnect([{ collection: FIRESTORE_SESSIONS_TABLE }]);
  const sessions = useSelector((state) => state.firestoreReducer.ordered.sessions);
  const users = useSelector((state) => state.firestoreReducer.ordered.webshopUsers);
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const [filteredList, setFilteredList] = useState(sessions);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [filteredListSet, setFilteredListSet] = useState(false);
  const [session, setSession] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!!sessions && !filteredListSet) {
      setFilteredList(_.orderBy(sessions, ["start"], ["desc"]));
      setFilteredListSet(true);
    }
  }, [sessions]);

  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item>
        <a onClick={() => showDrawer(row)}>
          <Flex alignItems="center">
            <EyeOutlined />
            <span style={{ marginLeft: 4 }}>{t("details")}</span>
          </Flex>
        </a>
      </Menu.Item>
    </Menu>
  );

  const showDrawer = (row) => {
    setDrawerVisible(true);
    const user = getUserById(row.uid, users);
    const obj = { ...row, user: user };
    setSession(obj);
  };

  const tableColumns = [
    {
      title: t("client"),
      dataIndex: "uid",
      render: (data) => <span>{getUserCompanyNameById(data, users)}</span>,
    },
    {
      title: t("when"),
      dataIndex: "start",
      render: (data) => <span>{dayjs.unix(data).format("dd D MMM HH:mm")}</span>,
    },
    {
      title: t("session_duration"),
      render: (_, session) => {
        const start = dayjs.unix(session.start);
        const end = dayjs.unix(session.end);
        if (!!session.start && !!session.end) return <span>{dayjs(end.diff(start)).format("mm:ss")}</span>;
        else return null;
      },
    },
    {
      title: t("session_ordered"),
      dataIndex: "ordered",
      render: (bool) => <span>{bool ? t("yes") : t("no")}</span>,
    },
    {
      title: "",
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  const sessionDetailsColumns = [
    {
      title: t("name"),
      render: (_, data) => (
        <div className="d-flex">
          <span>
            {data.name} {data.code ? "(" + data.code + ")" : "(" + getProductCodeById(data.id, products) + ")"}
          </span>
        </div>
      ),
    },
    {
      title: t("price"),
      dataIndex: "price",
      render: (data) => <span style={{ whiteSpace: "nowrap" }}>{CURRENCY + formatPrice(data)}</span>,
      align: "center",
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      render: (data) => <span>{data}</span>,
      align: "center",
    },
    {
      title: t("sub_total"),
      dataIndex: "paymentPrice",
      render: (_, data) => (
        <span style={{ whiteSpace: "pre-line" }}>{CURRENCY + formatPrice(data.price * data.quantity)}</span>
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Card>
        <div className="table-responsive">
          <Table
            columns={tableColumns}
            dataSource={filteredList}
            rowKey="id"
            rowSelection={{
              selectedRowKeys: selectedRowKeys,
              type: "checkbox",
              preserveSelectedRowKeys: false,
            }}
            scroll={{ x: 900 }}
          />
        </div>
        {!!session && !!session.user && (
          <Drawer
            title={session.user.company.name}
            placement="right"
            onClose={() => setDrawerVisible(false)}
            visible={drawerVisible}
            width={isMobile ? window.innerWidth : 720}
          >
            <Descriptions
              bordered
              title={
                <div className="flex-row">
                  {t("session")}
                  {/*<Tag color={getPaymentStatusColor(order.orderStatus)} className="float-right">*/}
                  {/*  {getPaymentStatus(order.orderStatus)}*/}
                  {/*</Tag>*/}
                </div>
              }
              size="small"
              column={1}
            >
              <Descriptions.Item label={t("client")}>
                {session.user.firstName + " " + session.user.lastName}
                {" - "}
                {session.user.company.name}
              </Descriptions.Item>
              <Descriptions.Item label={t("when")}>
                {dayjs.unix(session.start).format(DATE_FORMAT_DD_MM_YYYY_HH_mm)}
              </Descriptions.Item>
              <Descriptions.Item label={t("session_duration")}>
                {!!session.end && dayjs(dayjs.unix(session.end).diff(dayjs.unix(session.start))).format("mm:ss")}
              </Descriptions.Item>
              <Descriptions.Item label={t("session_initial_path")}>{session.initialPath}</Descriptions.Item>
              <Descriptions.Item label={t("session_exit_path")}>{session.exitPath}</Descriptions.Item>
            </Descriptions>
            <div className="table-responsive pt-5">
              <h4>{t("products")}</h4>
              <Table
                columns={sessionDetailsColumns}
                dataSource={session.cart}
                pagination={false}
                rowKey="id"
                className="w-100"
                bordered={true}
              />
            </div>
          </Drawer>
        )}
      </Card>
    </>
  );
};

export default Sessions;
