import React, { useState } from "react";
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, message, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import * as dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/nl_NL";
import { DATE_FORMAT_DD_MM_YYYY } from "../../../../constants/DateConstant";
import { FIRESTORE_DISCOUNT_CODES_TABLE } from "../../../../constants/FirestoreConstant";
import { uniqueMergeArrays } from "../../../../functions/arrays";
import { InfoCircleOutlined } from "@ant-design/icons";
import { formatCategory } from "../../../../functions/formatters";

const AddDiscount = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const firestore = useFirestore();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { Option } = Select;
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  const discountCodes = useSelector((state) => state.firestoreReducer.ordered.discountCodes);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const categories = uniqueMergeArrays(products, "category");

  /**
   * Submit promotion form
   */
  const onSubmit = () => {
    form.validateFields().then(() => {
      setSubmitLoading(true);
      let fields = form.getFieldsValue();
      if (fields.discount > 0 && fields.amount > 0) {
        messageApi.error(t("notifications.discount_percentage_and_amount"));
        setSubmitLoading(false);
      } else if (fields.discount === 0 && fields.amount === 0) {
        messageApi.error(t("notifications.discount_percentage_and_amount_null"));
        setSubmitLoading(false);
      } else {
        let expirationDate = dayjs(fields.expiration_date).format(DATE_FORMAT_DD_MM_YYYY);
        let startDate = dayjs(fields.start_date).format(DATE_FORMAT_DD_MM_YYYY);
        firestore
          .collection(FIRESTORE_DISCOUNT_CODES_TABLE)
          .add({
            code: fields.code,
            discountPercentage: !!fields.discount ? fields.discount : null,
            discountAmount: !!fields.amount ? fields.amount : null,
            categories: !!fields.categories && fields.categories.length > 0 ? fields.categories : null,
            startDate: startDate,
            expirationDate: expirationDate,
            createdAt: firestore.FieldValue.serverTimestamp(),
            createdBy: email,
            active: true,
          })
          .then(() => {
            setSubmitLoading(false);
            messageApi.success(t("notifications.discount_code_created", 4));
            form.resetFields();
          })
          .catch((err) => {
            setSubmitLoading(false);
            messageApi.error(err.message);
          });
      }
    });
  };

  const validateDiscountCode = (value) => {
    if (discountCodes.length === 0) return true;
    const found = discountCodes.find((element) => element.code === value);
    return !found;
  };

  return (
    <>
      {contextHolder}
      <Col xs={24} sm={24} md={6}>
        <Form
          layout="vertical"
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          initialValues={{ discount: 0, amount: 0 }}
        >
          <Card title={t("create_discount_code")}>
            <Form.Item
              name="code"
              label={t("discount_code")}
              rules={[
                {
                  required: true,
                  message: t("form.enter_discount_code"),
                },
                () => ({
                  validator(rule, value) {
                    return validateDiscountCode(value)
                      ? Promise.resolve()
                      : Promise.reject(t("form.discount_code_not_unique"));
                  },
                }),
              ]}
            >
              <Input placeholder={t("discount_code")} />
            </Form.Item>
            <Form.Item
              name="discount"
              label={t("discount_percentage")}
              tooltip={{ title: i18n.t("form.discount_percentage_tooltip"), icon: <InfoCircleOutlined /> }}
              rules={[
                {
                  required: true,
                  message: t("form.enter_discount_percentage"),
                },
              ]}
            >
              <InputNumber
                className="w-100"
                min={0}
                max={99}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
              />
            </Form.Item>
            <Form.Item
              name="amount"
              label={t("discount_amount")}
              tooltip={{ title: i18n.t("form.discount_amount_tooltip"), icon: <InfoCircleOutlined /> }}
              rules={[
                {
                  required: true,
                  message: t("form.enter_discount_amount"),
                },
              ]}
            >
              <InputNumber
                className="w-100"
                formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
            </Form.Item>
            <Form.Item name="categories" label={i18n.t("categories")}>
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder={i18n.t("form.enter_category")}
                notFoundContent={<span>{i18n.t("table_no_categories")}</span>}
              >
                {categories.map((category) => (
                  <Option key={category}>{formatCategory(category, lang)}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="start_date"
              label={t("start_date")}
              rules={[
                {
                  required: true,
                  message: t("form.choose_start_date"),
                },
              ]}
            >
              <DatePicker
                locale={locale}
                format={DATE_FORMAT_DD_MM_YYYY}
                placeholder={t("start_date_placeholder")}
                disabledDate={(current) => {
                  return current < dayjs().startOf("day");
                }}
                style={{ width: "100%" }}
                onChange={(value) => {
                  setStartDate(value);
                  let expDate = form.getFieldValue("expiration_date");
                  if (!!expDate && expDate < value) form.resetFields(["expiration_date"]);
                }}
              />
            </Form.Item>
            <Form.Item
              name="expiration_date"
              label={t("expiration_date")}
              rules={[
                {
                  required: true,
                  message: t("form.choose_expiration_date"),
                },
              ]}
            >
              <DatePicker
                locale={locale}
                format={DATE_FORMAT_DD_MM_YYYY}
                placeholder={t("expiration_date_placeholder")}
                disabledDate={(current) => {
                  return current < dayjs().endOf("day") || (!!startDate && current < startDate.endOf("day"));
                }}
                showToday={false}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Button type="primary" onClick={() => onSubmit()} htmlType="submit" loading={submitLoading}>
              {t("save")}
            </Button>
          </Card>
        </Form>
      </Col>
    </>
  );
};

export default AddDiscount;
