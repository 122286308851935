/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import { Button, Card, Dropdown, Form, Input, Menu, message, Space, Table } from "antd";
import { DownOutlined, EyeOutlined, PrinterOutlined, SearchOutlined } from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import * as dayjs from "dayjs";
import utils from "utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CURRENCY } from "constants/DeliveryConstants";
import { useReactToPrint } from "react-to-print";
import { getBarcodeDataExact } from "../../../../functions/orders";
import { formatPrice } from "../../../../functions/formatters";
import { getExactOrders } from "../../../../urls";
import axios from "axios";
import { getFirebaseIDToken } from "../../../../functions/firebase/firebase";
import Barcode from "../../../../components/shared-components/Table/Barcode";
import { saveExactOrders } from "../../../../redux/actions/Exact";

const Orders = () => {
  const dispatch = useDispatch();
  const barcodeRef = useRef();
  const exact = useSelector((state) => state.exact);
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const customers = useSelector((state) => state.firestoreReducer.ordered.webshopUsers);
  const [messageApi, contextHolder] = message.useMessage();
  const [orders, setOrders] = useState(!!exact ? exact.orders : null);
  const [filteredList, setFilteredList] = useState(orders);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [order, setOrder] = useState(null);
  const { t } = useTranslation();
  const [datePickerForm] = Form.useForm();
  const [dateRange, setDateRange] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const handleSyncOrders = () => {
    setIsLoading(true);
    getFirebaseIDToken().then(async (token) => {
      const response = await axios.get(getExactOrders, { headers: { Authorization: token } }).catch((err) => {
        console.log("err: ", err);
        setIsLoading(false);
        messageApi.error(t("notifications.error_retrieving_exact_orders"));
      });
      const data = response.data;
      setOrders(data);
      setFilteredList(data);
      setIsLoading(false);
      dispatch(saveExactOrders(data));
    });
  };

  const showDrawer = (row) => {
    setVisible(true);
    setOrder(row);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  const handleManualPrint = (functionName) => {
    setIsPrinting(true);
    setTimeout(() => {
      functionName();
    }, 1000);
  };

  const handleBarcodesPrint = useReactToPrint({
    contentRef: barcodeRef,
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });

  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item>
        <a onClick={() => showDrawer(row)}>
          <Flex alignItems="center">
            <EyeOutlined />
            <span style={{ marginLeft: 4 }}>{t("details")}</span>
          </Flex>
        </a>
      </Menu.Item>
    </Menu>
  );

  const printMenu = (
    <Menu>
      <Menu.Item key="5" onClick={() => handleManualPrint(handleBarcodesPrint)}>
        {t("order.barcodes")}
      </Menu.Item>
    </Menu>
  );

  const tableColumns = [
    {
      title: t("order.order_id"),
      dataIndex: "YourRef",
    },
    {
      title: t("company"),
      dataIndex: "OrderedBy",
      render: (data) => {
        const customer = customers?.find((customer) => customer.exactAccountGuid === data);
        return <span>{customer ? customer.company?.name : "-"}</span>;
      },
    },
    {
      title: t("order_date"),
      dataIndex: "OrderDate",
      render: (data) => {
        const timestamp = data.match(/\(([^)]+)\)/)[1];
        return <span>{dayjs.unix(timestamp / 1000).format("ddd D MMMM 'YY")}</span>;
      },
      sorter: (a, b) => utils.antdTableSorter(a, b, "OrderDate"),
      defaultSortOrder: "ascend",
    },
    {
      title: t("total"),
      dataIndex: "totalPrice",
      render: (_, order) => {
        const totalPrice = order.SalesOrderLines.results
          .map((product) => product.UnitPrice * product.Quantity)
          .reduce((a, b) => a + b, 0);
        return <span>{CURRENCY + formatPrice(totalPrice)}</span>;
      },
      sorter: (a, b) => utils.antdTableSorter(a, b, "totalPrice"),
    },
  ];

  const rowSelection = {
    onChange: (key, rows) => {
      setSelectedRows(rows);
      setSelectedRowKeys(key);
      if (!!dateRange && dateRange.length > 0) {
        setDateRange([]);
        datePickerForm.resetFields();
      }
    },
  };

  const onSearch = (e) => {
    const value = e.currentTarget.value;
    setSearchText(value);
    const data = orders.filter((order) => {
      return order.YourRef?.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredList(data);
    setSelectedRowKeys([]);
  };

  return (
    <>
      {contextHolder}
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 8,
            gap: 8,
            flexWrap: "wrap",
          }}
        >
          <div style={{ display: "flex", gap: 8 }}>
            <Space wrap>
              <Input
                value={searchText}
                placeholder={t("search")}
                prefix={<SearchOutlined />}
                onChange={(e) => onSearch(e)}
              />
            </Space>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <Dropdown overlay={printMenu}>
              <Button className="ant-btn-primary">
                <PrinterOutlined /> {t("print")} <DownOutlined />
              </Button>
            </Dropdown>
            <Button type={"primary"} onClick={handleSyncOrders} loading={isLoading}>
              {t("sync")}
            </Button>
          </div>
        </div>
        <div className="table-responsive">
          <Table
            columns={tableColumns}
            dataSource={filteredList}
            rowKey="OrderID"
            rowSelection={{
              selectedRowKeys: selectedRowKeys,
              type: "checkbox",
              preserveSelectedRowKeys: false,
              ...rowSelection,
            }}
            scroll={{ x: 1300 }}
            loading={isLoading}
          />
        </div>
        <div style={{ display: "none" }}>
          {isPrinting && (
            <>
              <Barcode ref={barcodeRef} data={getBarcodeDataExact(selectedRows, products)} />
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default Orders;
