import React from "react";
import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  TreeSelect,
  Upload,
} from "antd";
import i18n from "translations/i18n";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  ADDITIONAL_INFO_TEXT_MAX_CHAR,
  ADDITIONAL_INFO_TEXT_ROWS,
  DESCRIPTION_TEXT_MAX_CHAR,
  DESCRIPTION_TEXT_ROWS,
  SHORT_DESCRIPTION_TEXT_MAX_CHAR,
  SHORT_DESCRIPTION_TEXT_ROWS,
} from "../../../../constants/InputConstant";
import ImgCrop from "antd-img-crop";
import {
  PRODUCT_COLORS,
  PRODUCT_DIMENSION_TYPE_HEIGHT_DIAMETER,
  PRODUCT_DIMENSION_TYPE_LENGTH_WIDTH,
  PRODUCT_DIMENSION_TYPE_LENGTH_WIDTH_HEIGHT,
  PRODUCT_IMAGE_ASPECT_RATIO,
} from "constants/ProductConstant";
import { validProductUrl } from "../../../../functions/validator";
import datePickerLocale from "antd/es/date-picker/locale/nl_NL";
import * as dayjs from "dayjs";
import styled from "styled-components";
import { cloneDeep } from "lodash";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import UploadList from "antd/es/upload/UploadList";
// import nl_NL from "antd/es/locale-provider/nl_NL";

const { Option } = Select;

const imageUploadProps = {
  name: "file",
  multiple: true,
  listType: "picture-card",
};

/**
 * Image uploader expects to do a request, so we mock one
 */
const dummyRequest = ({ file, onSuccess }) => {
  onSuccess("ok");
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>{i18n.t("upload")}</div>
  </div>
);

const GeneralField = (props) => {
  const [messageApi, contextHolder] = message.useMessage();

  /**
   * When file is uploaded, check for file type and image size
   * @param {*} file The uploaded file
   * @returns Boolean that tells Upload component whether to add image or not
   */
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      messageApi.error(i18n.t("notifications.only_png_jpg"));
      return Upload.LIST_IGNORE;
    }
    return isJpgOrPng;
  };

  const rules = {
    name: [
      {
        required: true,
        message: i18n.t("form.enter_product_name"),
      },
    ],
    price: [
      {
        required: true,
        message: i18n.t("form.enter_price"),
      },
    ],
    taxRate: [
      {
        required: true,
        message: i18n.t("form.enter_taxrate"),
      },
    ],
    category: [
      {
        required: true,
        message: i18n.t("form.enter_category"),
      },
    ],
    discount: [
      {
        required: true,
        message: i18n.t("form.enter_discount"),
      },
    ],
    stock: [
      {
        required: true,
        message: i18n.t("form.enter_stock"),
      },
    ],
    color: [
      {
        required: true,
        message: i18n.t("form.choose_color"),
      },
    ],
    height: [
      {
        required: true,
        message: i18n.t("form.choose_height"),
      },
    ],
    diameter: [
      {
        required: true,
        message: i18n.t("form.choose_diameter"),
      },
    ],
    url: [
      {
        required: true,
        message: i18n.t("form.enter_url"),
      },
      () => ({
        validator(rule, value) {
          return validProductUrl(value, props.productId, props.products)
            ? Promise.resolve()
            : Promise.reject(i18n.t("form.url_not_unique"));
        },
      }),
    ],
    min_amount: [
      {
        required: true,
        message: i18n.t("form.enter_min_amount"),
      },
      () => ({
        validator(rule, value) {
          let orderUnit = props.form.getFieldValue("orderUnit");
          return orderUnit > value || value % orderUnit === 0
            ? Promise.resolve()
            : Promise.reject(i18n.t("form.min_amount_plural_of_order_unit"));
        },
      }),
    ],
    orderUnit: [
      {
        required: true,
        message: i18n.t("form.enter_order_unit"),
      },
      () => ({
        validator(rule, value) {
          let minAmount = props.form.getFieldValue("min_amount");
          return value > minAmount || minAmount % value === 0
            ? Promise.resolve()
            : Promise.reject(i18n.t("form.order_unit_higher_or_dividable_min_amount"));
        },
      }),
    ],
  };

  const onDragEnd = ({ source, destination }) => {
    const reorder = (list, startIndex, endIndex) => {
      const [removed] = list.splice(startIndex, 1);
      list.splice(endIndex, 0, removed);

      return list;
    };

    const newFileList = reorder(cloneDeep(props.fileList), source.index, destination.index);
    props.handleUploadOrderChange(newFileList);
  };

  return (
    <>
      {contextHolder}
      <Row gutter={16}>
        <Col xs={24} sm={24} md={17}>
          <Card title={i18n.t("product_info")}>
            <Form.Item name="name" label={i18n.t("product_name")} rules={rules.name}>
              <Input placeholder={i18n.t("name")} onChange={(e) => props.formatProductUrl(e.target.value)} />
            </Form.Item>
            <Form.Item name="code" label={i18n.t("product_code")}>
              <Input placeholder={i18n.t("product_code")} />
            </Form.Item>
            <Form.Item name="shortDescription" label={i18n.t("short_description")}>
              <Input.TextArea
                rows={SHORT_DESCRIPTION_TEXT_ROWS}
                showCount
                maxLength={SHORT_DESCRIPTION_TEXT_MAX_CHAR}
                placeholder={i18n.t("short_description")}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label={i18n.t("description")}
              tooltip={{ title: i18n.t("form.description_tooltip"), icon: <InfoCircleOutlined /> }}
            >
              <Input.TextArea
                rows={DESCRIPTION_TEXT_ROWS}
                showCount
                maxLength={DESCRIPTION_TEXT_MAX_CHAR}
                placeholder={i18n.t("description")}
              />
            </Form.Item>
            <Form.Item
              name="additionalInfo"
              label={i18n.t("additional_info")}
              tooltip={{ title: i18n.t("form.description_tooltip"), icon: <InfoCircleOutlined /> }}
            >
              <Input.TextArea
                rows={ADDITIONAL_INFO_TEXT_ROWS}
                showCount
                maxLength={ADDITIONAL_INFO_TEXT_MAX_CHAR}
                placeholder={i18n.t("additional_info")}
              />
            </Form.Item>
            <Row gutter={16}>
              <Col lg={6}>
                <Form.Item name="stock" label={i18n.t("stock")} rules={rules.stock}>
                  <InputNumber
                    className="w-100"
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </Col>
              <Col lg={6}>
                <Form.Item name="min_amount" label={i18n.t("min_amount")} rules={rules.min_amount}>
                  <InputNumber
                    className="w-100"
                    min={1}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </Col>
              <Col lg={6}>
                <Form.Item name="orderUnit" label={i18n.t("order_unit")} rules={rules.orderUnit}>
                  <InputNumber
                    className="w-100"
                    min={1}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </Col>
              <Col lg={6}>
                <Form.Item
                  name="amountInOuterBox"
                  label={i18n.t("amount_in_outer_box")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("form.enter_value"),
                    },
                  ]}
                >
                  <InputNumber
                    className="w-100"
                    min={1}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title={i18n.t("seo")}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                <Form.Item name={"url"} label={i18n.t("product_url")} rules={rules.url}>
                  <Input addonBefore={process.env.REACT_APP_PRODUCT_URL} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card title={i18n.t("pricing")}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="price" label={i18n.t("price")} rules={rules.price}>
                  <InputNumber
                    className="w-100"
                    formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="taxRate" label={i18n.t("tax_rate")} rules={rules.taxRate}>
                  <InputNumber
                    className="w-100"
                    min={0}
                    max={100}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace("%", "")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="discount" label={i18n.t("discount")} rules={rules.discount}>
              <InputNumber
                className="w-100"
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
              />
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={7}>
          <Card title={i18n.t("media")}>
            <ImgCrop
              modalWidth={1000}
              minZoom={0}
              modalTitle={i18n.t("crop_image")}
              modalOk={i18n.t("add")}
              modalCancel={i18n.t("close")}
              aspect={PRODUCT_IMAGE_ASPECT_RATIO}
              cropperProps={{
                restrictPosition: false,
              }}
            >
              <Upload
                {...imageUploadProps}
                fileList={props.fileList}
                beforeUpload={beforeUpload}
                onChange={(e) => props.handleUploadChange(e)}
                onPreview={(e) => props.handlePreview(e)}
                customRequest={dummyRequest}
                maxCount={4}
                multiple={false}
              >
                {props.fileList.length >= 4 ? null : uploadButton}
              </Upload>
            </ImgCrop>
            {/*{props.fileList && (*/}
            {/*  <DragDropContext onDragEnd={onDragEnd}>*/}
            {/*    <Droppable droppableId="droppable">*/}
            {/*      {(provided) => (*/}
            {/*        <div ref={provided.innerRef} {...provided.droppableProps}>*/}
            {/*          {props.fileList.map((item, index) => (*/}
            {/*            <Draggable key={item.uid} draggableId={item.uid} index={index}>*/}
            {/*              {(provided) => (*/}
            {/*                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>*/}
            {/*                  <UploadList*/}
            {/*                    showDownloadIcon={false}*/}
            {/*                    listType={props.listType}*/}
            {/*                    onPreview={props.handlePreview}*/}
            {/*                    locale={nl_NL}*/}
            {/*                    items={[item]}*/}
            {/*                  />*/}
            {/*                </div>*/}
            {/*              )}*/}
            {/*            </Draggable>*/}
            {/*          ))}*/}
            {/*          {provided.placeholder}*/}
            {/*        </div>*/}
            {/*      )}*/}
            {/*    </Droppable>*/}
            {/*  </DragDropContext>*/}
            {/*)}*/}
            {props.showReorderNotification && (
              <ReorderNotification>{i18n.t("reorderImagesNotification")}</ReorderNotification>
            )}
            <Modal
              visible={props.previewVisible}
              title={props.previewTitle}
              footer={null}
              onCancel={props.handlePreviewCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={props.previewImage} />
            </Modal>
          </Card>
          <Card title={i18n.t("category")}>
            <Form.Item name="category" label={i18n.t("categories")} rules={rules.category}>
              <TreeSelect
                showSearch
                style={{ width: "100%" }}
                placeholder={i18n.t("form.choose_specific_category")}
                allowClear
                treeDefaultExpandAll
                treeData={props.categories?.map((category) => {
                  return {
                    key: category.categoryId,
                    title: category.name,
                    value: category.categoryId,
                    children: category.subcategories?.map((subcategory) => {
                      return {
                        title: subcategory.name,
                        value: subcategory.subcategoryId,
                        key: subcategory.subcategoryId,
                      };
                    }),
                  };
                })}
              />
            </Form.Item>
          </Card>
          <Card title={i18n.t("attributes")}>
            <Form.Item name="color" label={i18n.t("color")} rules={rules.color}>
              <Select placeholder={i18n.t("form.choose_color")}>
                {PRODUCT_COLORS.map((color) => (
                  <Option key={color.id} value={color.id}>
                    <ColorImage src={color.img} alt={color.name} /> {color.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Radio.Group
              onChange={(e) => props.setProductDimensionType(e.target.value)}
              value={props.productDimensionType}
              className={"mb-3"}
            >
              <Space direction="vertical">
                <Radio value={PRODUCT_DIMENSION_TYPE_HEIGHT_DIAMETER} style={{ alignItems: "center" }}>
                  {i18n.t("height_diameter")}
                </Radio>
                <Radio value={PRODUCT_DIMENSION_TYPE_LENGTH_WIDTH} style={{ alignItems: "center" }}>
                  {i18n.t("length_width")}
                </Radio>
                <Radio value={PRODUCT_DIMENSION_TYPE_LENGTH_WIDTH_HEIGHT} style={{ alignItems: "center" }}>
                  {i18n.t("length_width_height")}
                </Radio>
              </Space>
            </Radio.Group>
            {props.productDimensionType === PRODUCT_DIMENSION_TYPE_LENGTH_WIDTH_HEIGHT ? (
              <Row gutter={12}>
                <Col lg={8}>
                  <Form.Item name="length" label={i18n.t("length")} rules={rules.height}>
                    <InputNumber className="w-100" />
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <Form.Item name="width" label={i18n.t("width")}>
                    <InputNumber className="w-100" />
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <Form.Item name="height" label={i18n.t("height")}>
                    <InputNumber className="w-100" />
                  </Form.Item>
                </Col>
              </Row>
            ) : props.productDimensionType === PRODUCT_DIMENSION_TYPE_HEIGHT_DIAMETER ? (
              <Row gutter={12}>
                <Col lg={12}>
                  <Form.Item name="height" label={i18n.t("height")} rules={rules.height}>
                    <InputNumber className="w-100" />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item name="diameter" label={i18n.t("diameter")} rules={rules.height}>
                    <InputNumber className="w-100" />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row gutter={12}>
                <Col lg={12}>
                  <Form.Item name="length" label={i18n.t("length")} rules={rules.height}>
                    <InputNumber className="w-100" />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item name="width" label={i18n.t("width")}>
                    <InputNumber className="w-100" />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Card>
          <Card title={i18n.t("pre_order")}>
            <Row>
              <Col lg={12}>
                <Form.Item
                  name="preorder_available_date"
                  label={i18n.t("pre_order_available_date")}
                  className={"w-100"}
                >
                  <DatePicker
                    locale={datePickerLocale}
                    format={"DD-MM-YYYY"}
                    showToday={false}
                    disabledDate={(current) => current && current < dayjs().endOf("day")}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item name="preorder_active" label={i18n.t("pre_order_active")} valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const ReorderNotification = styled.p`
  color: #ff2020;
  font-weight: 500;
  margin-bottom: 0;
`;

const ColorImage = styled.img`
  width: 18px;
  margin-right: 3px;
`;

export default GeneralField;
