/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Descriptions,
  Drawer,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  DownOutlined,
  EyeOutlined,
  FileExcelOutlined,
  MailOutlined,
  PrinterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import Flex from "components/shared-components/Flex";
import * as dayjs from "dayjs";
import { DATE_FORMAT_DD_MM_YYYY, DATE_FORMAT_DD_MM_YYYY_HH_mm, DATE_FORMAT_YYYY_MM_DD } from "constants/DateConstant";
import utils from "utils";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { uniqueMergeArrays } from "../../../../functions/arrays";
import { isLoaded, useFirestore } from "react-redux-firebase";
import { CURRENCY, DELIVERY_OPTIONS } from "constants/DeliveryConstants";
import Text from "antd/lib/typography/Text";
import { FIRESTORE_ORDERS_TABLE } from "../../../../constants/FirestoreConstant";
import {
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_CREATED,
  ORDER_STATUS_PAID,
  ORDER_STATUS_PAYMENT_PENDING,
  ORDER_STATUS_RETURNED,
  ORDER_STATUS_SHIPPED,
} from "../../../../constants/OrderConstant";
import { getExcelExportData } from "functions/excel";
import { useReactToPrint } from "react-to-print";
import { calculateTaxFromExVAT, getBarcodeData } from "../../../../functions/orders";
import { formatPrice, getPaymentStatus } from "../../../../functions/formatters";
import _ from "lodash";
import locale from "antd/es/date-picker/locale/nl_NL";
import { COMPANY_CONSTANTS } from "../../../../constants/CompanyConstants";
import { setOrdersMaxDaysAgo } from "../../../../redux/actions/Queries";
import { getProductCodeById } from "../../../../functions/products";
import { sendOrderToExact } from "../../../../functions/api/exact";
import { isMobile } from "../../../../functions/browser";
import { resend_confirmation_email } from "../../../../urls";
import axios from "axios";
import { getFirebaseIDToken } from "../../../../functions/firebase/firebase";
import Barcode from "../../../../components/shared-components/Table/Barcode";
import isBetween from "dayjs/plugin/isBetween";
import ExcelExport from "../../../../components/shared-components/ExcelExport";

dayjs.extend(isBetween);

const { Option } = Select;
const { RangePicker } = DatePicker;

const Orders = () => {
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const barcodeRef = useRef();
  const orders = useSelector((state) => state.firestoreReducer.ordered.orders);
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const orderMaxDaysAgoQuery = useSelector((state) => state.queries.ordersMaxDaysAgo);
  const [messageApi, contextHolder] = message.useMessage();
  const [filteredList, setFilteredList] = useState(orders);
  const [paymentStatusList, setPaymentStatusList] = useState([]);
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [visible, setVisible] = useState(false);
  const [order, setOrder] = useState(null);
  const { t } = useTranslation();
  const [datePickerForm] = Form.useForm();
  const [dateRange, setDateRange] = useState([]);
  const [orderTax, setOrderTax] = useState(null);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("All");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (!!orders) {
      let filteredOrders = orders.filter((order) => order.orderStatus !== ORDER_STATUS_CREATED);
      setFilteredList(filteredOrders);
      setPaymentStatusList(uniqueMergeArrays(orders, "orderStatus"));
      setOrderStatusList([ORDER_STATUS_PAID, ORDER_STATUS_SHIPPED, ORDER_STATUS_COMPLETED, ORDER_STATUS_RETURNED]);
    }
  }, [orders]);

  const handleShowStatus = (value) => {
    setSelectedOrderStatus(value);
    setSearchText("");
    if (value !== "All") {
      const key = "orderStatus";
      const filteredArray = utils.filterArray(orders, key, value);
      setFilteredList(filteredArray);
    } else {
      setFilteredList(orders);
    }
  };

  const showDrawer = (row) => {
    setVisible(true);
    if (row.vat)
      setOrderTax(
        calculateTaxFromExVAT(
          row.products,
          row.delivery.cost,
          !!row.discount ? row.discount.percentage : null,
          !!row.discount ? row.discount.amount : null,
          !!row.discount ? row.discount.categories : null,
        ),
      );
    setOrder(row);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  const formatShippingMethod = (value) => {
    if (value === DELIVERY_OPTIONS.SEND) return t("shipping");
    if (value === DELIVERY_OPTIONS.PICKUP) return t("pickup");
  };

  const getPaymentStatusColor = (status) => {
    if (status === ORDER_STATUS_PAID) return "geekblue";
    else if (status === ORDER_STATUS_SHIPPED) return "lime";
    else if (status === ORDER_STATUS_COMPLETED) return "cyan";
    else if (status === ORDER_STATUS_RETURNED) return "orange";
    else if (status === ORDER_STATUS_CREATED) return "red";
    else if (status === ORDER_STATUS_PAYMENT_PENDING) return "orange";
  };

  const handleManualPrint = (functionName) => {
    setIsPrinting(true);
    setTimeout(() => {
      functionName();
    }, 1000);
  };

  const handleBarcodesPrint = useReactToPrint({
    contentRef: barcodeRef,
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });

  const updateFirebaseOrderStatus = (order, status) => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    firestore
      .collection(FIRESTORE_ORDERS_TABLE)
      .doc(order.id)
      .update({ orderStatus: status })
      .then(() => messageApi.success(t("order.status_changed_success")))
      .catch(() => messageApi.error(t("order.status_changed_failure")));
  };

  const getFilteredOrders = (orderStatusFilter) => {
    let filteredOrders;
    // If orderStatusFilter = PAID -> Get only paid orders
    // If not -> Get all orders except those with CREATED status
    if (!!dateRange && dateRange.length > 0) {
      filteredOrders =
        orderStatusFilter === ORDER_STATUS_PAID
          ? filterOrdersDateRange(orders, ORDER_STATUS_PAID, dateRange, true)
          : filterOrdersDateRange(orders, ORDER_STATUS_CREATED, dateRange, false);
    } else {
      filteredOrders =
        orderStatusFilter === ORDER_STATUS_PAID
          ? filterOrdersRows(selectedRows, ORDER_STATUS_PAID)
          : filterOrdersRows(selectedRows, null);
    }

    // Order by date ascending
    return _.orderBy(filteredOrders, ["createdAt"], ["asc"]);
  };

  const filterOrdersDateRange = (orders, statusFilter, dateRange, isEqual) => {
    let startDate = dateRange[0].format(DATE_FORMAT_YYYY_MM_DD);
    let endDate = dateRange[1].format(DATE_FORMAT_YYYY_MM_DD);
    return _.filter(orders, function (order) {
      return (
        (isEqual ? order.orderStatus === statusFilter : order.orderStatus !== statusFilter) &&
        dayjs(order.delivery.date, DATE_FORMAT_DD_MM_YYYY).isBetween(startDate, endDate, undefined, "[]")
      );
    });
  };

  const filterOrdersRows = (orders, statusFilter) => {
    return !!statusFilter
      ? _.filter(orders, function (order) {
          return order.orderStatus === statusFilter;
        })
      : orders;
  };

  const orderStatusMenu = (order) => (
    <Menu>
      {orderStatusList
        .filter((item) => item !== order.orderStatus)
        .map((item, key) => {
          return (
            <Menu.Item key={key}>
              <Popconfirm
                title={
                  <p>
                    {t("order.change_status.intro", { orderId: order.orderId })}{" "}
                    <b>
                      {t("order.change_status.bold", {
                        status: getPaymentStatus(item).toLowerCase(),
                      })}
                    </b>
                    {t("order.change_status.end")}
                  </p>
                }
                onConfirm={() => updateFirebaseOrderStatus(order, item)}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <a onClick={(e) => e.preventDefault()}>{getPaymentStatus(item)}</a>
              </Popconfirm>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const exactStatusMenu = (order) => (
    <Menu>
      <Menu.Item>
        <a onClick={(e) => handleSendToExact(e, order)}>{t("send_to_exact")}</a>
      </Menu.Item>
    </Menu>
  );

  const resendConfirmationMail = (order) => {
    axios
      .post(resend_confirmation_email, { order: order, customerEmail: order.customer.email })
      .then((res) => {
        messageApi.success(t("notifications.resend_confirmation_mail_success"));
      })
      .catch((err) => messageApi.error(err.message));
  };

  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item>
        <a onClick={() => showDrawer(row)}>
          <Flex alignItems="center">
            <EyeOutlined />
            <span style={{ marginLeft: 4 }}>{t("details")}</span>
          </Flex>
        </a>
      </Menu.Item>
      <Menu.Item>
        <Flex alignItems="center">
          <MailOutlined />
          <Popconfirm
            placement="bottom"
            title={t("confirm_resend_confirmation_mail", {
              customer: row.customer.company,
            })}
            onConfirm={() => resendConfirmationMail(row)}
            okText={t("yes")}
            cancelText={t("no")}
          >
            <span style={{ marginLeft: 4 }}>{t("resend_confirmation_mail")}</span>
          </Popconfirm>
        </Flex>
      </Menu.Item>
    </Menu>
  );

  const exportMenu = (
    <Menu>
      <Menu.Item key="1">
        <ExcelExport
          data={
            selectedRowKeys.length > 0 || (!!dateRange && dateRange.length > 0)
              ? getExcelExportData(getFilteredOrders(null))
              : getExcelExportData([])
          }
          fileName={COMPANY_CONSTANTS.WEBSITE_NAME + " bestellingen " + dayjs().format(DATE_FORMAT_DD_MM_YYYY)}
        >
          {t("excel")}
        </ExcelExport>
      </Menu.Item>
    </Menu>
  );

  const handleSendToExact = (e, order) => {
    e.preventDefault();
    getFirebaseIDToken().then((token) => {
      sendOrderToExact(order, token)
        .then(() => messageApi.success(t("notifications.order_saved_to_exact")))
        .catch(() => messageApi.error(t("notifications.order_not_saved_to_exact")));
    });
  };

  const printMenu = (
    <Menu>
      {/*<Menu.Item key="1" onClick={handleOrderListPrint}>*/}
      {/*  {t("order.orders")}*/}
      {/*</Menu.Item>*/}
      {/*<Menu.Item key="2" onClick={handlePickListPrint}>*/}
      {/*  {t("order.pick_list")}*/}
      {/*</Menu.Item>*/}
      {/*<Menu.Item key="3" onClick={handlePackingSlipPrint}>*/}
      {/*  {t("order.packing_slips")}*/}
      {/*</Menu.Item>*/}
      {/*<Menu.Item key="4" onClick={handleSendListPrint}>*/}
      {/*  {t("order.send_labels")}*/}
      {/*</Menu.Item>{" "}*/}
      <Menu.Item key="5" onClick={() => handleManualPrint(handleBarcodesPrint)}>
        {t("order.barcodes")}
      </Menu.Item>
    </Menu>
  );

  const tableColumns = [
    {
      title: t("order.order_id"),
      dataIndex: "orderId",
    },
    {
      title: t("email"),
      dataIndex: "customer",
      render: (data) => <span>{data.email}</span>,
    },
    {
      title: t("company"),
      dataIndex: "customer",
      render: (data) => <span>{data.company}</span>,
    },
    {
      title: t("order_date"),
      dataIndex: "createdAt",
      render: (data) => <span>{dayjs.unix(data).format(DATE_FORMAT_DD_MM_YYYY_HH_mm)}</span>,
      sorter: (a, b) => utils.antdTableSorter(a, b, "createdAt"),
      defaultSortOrder: "descend",
    },
    {
      title: t("preferred_delivery_date"),
      dataIndex: "delivery",
      render: (data) => (
        <span>
          {!!data && !!data.preferredDateTimestamp
            ? dayjs.unix(data.preferredDateTimestamp).format("dddd D MMMM")
            : "-"}
        </span>
      ),
      sorter: (a, b) => a.delivery.preferredDateTimestamp - b.delivery.preferredDateTimestamp,
    },
    {
      title: t("status"),
      dataIndex: "orderStatus",
      render: (_, order) => (
        <>
          <Dropdown overlay={orderStatusMenu(order)} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Tag color={getPaymentStatusColor(order.orderStatus)}>{getPaymentStatus(order.orderStatus)}</Tag>
            </a>
          </Dropdown>
        </>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "orderStatus"),
    },
    {
      title: t("saved_to_exact"),
      dataIndex: "savedToExact",
      render: (_, order) =>
        order.savedToExact ? (
          <span>{t("yes")}</span>
        ) : (
          <Dropdown overlay={exactStatusMenu(order)} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <span>{t("no")}</span>
            </a>
          </Dropdown>
        ),
    },
    {
      title: t("total"),
      dataIndex: "totalPrice",
      render: (_, order) =>
        !!order.vat ? (
          <div>
            <span className="font-weight-semibold">{CURRENCY + formatPrice(order.totalPrice)}</span>
            <span> - ({CURRENCY + formatPrice(order.vat + order.totalPrice)})</span>
          </div>
        ) : (
          <span className="font-weight-semibold">{CURRENCY + formatPrice(order.totalPrice)}</span>
        ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "totalPrice"),
    },
    {
      title: "",
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  const orderDetailsColumns = [
    {
      title: t("name"),
      render: (_, data) => (
        <div className="d-flex">
          <span>
            {data.name} {data.code ? "(" + data.code + ")" : "(" + getProductCodeById(data.productId, products) + ")"}
          </span>
        </div>
      ),
    },
    {
      title: t("price"),
      dataIndex: "originalPrice",
      render: (data) => <span style={{ whiteSpace: "nowrap" }}>{CURRENCY + formatPrice(data)}</span>,
      align: "center",
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      render: (data) => <span>{data}</span>,
      align: "center",
    },
    {
      title: t("discount"),
      dataIndex: "discountPercentage",
      render: (data) => <span>{data}%</span>,
      align: "center",
    },
    {
      title: t("sub_total"),
      dataIndex: "paymentPrice",
      render: (_, data) => (
        <span style={{ whiteSpace: "pre-line" }}>{CURRENCY + formatPrice(data.paymentPrice * data.quantity)}</span>
      ),
      align: "right",
    },
  ];

  const rowSelection = {
    onChange: (key, rows) => {
      setSelectedRows(rows);
      setSelectedRowKeys(key);
      if (!!dateRange && dateRange.length > 0) {
        setDateRange([]);
        datePickerForm.resetFields();
      }
    },
  };

  const onSearch = (e) => {
    const value = e.currentTarget.value;
    setSearchText(value);
    const data = utils.wildCardSearchOnOrder(orders, value, selectedOrderStatus);
    setFilteredList(data);
    setSelectedRowKeys([]);
  };

  const handleDateRange = (dateRange) => {
    setDateRange(dateRange);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  return (
    <>
      {contextHolder}
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 8,
            gap: 8,
            flexWrap: "wrap",
          }}
        >
          <div style={{ display: "flex", gap: 8 }}>
            <Space wrap>
              <Input
                value={searchText}
                placeholder={t("search")}
                prefix={<SearchOutlined />}
                onChange={(e) => onSearch(e)}
              />
              <Select
                defaultValue="All"
                className="w-100"
                style={{ minWidth: 180 }}
                onChange={handleShowStatus}
                placeholder={t("status")}
              >
                <Option value="All">{t("all")}</Option>
                {paymentStatusList.map((elm) => (
                  <Option key={elm} value={elm}>
                    {getPaymentStatus(elm)}
                  </Option>
                ))}
              </Select>
            </Space>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <Tooltip title={t("pickup_delivery_date")}>
              <Form form={datePickerForm}>
                <Form.Item name="dateRange" style={{ marginBottom: 0 }}>
                  <RangePicker
                    onChange={(e) => handleDateRange(e)}
                    locale={locale}
                    placeholder={[t("start_date"), t("end_date")]}
                    format={DATE_FORMAT_DD_MM_YYYY}
                  />
                </Form.Item>
              </Form>
            </Tooltip>
            <Dropdown overlay={printMenu}>
              <Button className="ant-btn-primary">
                <PrinterOutlined /> {t("print")} <DownOutlined />
              </Button>
            </Dropdown>
            <Dropdown overlay={exportMenu}>
              <Button className="ant-btn-primary">
                <FileExcelOutlined /> {t("export")} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
        <div className="table-responsive">
          <Table
            columns={tableColumns}
            dataSource={filteredList}
            rowKey="id"
            rowSelection={{
              selectedRowKeys: selectedRowKeys,
              type: "checkbox",
              preserveSelectedRowKeys: false,
              ...rowSelection,
            }}
            scroll={{ x: 1300 }}
            loading={!isLoaded(orders)}
          />
        </div>
        {!!order && (
          <Drawer
            title={order.orderId}
            placement="right"
            onClose={closeDrawer}
            visible={visible}
            width={isMobile ? window.innerWidth : 720}
          >
            <Descriptions
              bordered
              title={
                <div className="d-flex justify-content-between">
                  <span>{t("order.order")}</span>
                  <Tag color={getPaymentStatusColor(order.orderStatus)} className="float-right">
                    {getPaymentStatus(order.orderStatus)}
                  </Tag>
                </div>
              }
              size="small"
              column={1}
            >
              <Descriptions.Item label={t("order_date")}>
                {dayjs.unix(order.createdAt).format(DATE_FORMAT_DD_MM_YYYY_HH_mm)}
              </Descriptions.Item>
              <Descriptions.Item label={t("company")}>{order.customer.company}</Descriptions.Item>
              <Descriptions.Item label={t("email")}>{order.customer.email}</Descriptions.Item>
              <Descriptions.Item label={t("phone")}>{order.customer.phoneNumber}</Descriptions.Item>
              <Descriptions.Item label={t("delivery_address")}>
                {order.customer.address.delivery.firstName} {order.customer.address.delivery.lastName} <br />
                {order.customer.address.delivery.companyName} {order.customer.address.delivery.companyName && <br />}
                {order.customer.address.delivery.street} {order.customer.address.delivery.houseNumber}{" "}
                {order.customer.address.delivery.addition}
                <br />
                {order.customer.address.delivery.zipCode}, {order.customer.address.delivery.city}
              </Descriptions.Item>
              {!!order.customer.address.invoice && (
                <Descriptions.Item label={t("invoice_address")}>
                  {order.customer.address.invoice.firstName} {order.customer.address.invoice.lastName} <br />
                  {order.customer.address.invoice.companyName} {order.customer.address.invoice.companyName && <br />}
                  {order.customer.address.invoice.street} {order.customer.address.invoice.houseNumber}{" "}
                  {order.customer.address.invoice.addition}
                  <br />
                  {order.customer.address.invoice.zipCode}, {order.customer.address.invoice.city}
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t("preferred_delivery_date")}>
                {!!order.delivery.preferredDateTimestamp
                  ? dayjs.unix(order.delivery.preferredDateTimestamp).format("dddd D MMMM")
                  : "-"}
              </Descriptions.Item>
              <Descriptions.Item label={t("order_notes")}>
                {!!order.orderNotes ? order.orderNotes : "-"}
              </Descriptions.Item>
              <Descriptions.Item label={t("sales_person")}>
                {!!order.salesPersonName ? order.salesPersonName : "-"}
              </Descriptions.Item>
            </Descriptions>
            <div className="table-responsive pt-5">
              <h4>{t("products")}</h4>
              <Table
                columns={orderDetailsColumns}
                dataSource={order.products}
                pagination={false}
                rowKey="variantId"
                className="w-100"
                bordered={true}
                summary={(pageData) => {
                  let totalAmount = 0;
                  let totalDiscount = 0;

                  pageData.forEach(({ quantity, discount }) => {
                    totalAmount += quantity;
                    totalDiscount += discount;
                  });
                  return (
                    !!order && (
                      <>
                        {order.delivery.type === DELIVERY_OPTIONS.SEND && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              <Text>{t("shipping")}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell align="right">
                              <Text>{!!order.delivery.cost ? "€" + formatPrice(order.delivery.cost) : "-"}</Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                        {!!order.discount && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              <Text>
                                {!!order.discount.percentage ? (
                                  <Trans
                                    i18nKey="cart_discount_percentage"
                                    values={{
                                      discountPercentage: order.discount.percentage,
                                      discountCode: order.discount.code,
                                    }}
                                  />
                                ) : (
                                  <Trans
                                    i18nKey="cart_discount_amount"
                                    values={{
                                      discountAmount: order.discount.amount,
                                      discountCode: order.discount.code,
                                    }}
                                  />
                                )}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell />
                            <Table.Summary.Cell align="right">
                              <Text>- {CURRENCY + formatPrice(order.discount.totalDiscount)}</Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                        <Table.Summary.Row>
                          <Table.Summary.Cell>
                            <Text>{t("shipping")}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                          <Table.Summary.Cell />
                          <Table.Summary.Cell />
                          <Table.Summary.Cell align="right">
                            <Text>{CURRENCY + formatPrice(!!order.delivery.cost ? order.delivery.cost : 0)}</Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        {!!order.vat ? (
                          <>
                            <Table.Summary.Row>
                              <Table.Summary.Cell>
                                <Text>{t("vat")}</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="center" />
                              <Table.Summary.Cell align="center" />
                              <Table.Summary.Cell align="center" />
                              <Table.Summary.Cell align="right">
                                <Text>{CURRENCY + formatPrice(orderTax.taxPrice)}</Text>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                              <Table.Summary.Cell>
                                <Text strong={true}>{t("total")}</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="center" />
                              <Table.Summary.Cell align="center">
                                <Text strong={true}>{totalAmount}</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="center">
                                <Text strong={true}>{CURRENCY + formatPrice(totalDiscount)}</Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell align="right">
                                <Text strong={true}>{CURRENCY + formatPrice(orderTax.totalPriceInclTax)}</Text>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </>
                        ) : (
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              <Text strong={true}>{t("total")}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="center" />
                            <Table.Summary.Cell align="center">
                              <Text strong={true}>{totalAmount}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="center">
                              <Text strong={true}>{CURRENCY + formatPrice(totalDiscount)}</Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell align="right">
                              <Text strong={true}>{CURRENCY + formatPrice(order.totalPrice)}</Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                      </>
                    )
                  );
                }}
              />
            </div>
          </Drawer>
        )}
        <div style={{ display: "none" }}>
          {isPrinting && (
            <>
              <Barcode ref={barcodeRef} data={getBarcodeData(getFilteredOrders(null), products)} />
            </>
          )}
        </div>
      </Card>
      {!!orderMaxDaysAgoQuery && (
        <div>
          <p>{t("orders_last_period_explanation")}</p>
          <Button onClick={() => dispatch(setOrdersMaxDaysAgo(null))}>{t("retrieve_all_orders")}</Button>
        </div>
      )}
    </>
  );
};

export default Orders;
