export const PRODUCT_IMAGE_CROP_SIZE = 570;
export const PRODUCT_IMAGE_MAX_WIDTH = 850;
export const PRODUCT_IMAGE_COMPRESSION_QUALITY = 1;
export const PRODUCT_IMAGE_ASPECT_RATIO = 3 / 4;

export const PRODUCT_COLORS = [
  { name: "Black", id: "BLACK", img: "/img/products/colors/black.webp" },
  { name: "Blue", id: "BLUE", img: "/img/products/colors/blue.webp" },
  { name: "Brown", id: "BROWN", img: "/img/products/colors/brown.webp" },
  {
    name: "Butter",
    id: "BUTTER",
    img: "/img/products/colors/butter.webp",
  },
  { name: "Dark Smoke", id: "DARK SMOKE", img: "/img/products/colors/dark_smoke.webp" },
  { name: "Green", id: "GREEN", img: "/img/products/colors/green.webp" },
  { name: "Grey", id: "GREY", img: "/img/products/colors/grey.webp" },
  { name: "Indigo / Smoke", id: "INDIGO", img: "/img/products/colors/indigo.webp" },
  { name: "Pink", id: "PINK", img: "/img/products/colors/pink.webp" },
  { name: "Smoke", id: "SMOKE", img: "/img/products/colors/smoke.webp" },
  { name: "Smoke/Grey", id: "SMOKE_GREY", img: "/img/products/colors/smoke_grey.webp" },
  { name: "White", id: "WHITE", img: "/img/products/colors/white.webp" },
  { name: "Yellow / Gold", id: "GOLD", img: "/img/products/colors/gold.webp" },
];

export const PRODUCT_DIMENSION_TYPE_HEIGHT_DIAMETER = "HEIGHT_DIAMETER";
export const PRODUCT_DIMENSION_TYPE_LENGTH_WIDTH = "LENGTH_WIDTH";
export const PRODUCT_DIMENSION_TYPE_LENGTH_WIDTH_HEIGHT = "LENGTH_WIDTH_HEIGHT";
