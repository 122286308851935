import React, { useEffect, useState } from "react";
import { Card, Col, Descriptions, Divider, Drawer, Dropdown, Menu, Row, Select, Switch, Table, Tag } from "antd";
import { CheckOutlined, CloseOutlined, EyeOutlined } from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EllipsisDropdown from "../../../../components/shared-components/EllipsisDropdown";
import Modal from "antd/es/modal/Modal";
import styled from "styled-components";
import TextArea from "antd/es/input/TextArea";
import { useFirestore } from "react-redux-firebase";
import { sendWelcomeEmail } from "../../../../functions/api/mail";
import * as dayjs from "dayjs";
import { updateUser } from "../../../../functions/firestore/users";
import _ from "lodash";
import { FIRESTORE_USERS_TABLE } from "../../../../constants/FirestoreConstant";
import { formatAddress } from "../../../../functions/formatters";
import { addUserToMailchimp } from "../../../../functions/api/mailchimp";
import { getFirebaseIDToken } from "../../../../functions/firebase/firebase";

const { Option } = Select;

const REQUEST_STATUS_OPEN = "open";
const REQUEST_STATUS_IN_PROGRESS = "in_progress";
const REQUEST_STATUS_REJECTED = "rejected";
const REQUEST_STATUS_ACCEPTED = "accepted";

const PendingCustomers = () => {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const allUsers = useSelector((state) => state.firestoreReducer.ordered.webshopUsers);
  const salesPersonUsers = _.filter(allUsers, (user) => user.isSalesPerson);
  const [pendingList, setPendingList] = useState(null);
  const [userToReview, setUserToReview] = useState(null);
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [acceptModalVisible, setAcceptModalVisible] = useState(false);
  const [note, setNote] = useState(null);
  const [drawerUser, setDrawerUser] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(null);
  const [isSalesPerson, setIsSalesPerson] = useState(false);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(null);

  useEffect(() => {
    if (!!allUsers) {
      const pendingCustomers = allUsers.filter((item) => !item.authorization?.isReviewed);
      setPendingList(pendingCustomers);
    }
  }, [allUsers]);

  const acceptUser = () => {
    const authorizationObj = {
      isReviewed: true,
      isAuthorized: true,
      note: !!note ? note : null,
      updatedAt: dayjs().unix(),
      updatedBy: auth.email,
      status: REQUEST_STATUS_ACCEPTED,
    };

    updateUser(userToReview.id, {
      authorization: authorizationObj,
      salesPerson: !!selectedSalesPerson && !isSalesPerson ? selectedSalesPerson : null,
      isSalesPerson: isSalesPerson,
      lastOrderTimestamp: null,
      allowMarketingMails: true,
    })
      .then(() => {
        sendWelcomeEmail(
          userToReview.email,
          userToReview.firstName,
          userToReview.locale,
          userToReview.createdBySalesPerson,
        );
        getFirebaseIDToken().then((token) => {
          addUserToMailchimp({
            email: userToReview.email,
            firstName: userToReview.firstName,
            lastName: userToReview.lastName,
            countryCode: userToReview.company.country,
            token: token,
          });
        });
        setAcceptModalVisible(false);
        setUserToReview(null);
        setNote(null);
        setSelectedSalesPerson(null);
        setIsSalesPerson(false);
      })
      .catch((err) => console.log(err));
  };

  const rejectUser = () => {
    const authorizationObj = {
      isReviewed: true,
      isAuthorized: false,
      note: !!note ? note : null,
      updatedAt: dayjs().unix(),
      updatedBy: auth.email,
      status: REQUEST_STATUS_REJECTED,
    };

    updateUser(userToReview.id, { authorization: authorizationObj })
      .then(() => {
        setRejectModalVisible(false);
        setUserToReview(null);
        setNote(null);
      })
      .catch((err) => console.log(err));
  };

  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          setDrawerUser(row);
          setDrawerVisible(true);
        }}
      >
        <Flex alignItems="center">
          <EyeOutlined />
          <span style={{ marginLeft: 4 }}>{t("details")}</span>
        </Flex>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setUserToReview(row);
          if (!!row.salesPerson) setSelectedSalesPerson(row.salesPerson);
          setAcceptModalVisible(true);
        }}
      >
        <Flex alignItems="center">
          <CheckOutlined />
          <span style={{ marginLeft: 4 }}>{t("accept")}</span>
        </Flex>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setUserToReview(row);
          setRejectModalVisible(true);
        }}
      >
        <Flex alignItems="center">
          <CloseOutlined />
          <span style={{ marginLeft: 4 }}>{t("reject")}</span>
        </Flex>
      </Menu.Item>
    </Menu>
  );

  const updateRequestStatus = (user, status) => {
    const object = {
      ["authorization.updatedAt"]: dayjs().unix(),
      ["authorization.updatedBy"]: auth.email,
      ["authorization.status"]: status,
    };
    firestore.collection(FIRESTORE_USERS_TABLE).doc(user.id).update(object);
  };

  const requestStatusMenu = (user) => (
    <Menu>
      {[REQUEST_STATUS_OPEN, REQUEST_STATUS_IN_PROGRESS]
        .filter((item) => item !== user?.authorization?.status)
        .map((item, key) => {
          return (
            <Menu.Item key={key} onClick={() => updateRequestStatus(user, item)}>
              {t(item)}
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const tableColumns = [
    {
      title: t("date"),
      render: (_, data) => <span>{dayjs.unix(data.created_at).format("D MMM HH:mm")}</span>,
    },
    {
      title: t("company_name"),
      render: (_, data) => <span>{data.company?.name}</span>,
    },
    {
      title: t("vat_number"),
      render: (_, data) => <span>{data.company?.vatNumber || "-"}</span>,
    },
    {
      title: t("country"),
      render: (_, data) => <span>{data.company?.country}</span>,
    },
    {
      title: t("company_location"),
      render: (_, data) => <span>{data.company?.city}</span>,
    },
    {
      title: t("physical_store"),
      render: (_, data) => <span>{data.company?.hasPhysicalStore ? t("yes") : t("no")}</span>,
    },
    {
      title: t("name"),
      render: (_, data) => <span>{data.firstName + " " + data.lastName}</span>,
    },
    {
      title: t("phone"),
      dataIndex: "phoneNumber",
      render: (data) => <span>{data}</span>,
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (_, user) => {
        const status = user?.authorization?.status;
        return (
          <Dropdown overlay={requestStatusMenu(user)} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Tag color={!status || status === REQUEST_STATUS_OPEN ? "blue" : "yellow"}>
                {!!status ? t(status) : t("open")}
              </Tag>
            </a>
          </Dropdown>
        );
      },
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  return (
    <>
      <Card>
        <div className="table-responsive">
          <Table
            columns={tableColumns}
            dataSource={pendingList?.length > 0 ? pendingList : []}
            rowKey="email"
            scroll={{ x: 1300 }}
          />
        </div>
      </Card>
      <Modal
        title={!!userToReview && userToReview.company.name + " " + t("reject")}
        visible={rejectModalVisible}
        onOk={rejectUser}
        onCancel={() => {
          setUserToReview(null);
          setRejectModalVisible(false);
          setNote(null);
        }}
        okText={t("reject")}
        cancelText={t("close")}
      >
        <InputLabel>{t("note")}</InputLabel>
        <TextArea placeholder={t("optional")} value={note} onChange={(e) => setNote(e.target.value)} />
      </Modal>
      <Modal
        title={!!userToReview && userToReview.company.name + " " + t("accept")}
        visible={acceptModalVisible}
        onOk={acceptUser}
        onCancel={() => {
          setUserToReview(null);
          setAcceptModalVisible(false);
          setNote(null);
          setSelectedSalesPerson(null);
        }}
        okText={t("accept")}
        cancelText={t("close")}
      >
        <InputLabel>{t("note")}</InputLabel>
        <TextArea placeholder={t("optional")} value={note} onChange={(e) => setNote(e.target.value)} />
        <Row className={"mt-3"} gutter={8}>
          <Col lg={8}>
            <InputLabel>{t("is_sales_person")}</InputLabel>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={isSalesPerson}
              onChange={setIsSalesPerson}
            />
          </Col>
          <Col lg={16}>
            {!isSalesPerson && (
              <>
                <InputLabel>{t("sales_person")}</InputLabel>
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={true}
                  className="w-100"
                  onChange={setSelectedSalesPerson}
                  value={selectedSalesPerson}
                >
                  <Option key={0} value={null} className={"w-100"}>
                    {t("none")}
                  </Option>
                  {salesPersonUsers.map((user) => (
                    <Option key={user.id} value={user.id} className={"w-100"}>
                      {user.firstName + " " + user.lastName + " - " + user.company.name}
                    </Option>
                  ))}
                </Select>
              </>
            )}
          </Col>
        </Row>
      </Modal>
      <Drawer
        title={!!drawerUser && drawerUser.company.name}
        placement="right"
        onClose={() => {
          setDrawerVisible(false);
          setDrawerUser(null);
        }}
        visible={drawerVisible}
        width={550}
      >
        {!!drawerUser && (
          <>
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label={t("date")}>
                {dayjs.unix(drawerUser.created_at).format("D MMM YYYY HH:mm")}
              </Descriptions.Item>
              <Descriptions.Item label={t("company_name")}>{drawerUser.company.name}</Descriptions.Item>
              <Descriptions.Item label={t("country")}>{drawerUser.company.country}</Descriptions.Item>
              {!!drawerUser.address && !!drawerUser.address.delivery && (
                <Descriptions.Item label={t("address")}>{formatAddress(drawerUser.address.delivery)}</Descriptions.Item>
              )}
              <Descriptions.Item label={t("website_url")}>{drawerUser.company.websiteURL}</Descriptions.Item>
              <Descriptions.Item label={t("physical_store")}>
                {drawerUser.company.hasPhysicalStore ? t("yes") : t("no")}
              </Descriptions.Item>
              <Descriptions.Item label={t("vat_number")}>
                {!!drawerUser.company.vatNumber ? drawerUser.company.vatNumber : "-"}
              </Descriptions.Item>
              <Descriptions.Item label={t("first_name")}>{drawerUser.firstName}</Descriptions.Item>
              <Descriptions.Item label={t("last_name")}>{drawerUser.lastName}</Descriptions.Item>
              <Descriptions.Item label={t("email")}>{drawerUser.email}</Descriptions.Item>
              <Descriptions.Item label={t("phone")}>{drawerUser.phoneNumber}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label={t("status")}>
                {!!drawerUser.authorization.status ? t(drawerUser.authorization.status) : t("open")}
              </Descriptions.Item>
              <Descriptions.Item label={t("status_edited_at")}>
                {!!drawerUser.authorization.updatedAt
                  ? dayjs.unix(drawerUser.authorization.updatedAt).format("DD-MM-YYYY HH:mm")
                  : "-"}
              </Descriptions.Item>
              <Descriptions.Item label={t("in_progress_by")}>
                {!!drawerUser.authorization.updatedBy ? drawerUser.authorization.updatedBy : "-"}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
      </Drawer>
    </>
  );
};

const InputLabel = styled.p`
  color: #333;
  margin-bottom: 0;
`;

export default PendingCustomers;
