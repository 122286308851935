import { EMAIL_API_FAILURE_RESPONSE } from "../../constants/ResponseConstant";
import { addUsersMailchimp } from "../../urls";
import axios from "axios";

export const addUserToMailchimp = ({ email, firstName, lastName, countryCode, token }) => {
  const object = {
    email: email.toLowerCase(),
    firstName: firstName,
    lastName: lastName,
    locale: ["NL", "BE"].includes(countryCode) ? "nl" : "en",
    tags: [formatCountryCodeToName(countryCode)],
  };
  const config = {
    headers: {
      Authorization: token,
    },
  };
  axios.post(addUsersMailchimp, [object], config).catch(() => console.error(EMAIL_API_FAILURE_RESPONSE));
};

const formatCountryCodeToName = (countryCode) => {
  switch (countryCode) {
    case "NL":
      return "Nederland";
    case "BE":
      return "België";
    case "DE":
      return "Duitsland";
    case "FR":
      return "Frankrijk";
    case "GB":
      return "Verenigd Koninkrijk";
    case "AT":
      return "Oostenrijk";
    case "CH":
      return "Zwitserland";
    case "DK":
      return "Denemarken";
    case "ES":
      return "Spanje";
    case "IT":
      return "Italië";
    case "NO":
      return "Noorwegen";
    case "SE":
      return "Zweden";
    case "US":
      return "Verenigde Staten";
    case "CA":
      return "Canada";
    case "AU":
      return "Australië";
    case "NZ":
      return "Nieuw-Zeeland";
    case "IE":
      return "Ierland";
    case "LU":
      return "Luxemburg";
    case "PT":
      return "Portugal";
    case "FI":
      return "Finland";
    case "PL":
      return "Polen";
    case "CZ":
      return "Tsjechië";
    case "GR":
      return "Griekenland";
    case "HU":
      return "Hongarije";
    case "IS":
      return "IJsland";
    case "JP":
      return "Japan";
    case "KR":
      return "Zuid-Korea";
    case "MX":
      return "Mexico";
    case "RU":
      return "Rusland";
    case "SG":
      return "Singapore";
    case "TR":
      return "Turkije";
    case "CN":
      return "China";
    case "HK":
      return "Hongkong";
    case "IN":
      return "India";
    case "ID":
      return "Indonesië";
    case "MY":
      return "Maleisië";
    case "PH":
      return "Filipijnen";
    case "TH":
      return "Thailand";
    case "VN":
      return "Vietnam";
    case "AR":
      return "Argentinië";
    case "BR":
      return "Brazilië";
    case "SK":
      return "Slowakije";
    case "KW":
      return "Koeweit";
    case "CY":
      return "Cyprus";
    case "LI":
      return "Liechtenstein";
    case "MA":
      return "Marokko";
    case "RS":
      return "Servië";
    case "EG":
      return "Egypte";
    case "UA":
      return "Oekraïne";
    case "BG":
      return "Bulgarije";
    case "AN":
      return "Nederlandse Antillen";
    case "AM":
      return "Armenië";
    case "RO":
      return "Roemenië";
    case "HR":
      return "Kroatië";
    case "IL":
      return "Israël";
    case "LV":
      return "Letland";
    case "SI":
      return "Slovenië";
    case "CS":
      return "Tsjecho-Slowakije";
    case "LT":
      return "Litouwen";
    case "AE":
      return "Verenigde Arabische Emiraten";
    default:
      return countryCode;
  }
};
