import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import SideNav from "components/layout-components/SideNav";
import TopNav from "components/layout-components/TopNav";
import MobileNav from "components/layout-components/MobileNav";
import HeaderNav from "components/layout-components/HeaderNav";
import PageHeader from "components/layout-components/PageHeader";
import Footer from "components/layout-components/Footer";
import AppViews from "views/app-views";
import { Grid, Layout } from "antd";

import navigationConfig from "configs/NavigationConfig";
import { NAV_TYPE_SIDE, NAV_TYPE_TOP } from "constants/ThemeConstant";
import utils from "utils";
import { useFirestoreConnect } from "react-redux-firebase";
import {
  FIRESTORE_CARTS_TABLE,
  FIRESTORE_CATEGORIES_TABLE,
  FIRESTORE_COLLECTIONS_TABLE,
  FIRESTORE_CONFIG,
  FIRESTORE_DISCOUNT_CODES_TABLE,
  FIRESTORE_FEATURED_PRODUCTS_TABLE,
  FIRESTORE_GROUPS_TABLE,
  FIRESTORE_ORDERS_TABLE,
  FIRESTORE_PRODUCTS_TABLE,
  FIRESTORE_PROMOTIONS_TABLE,
  FIRESTORE_PURCHASE_ORDERS_TABLE,
  FIRESTORE_USERS_TABLE,
} from "../../constants/FirestoreConstant";
import * as dayjs from "dayjs";
import _ from "lodash";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location, direction }) => {
  const queryModifications = useSelector((state) => state.queries);
  const initialQueries = [
    { collection: FIRESTORE_PRODUCTS_TABLE },
    { collection: FIRESTORE_PROMOTIONS_TABLE },
    { collection: FIRESTORE_DISCOUNT_CODES_TABLE },
    {
      collection: FIRESTORE_USERS_TABLE,
      where: ["role", "==", "webshopUser"],
      storeAs: "webshopUsers",
    },
    {
      collection: FIRESTORE_USERS_TABLE,
      where: ["isSalesPerson", "==", true],
      storeAs: "salesPersons",
    },
    { collection: FIRESTORE_GROUPS_TABLE },
    { collection: FIRESTORE_COLLECTIONS_TABLE },
    { collection: FIRESTORE_FEATURED_PRODUCTS_TABLE },
    { collection: FIRESTORE_CATEGORIES_TABLE },
    { collection: FIRESTORE_CARTS_TABLE },
    { collection: FIRESTORE_PURCHASE_ORDERS_TABLE },
    { collection: FIRESTORE_CONFIG },
  ];
  const [allQueries, setAllQueries] = useState(initialQueries);

  // Fetch all collections from Firestore and put in redux store
  useFirestoreConnect(allQueries);
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname);
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;

  useEffect(() => {
    if (!!queryModifications) {
      const queryClone = _.clone(allQueries);
      if (!!queryModifications.ordersMaxDaysAgo) {
        const orderDaysAgoTimestamp = dayjs().subtract(queryModifications.ordersMaxDaysAgo, "days").unix();
        queryClone.push({
          collection: FIRESTORE_ORDERS_TABLE,
          where: ["createdAt", ">=", orderDaysAgoTimestamp],
        });
        setAllQueries(queryClone);
      } else {
        _.remove(queryClone, (item) => item.collection === FIRESTORE_ORDERS_TABLE);
        queryClone.push({
          collection: FIRESTORE_ORDERS_TABLE,
        });
        setAllQueries(queryClone);
      }
    }
  }, [queryModifications]);

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        {isNavSide && !isMobile ? <SideNav routeInfo={currentRouteInfo} /> : null}
        <Layout className="app-layout" style={{ paddingLeft: 0, minHeight: "100vh" }}>
          <div className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <AppViews />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
