import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import Users from "./users";
import Groups from "./groups";
import Collections from "./collections";

const PARENT_URL = "/cms/admin";

const Admin = () => (
  <Suspense fallback={<Loading cover="content" />}>
    <Routes>
      <Route path={`/users`} element={<Users />} />
      <Route path={`/groups`} element={<Groups />} />
      <Route path={`/collections`} element={<Collections />} />
      <Route path={``} element={<Navigate to={`${PARENT_URL}/users`} replace />} />
    </Routes>
  </Suspense>
);

export default Admin;
