import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProductList from "./product-list";
import AddProduct from "./add-product";
import EditProduct from "./edit-product";
import FeaturedProducts from "./featured-products";

const PARENT_URL = "/cms/products";

const Products = () => {
  return (
    <Routes>
      <Route path={`/add-product`} element={<AddProduct />} />
      <Route path={`/edit-product/:id`} element={<EditProduct />} />
      <Route path={`/product-list`} element={<ProductList />} />
      <Route path={`/featured`} element={<FeaturedProducts />} />
      <Route path={``} element={<Navigate to={`${PARENT_URL}/product-list`} replace />} />
    </Routes>
  );
};

export default Products;
