import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tooltip,
} from "antd";
import { DeleteOutlined, EditOutlined, InfoCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Flex from "components/shared-components/Flex";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import {
  FIRESTORE_COLLECTIONS_TABLE,
  FIRESTORE_GROUPS_TABLE,
  FIRESTORE_PRODUCTS_TABLE,
} from "../../../../constants/FirestoreConstant";
import EllipsisDropdown from "../../../../components/shared-components/EllipsisDropdown";
import { getUsersForSelectGroupType, getUsersWithoutGroup } from "../../../../functions/groups";
import _ from "lodash";
import styled from "styled-components";
import { GROUP_TYPE_SETTINGS, GROUP_TYPES } from "../../../../constants/GroupConstants";
import { getAuthorizedUsers } from "../../../../functions/users";
import { formatGroupType } from "../../../../functions/formatters";

const { Option } = Select;

const Groups = () => {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const groups = useSelector((state) => state.firestoreReducer.ordered.groups);
  const webshopUsers = useSelector((state) => state.firestoreReducer.ordered.webshopUsers);
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const collections = useSelector((state) => state.firestoreReducer.ordered.collections);
  const [addGroupForm] = Form.useForm();
  const [editGroupForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [addGroupModalVisible, setAddGroupModalVisible] = useState(false);
  const [editGroupModalVisible, setEditGroupModalVisible] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState(null);
  const authorizedUsers = getAuthorizedUsers(webshopUsers);
  const [usersForSelect, setUsersForSelect] = useState([]);
  const [groupType, setGroupType] = useState(null);

  useEffect(() => {
    const activeUsers = getUsersWithoutGroup(webshopUsers, groups);
    setUsersForSelect(activeUsers);
  }, [groups]);

  useEffect(() => {
    if (!!groupType && !groupToEdit) {
      calculateAndSetUsersForSelect(groupType, null);
    }
  }, [groupType]);

  const calculateAndSetUsersForSelect = (groupType, groupId) => {
    const filteredUsers = getUsersForSelectGroupType(authorizedUsers, groups, groupType, !!groupId ? groupId : null);
    setUsersForSelect(filteredUsers);
  };

  const addUser = () => {
    setAddGroupModalVisible(true);
  };

  const openEditModal = (group) => {
    setGroupToEdit(group);
    let selectedUsers = [];
    calculateAndSetUsersForSelect(group.type, group.id);
    if (!!group.customers) {
      group.customers.forEach((item) => {
        selectedUsers.push(item);
      });
    }
    editGroupForm.setFieldsValue({
      customers: selectedUsers,
      name: group.name,
      min_order_amount: group.minOrderAmount,
      type: group.type,
      price_percentage_modification: group.pricePercentageModification,
      selectable_for_sellers: group.selectableForSellers,
    });
    setGroupType(group.type);
    setEditGroupModalVisible(true);
  };

  const handleUpdateGroup = () => {
    let values = editGroupForm.getFieldsValue();
    let object = {
      name: values.name,
      customers: !!values.customers && values.customers.length > 0 ? values.customers : null,
      minOrderAmount: !!values.min_order_amount ? values.min_order_amount : null,
      type: values.type,
      pricePercentageModification: !!values.price_percentage_modification ? values.price_percentage_modification : null,
      selectableForSellers: values.selectable_for_sellers || false,
    };
    firestore
      .collection(FIRESTORE_GROUPS_TABLE)
      .doc(groupToEdit.id)
      .update(object)
      .then(() => {
        closeEditModal();
        messageApi.success(t("group_saved"), 2);
      });
  };

  const deleteGroup = (group) => {
    firestore
      .collection(FIRESTORE_GROUPS_TABLE)
      .doc(group.id)
      .delete()
      .then((r) => {
        // Delete group from all products that it is associated to
        let batch = firestore.batch();

        products.forEach((item) => {
          let groupAlternatives = item.groupAlternatives;
          if (!!groupAlternatives) {
            let alternativeIndex = _.findIndex(groupAlternatives, { groupId: group.id });
            let cloneAlternatives = _.clone(groupAlternatives);
            if (alternativeIndex >= 0) {
              cloneAlternatives.splice(alternativeIndex, 1);
            }
            let updateObject = cloneAlternatives.length === 0 ? null : cloneAlternatives;
            let productRef = firestore.collection(FIRESTORE_PRODUCTS_TABLE).doc(item.id);
            batch.update(productRef, { groupAlternatives: updateObject });
          }
        });

        collections.forEach((item) => {
          let hiddenForGroups = item.hiddenForGroups;
          if (!!hiddenForGroups && hiddenForGroups.length > 0 && hiddenForGroups.includes(group.id)) {
            let clone = _.clone(hiddenForGroups);
            let newHiddenGroups = _.remove(clone, (n) => n !== group.id);
            if (newHiddenGroups.length === 0) newHiddenGroups = null;
            let collectionRef = firestore.collection(FIRESTORE_COLLECTIONS_TABLE).doc(item.id);
            batch.update(collectionRef, { hiddenForGroups: newHiddenGroups });
          }
        });

        batch
          .commit()
          .then(() => messageApi.success(t("group_removed")))
          .catch((error) => console.log(error));
      });
  };

  const closeAddModal = () => {
    setAddGroupModalVisible(false);
    setGroupType(null);
    addGroupForm.resetFields();
  };

  const closeEditModal = () => {
    setEditGroupModalVisible(false);
    setGroupToEdit(null);
    setUsersForSelect([]);
    setGroupType(null);
    editGroupForm.resetFields();
  };

  const handleAddGroup = () => {
    addGroupForm.validateFields().then((values) => {
      let object = {
        name: values.name,
        customers: !!values.customers ? values.customers : null,
        minOrderAmount: !!values.minOrderAmount ? values.minOrderAmount : null,
        type: values.type,
        pricePercentageModification: !!values.price_percentage_modification
          ? values.price_percentage_modification
          : null,
        selectableForSellers: !!values.selectable_for_sellers,
      };
      firestore
        .collection(FIRESTORE_GROUPS_TABLE)
        .add(object)
        .then((response) => {
          closeAddModal();
        })
        .catch((err) => messageApi.error(err.message));
    });
  };

  const tableColumns = [
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("type"),
      dataIndex: "type",
      render: (type) => <span>{formatGroupType(type)}</span>,
    },
    {
      title: t("customers"),
      dataIndex: "customers",
      render: (customers) => <span>{!!customers ? customers.length : 0}</span>,
      sorter: {
        compare: (a, b) => a.customers.length - b.customers.length,
      },
    },
    {
      title: t("selectable_for_sellers_short"),
      dataIndex: "selectableForSellers",
      render: (selectable) => <span>{selectable ? t("yes") : t("no")}</span>,
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, row) => (
        <div className="text-right">
          <EllipsisDropdown
            menu={
              <Menu>
                <Menu.Item onClick={() => openEditModal(row)}>
                  <Flex alignItems="center">
                    <EditOutlined />
                    <span style={{ marginLeft: 4 }}>{t("edit")}</span>
                  </Flex>
                </Menu.Item>
                <Menu.Item>
                  <Flex alignItems="center">
                    <DeleteOutlined />
                    <Popconfirm
                      placement="bottom"
                      title={t("confirm_group_user", {
                        name: row.name,
                        customer_amount: !!row.customers ? row.customers.length : 0,
                      })}
                      onConfirm={() => deleteGroup(row)}
                      okText={t("yes")}
                      cancelText={t("no")}
                    >
                      <span style={{ marginLeft: 4 }}>{t("delete")}</span>
                    </Popconfirm>
                  </Flex>
                </Menu.Item>
              </Menu>
            }
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Card>
        <Flex alignItems="between" justifyContent="between" mobileFlex={false}>
          <Flex alignItems="end" className="mb-1" mobileFlex={false}>
            <div>
              <Button onClick={addUser} type="primary" icon={<PlusCircleOutlined />} block>
                {t("add_group")}
              </Button>
            </div>
          </Flex>
        </Flex>
        <div className="table-responsive">
          <Table columns={tableColumns} dataSource={groups} rowKey="id" />
        </div>
        <Modal
          title={t("add_group")}
          visible={addGroupModalVisible}
          onCancel={() => closeAddModal()}
          onOk={handleAddGroup}
          okText={t("save")}
          cancelText={t("close")}
        >
          <Form layout="vertical" form={addGroupForm} name="advanced_search" className="ant-advanced-search-form">
            <Form.Item
              name="name"
              label={t("group_name")}
              rules={[
                {
                  required: true,
                  message: t("form.enter_group_name"),
                },
              ]}
            >
              <Input
                placeholder={t("name")}
                suffix={
                  <Tooltip title={t("form.add_group_name_tooltip")}>
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item
              name="type"
              label={t("group_type")}
              rules={[
                {
                  required: true,
                  message: t("form.choose_group_type"),
                },
              ]}
            >
              <Select placeholder={t("group_type")} onChange={(type) => setGroupType(type)}>
                {GROUP_TYPES.map((type, index) => {
                  return (
                    <Option value={type} key={index}>
                      {t(type)}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="customers" label={t("customers")}>
              <Select
                mode="multiple"
                placeholder={!!groupType ? t("customers") : t("form.choose_group_type_first")}
                disabled={!groupType}
                showSearch
                optionFilterProp="children"
              >
                {usersForSelect.length > 0 &&
                  usersForSelect.map((user, index) => {
                    return (
                      <Option value={user.id} key={index}>
                        {!!user.company && user.company.name + " | "} {user.email}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            {!!groupType && groupType === GROUP_TYPE_SETTINGS && (
              <>
                <FormSectionLabel>{t("settings")}</FormSectionLabel>
                <Form.Item name="min_order_amount" label={t("min_order_amount")}>
                  <InputNumber placeholder={t("min_order_amount")} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item name="price_percentage_modification" label={t("price_percentage_modification")}>
                  <InputNumber
                    placeholder={t("price_percentage_modification")}
                    style={{ width: "100%" }}
                    min={-30}
                    max={100}
                  />
                </Form.Item>
                <Form.Item name="selectable_for_sellers" label={t("selectable_for_sellers")} valuePropName="checked">
                  <Switch />
                </Form.Item>
              </>
            )}
          </Form>
        </Modal>

        {editGroupModalVisible && (
          <Modal
            title={t("edit_group")}
            visible={editGroupModalVisible}
            onCancel={() => closeEditModal()}
            onOk={handleUpdateGroup}
            okText={t("save")}
            cancelText={t("close")}
          >
            <Form layout="vertical" form={editGroupForm} name="advanced_search" className="ant-advanced-search-form">
              <Form.Item
                name="name"
                label={t("group_name")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_group_name"),
                  },
                ]}
              >
                <Input placeholder={t("group_name")} />
              </Form.Item>
              <Form.Item
                name="type"
                label={t("group_type")}
                rules={[
                  {
                    required: true,
                    message: t("form.choose_group_type"),
                  },
                ]}
              >
                <Select placeholder={t("group_type")} onChange={(type) => setGroupType(type)} disabled>
                  {GROUP_TYPES.map((type, index) => {
                    return (
                      <Option value={type} key={index}>
                        {t(type)}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item name="customers" label={t("customers")}>
                <Select mode="multiple" placeholder={t("customers")} showSearch optionFilterProp="children">
                  {usersForSelect.map((user, index) => {
                    return (
                      <Option value={user.id} key={index}>
                        {user.company.name} | {user.email}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {!!groupType && groupType === GROUP_TYPE_SETTINGS && (
                <>
                  <FormSectionLabel>{t("settings")}</FormSectionLabel>
                  <Form.Item name="min_order_amount" label={t("min_order_amount")}>
                    <InputNumber placeholder={t("min_order_amount")} style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item name="price_percentage_modification" label={t("price_percentage_modification")}>
                    <InputNumber
                      placeholder={t("price_percentage_modification")}
                      style={{ width: "100%" }}
                      min={-30}
                      max={100}
                    />
                  </Form.Item>
                  <Form.Item name="selectable_for_sellers" label={t("selectable_for_sellers")} valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </>
              )}
            </Form>
          </Modal>
        )}
      </Card>
    </>
  );
};

const FormSectionLabel = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #333;
  text-align: center;
`;

export default Groups;
