import React, { useEffect, useState } from "react";
import { Card, Col, DatePicker, Row, Table } from "antd";
import Flex from "components/shared-components/Flex";
import AvatarStatus from "components/shared-components/AvatarStatus";
import DataDisplayWidget from "components/shared-components/DataDisplayWidget";
import ChartWidget from "components/shared-components/ChartWidget";
import { COLORS } from "constants/ChartConstant";
import utils from "utils";
import { useSelector } from "react-redux";
import { getSalesDashboardData } from "../../../functions/reports";
import { formatPrice } from "../../../functions/formatters";
import { CURRENCY } from "../../../constants/DeliveryConstants";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import i18n from "i18next";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  BarChartOutlined,
  CrownOutlined,
  EuroOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/nl_NL";
import { DATE_FORMAT_DD_MM_YYYY } from "../../../constants/DateConstant";
import { getOrdersInPeriod } from "../../../functions/firestore/orders";
import * as dayjs from "dayjs";

const { RangePicker } = DatePicker;

const RevenueChart = ({ data }) => {
  const { t } = useTranslation();
  const [revenueToday, setRevenueToday] = useState(0);
  const percentageChange = Math.round(((data.total - data.totalPrevPeriod) / data.totalPrevPeriod) * 100 * 100) / 100;
  useEffect(() => {
    if (!!data && !!data.totalToday) setRevenueToday(data.totalToday);
  }, [data]);

  if (!!data)
    return (
      <Card>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={8}>
            <Flex className="h-100" flexDirection="column" justifyContent="between">
              <div>
                <h4 className="mb-0">{t("revenue")}</h4>
                <span className="text-muted">{data.readableDateRange}</span>
              </div>
              <div className="mb-4">
                <h1 className="font-weight-bold text-nowrap">{CURRENCY + formatPrice(data.total)}</h1>
                {!data.customDates && (
                  <p className={percentageChange > 0 ? "text-success" : "text-danger"}>
                    <span>
                      {percentageChange > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                      <span>{`${!!percentageChange ? percentageChange : 0}%`} </span>
                    </span>
                  </p>
                )}
              </div>
              <div className="mb-4">
                <h4 className="font-weight-bold text-nowrap mb-0">{CURRENCY + formatPrice(revenueToday)}</h4>
                <span className={"text-muted"}>{t("today")}</span>
              </div>
            </Flex>
          </Col>
          <Col xs={24} sm={24} md={24} lg={16} style={{ paddingTop: 56 }}>
            <ChartWidget
              card={false}
              series={data.dataPoints}
              xAxis={data.labels}
              height={250}
              type="bar"
              customOptions={{ colors: COLORS }}
            />
          </Col>
        </Row>
      </Card>
    );
};

const DisplayDataSet = ({ data }) => {
  const { t } = useTranslation();

  if (!!data)
    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <DataDisplayWidget
            icon={<FileDoneOutlined />}
            value={data.totalOrders}
            title={t("orders")}
            color="cyan"
            vertical={true}
            avatarSize={55}
          />
          <DataDisplayWidget
            icon={<BarChartOutlined />}
            value={CURRENCY + formatPrice(data.totalRevenue / data.totalOrders)}
            title={t("average_order_value")}
            color="blue"
            vertical={true}
            avatarSize={55}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <DataDisplayWidget
            icon={<EuroOutlined />}
            value={CURRENCY + formatPrice(data.totalRevenue)}
            title={t("revenue")}
            color="gold"
            vertical={true}
            avatarSize={55}
          />
          <DataDisplayWidget
            icon={<CrownOutlined />}
            value={CURRENCY + formatPrice(!!data.bestOrder ? data.bestOrder.totalPrice : 0)}
            title={t("biggest_order")}
            color="purple"
            vertical={true}
            avatarSize={55}
          />
        </Col>
      </Row>
    );
};

const TopProduct = ({ data }) => {
  const { t } = useTranslation();
  const sales = data.sales.slice(0, 5);
  return (
    <StyledCard title={t("bestsellers")}>
      <span className="text-muted">{data.readableDateRange}</span>
      {sales.map((item) => (
        <Flex className="w-100 py-3" justifyContent="between" alignItems="center" key={item.name}>
          <AvatarStatus shape="square" src={item.img} name={item.name} subTitle={item.category} />
          <Flex>
            <div className="mr-3 text-right">
              <span className="text-muted">{t("revenue")}</span>
              <div className="mb-0 h5 font-weight-bold">{CURRENCY + formatPrice(item.totalRevenue)}</div>
            </div>
          </Flex>
        </Flex>
      ))}
    </StyledCard>
  );
};

const tableColumns = [
  {
    title: i18n.t("order.order_id"),
    dataIndex: "orderId",
  },
  {
    title: i18n.t("name"),
    dataIndex: "name",
    render: (_, record) => record.customer.firstName + " " + record.customer.lastName,
  },
  {
    title: i18n.t("date"),
    dataIndex: "date",
    render: (_, record) => <span>{dayjs.unix(record.createdAt).format("D MMM")}</span>,
  },
  {
    title: i18n.t("total"),
    dataIndex: "amount",
    render: (_, record) => (
      <span className="font-weight-semibold text-nowrap">{CURRENCY + formatPrice(record.totalPrice)}</span>
    ),
    sorter: (a, b) => utils.antdTableSorter(a, b, "amount"),
  },
];

const BestOrder = ({ data }) => {
  const { t } = useTranslation();
  return (
    <StyledCard title={t("biggest_sales")}>
      <span className="text-muted">{data.readableDateRange}</span>
      <Table
        pagination={false}
        columns={tableColumns}
        dataSource={data.bestOrders}
        rowKey="id"
        scroll={{ x: 100 }}
        style={{ marginTop: 12 }}
      />
    </StyledCard>
  );
};

const SalesDashboard = () => {
  const { t } = useTranslation();
  const orders = useSelector((state) => state.firestoreReducer.ordered.orders);
  const categories = useSelector((state) => state.firestoreReducer.ordered.categories);
  const [salesData, setSalesData] = useState(getSalesDashboardData({ orders, categories }));

  const onChangeRange = (value) => {
    if (!!value && value.length === 2) {
      const start = dayjs(value[0]).startOf("day").unix();
      const end = dayjs(value[1]).endOf("day").unix();
      getOrdersInPeriod(start, end).then((rangeOrders) => {
        setSalesData(getSalesDashboardData({ orders: rangeOrders, categories, start, end }));
      });
    }
  };

  return (
    <>
      <Row gutter={16} className={"mb-2"}>
        <Col xs={24} sm={24} md={16} lg={16} xl={15} xxl={14}>
          <PageTitle>{t("reports")}</PageTitle>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={9} xxl={10}>
          <RangePicker
            style={{ float: "right" }}
            onChange={onChangeRange}
            locale={locale}
            format={DATE_FORMAT_DD_MM_YYYY}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={16} xl={15} xxl={14}>
          <RevenueChart data={salesData.revenue} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={9} xxl={10}>
          <DisplayDataSet data={salesData.facts} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <TopProduct data={salesData.productSales} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <BestOrder data={salesData.bestOrders} />
        </Col>
      </Row>
    </>
  );
};

const StyledCard = styled(Card)`
  padding-top: 12px;
`;

const DatePeriod = styled.h4`
  font-size: 18px;
  float: right;
  color: dimgrey;
`;

const PageTitle = styled.h1`
  font-size: 20px;
`;

export default SalesDashboard;
