import React from "react";
import { saveAs } from "file-saver";
import XLSX from "xlsx";

const ExcelExport = ({ data, fileName, children }) => {
  const exportToExcel = () => {
    try {
      const workbook = XLSX.utils.book_new();

      // Handle the case where data is an array of sheets with columns and data
      if (Array.isArray(data) && data.length > 0 && data[0].columns && data[0].data) {
        // Process each sheet in the data array
        data.forEach((sheet, sheetIndex) => {
          // Create headers from columns
          const headers = sheet.columns.map((col) => col.title);

          // Transform the data to include headers
          const excelData = [headers, ...sheet.data.map((row) => row.map((cell) => cell.value))];

          // Create worksheet
          const worksheet = XLSX.utils.aoa_to_sheet(excelData);

          // Add worksheet to workbook with sheet name if provided
          const sheetName = sheet.sheetName || `Sheet${sheetIndex + 1}`;
          XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        });
      }
      // Fallback for simple data (single sheet)
      else {
        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      }

      // Generate and save the file
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, `${fileName}.xlsx`);
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  return <span onClick={exportToExcel}>{children}</span>;
};

export default ExcelExport;
