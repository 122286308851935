export const findCategoryBySlug = (categories, slug) => {
  for (let category of categories) {
    if (category.slug === slug) {
      return category;
    }
    if (category.subcategories) {
      let found = findCategoryBySlug(category.subcategories, slug);
      if (found) {
        return found;
      }
    }
  }
  return null; // Geen categorie gevonden met de gegeven slug
};

export const findCategoryById = (categories, id) => {
  for (let category of categories) {
    if (category.categoryId === id) {
      return category;
    }
    if (category.subcategories) {
      let found = findSubcategoryById(category.subcategories, id);
      if (found) {
        return { ...found, subcategory: true };
      }
    }
  }
  return null; // Geen categorie gevonden met het gegeven id
};

export const findSubcategoryById = (subcategories, id) => {
  for (let category of subcategories) {
    if (category.subcategoryId === id) {
      return category;
    }
  }
  return null; // Geen categorie gevonden met het gegeven id
};

// Find the main category by subcategory id
export const findMainCategoryBySubId = (categories, id) => {
  const category = categories.find((cat) => cat.subcategoryIds.includes(id));
  if (category) {
    return category;
  } else return null;
};

export const getProductsByCategory = (products, selectedCategory) => {
  if (!selectedCategory) return products;
  if (selectedCategory.categoryId) {
    return products.filter(
      (product) =>
        product.category === selectedCategory.categoryId || selectedCategory.subcategoryIds.includes(product.category),
    );
  } else if (selectedCategory.subcategoryId) {
    return products.filter((product) => product.category === selectedCategory.subcategoryId);
  } else {
    return products;
  }
};
