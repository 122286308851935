import React from "react";
import styled from "styled-components";

const CataloguePDF = React.forwardRef((props, ref) => {
  const data = props.data;

  const getDimensionsDescription = (height, diameter, length, width) => {
    if (!!length && !!width && !!height) {
      return `Length: ${length}cm\nWidth: ${width}cm\nHeight: ${height}cm\n`;
    } else if (!!height || !!diameter) {
      return `Height: ${height}cm\nDiameter: ${diameter}cm\n`;
    } else if (!!length || !!width) {
      return `Length: ${length}cm\nWidth: ${width}cm\n`;
    }
  };

  return (
    <div ref={ref}>
      {data.map((product, index) => {
        const imageSrc = !!product.image?.length > 0 ? product.image[0] : null;
        if (!!imageSrc)
          return (
            <Wrapper style={{ pageBreakBefore: "always" }} key={index}>
              <StyledImage src={imageSrc} alt={product.name} />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <InfoWrapper>
                  <p className={"code"}>{product.code}</p>
                  <p className={"dimensions"}>
                    {getDimensionsDescription(product.height, product.diameter, product.length, product.width)}
                  </p>
                </InfoWrapper>
                <LogoWrapper>
                  <img src={"/img/logo.webp"} />
                </LogoWrapper>
              </div>
            </Wrapper>
          );
      })}
    </div>
  );
});

const LogoWrapper = styled.div`
  img {
    object-fit: contain;
    width: 100%;
    max-width: 175px;
  }
`;

const InfoWrapper = styled.div`
  background-color: #846f55;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  font-size: 1rem;
  line-height: 1.5rem;
  width: fit-content;
  border-radius: 8px;

  p {
    margin-bottom: 0;
    white-space: pre-line;
    color: white;

    &.code {
      font-weight: 500;
    }
  }
`;

const StyledImage = styled.img`
  object-fit: contain;
  max-width: 80vw;
  max-height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div`
  position: relative;
  padding: 2rem;
  width: 100vw;
  height: 100vh;
`;

export default CataloguePDF;
