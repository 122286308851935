import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Card } from "antd";
import ApexChart from "react-apexcharts";
import {
  apexAreaChartDefaultOption,
  apexBarChartDefaultOption,
  apexLineChartDefaultOption,
} from "constants/ChartConstant";
import { useResizeDetector } from "react-resize-detector";
import { cloneDeep } from "lodash";

const titleStyle = {
  position: "absolute",
  zIndex: "1",
};

const extraStyle = {
  position: "absolute",
  zIndex: "1",
  right: "0",
  top: "-2px",
};

const getChartTypeDefaultOption = (type) => {
  switch (type) {
    case "line":
      return apexLineChartDefaultOption;
    case "bar":
      return apexBarChartDefaultOption;
    case "area":
      return apexAreaChartDefaultOption;
    default:
      return apexLineChartDefaultOption;
  }
};

const ChartWidget = ({ title, series, width, height, xAxis, customOptions, card, type, extra, bodyClass }) => {
  const [options, setOptions] = useState(getChartTypeDefaultOption(type));

  const isMobile = window.innerWidth < 768;
  const setLegendOffset = () => {
    if (chartWrapperRef.current) {
      const legend = chartWrapperRef.current.querySelectorAll("div.apexcharts-legend")[0];
      legend.style.marginRight = `${isMobile ? 0 : extraRef.current?.offsetWidth}px`;
      if (isMobile) {
        legend.style.position = "relative";
        legend.style.top = 0;
        legend.style.justifyContent = "start";
        legend.style.padding = 0;
      }
    }
  };

  useEffect(() => {
    setLegendOffset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Update xAxis in options state
    const newOptions = cloneDeep(options);
    newOptions.xaxis.categories = xAxis;
    setOptions(newOptions);
  }, [xAxis]);

  const extraRef = useRef(null);
  const chartWrapperRef = useRef();
  const chartRef = useRef();

  const onResize = useCallback(() => {
    setTimeout(() => {
      setLegendOffset();
    }, 600);
  }, []);

  const { w, h, resizeRef } = useResizeDetector({
    handleHeight: false,
    refreshMode: "debounce",
    refreshRate: 1000,
    onResize,
  });

  const renderChart = () => (
    <div ref={resizeRef}>
      <div className="chartRef" ref={chartWrapperRef}>
        <ApexChart options={options} type={type} series={series} width={width} height={height} ref={chartRef} />
      </div>
    </div>
  );
  return (
    <>
      {card ? (
        <Card>
          <div className={`position-relative ${bodyClass}`}>
            {<div style={!isMobile ? titleStyle : {}}>{title}</div> && (
              <h4 className="font-weight-bold" style={!isMobile ? titleStyle : {}}>
                {title}
              </h4>
            )}
            {extra && (
              <div ref={extraRef} style={!isMobile ? extraStyle : {}}>
                {extra}
              </div>
            )}
            {renderChart()}
          </div>
        </Card>
      ) : (
        renderChart()
      )}
    </>
  );
};

ChartWidget.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  series: PropTypes.array.isRequired,
  xAxis: PropTypes.array,
  customOptions: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  card: PropTypes.bool,
  type: PropTypes.string,
  extra: PropTypes.element,
  bodyClass: PropTypes.string,
};

ChartWidget.defaultProps = {
  series: [],
  height: 300,
  width: "100%",
  card: true,
  type: "line",
};

export default ChartWidget;
