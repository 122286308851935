import { Row } from "antd";
import { uniqueMergeArrays } from "functions/arrays";
import React from "react";
import { useSelector } from "react-redux";
import AddDiscount from "./AddDiscount";
import DiscountList from "./DiscountList";

const Promotions = () => {
  const products = useSelector((state) => state.firestoreReducer.ordered.products);
  const categories = uniqueMergeArrays(products, "category");
  return (
    <Row gutter={16}>
      <AddDiscount products={products} categories={categories}/>
      <DiscountList products={products} categories={categories}/>
    </Row>
  );
};

export default Promotions;
