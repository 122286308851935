import _ from "lodash";
import i18n from "translations/i18n";
import { DELIVERY_OPTIONS } from "constants/DeliveryConstants";
import { VARIATION_PIECES, VARIATION_WEIGHT } from "constants/VariationConstant";

export const getPickListData = function (data, allProducts) {
  let uniqueProducts = [];
  data.map((order) => {
    // Create array that will contain all product objects
    let products = [];
    order.products.map((product) => {
      let currentProduct = _.find(allProducts, ["id", product.productId]);

      let productList = !!currentProduct && currentProduct.productItems ? currentProduct.productItems : null;

      // If product contains a list of other products, we need those individual items in the picklist
      if (!!productList) {
        // For every item in the productList, push every individual product into the products array
        productList.map((item, index) => {
          let productById = _.find(allProducts, ["id", item.productId]);
          products.push({
            key: productById.id + index,
            name: productById.name,
            type: null,
            quantity: item.type === VARIATION_PIECES ? product.quantity * item.value : product.quantity,
            weight: item.type === VARIATION_WEIGHT ? item.value : null,
            amountPerOrder: item.type === VARIATION_PIECES ? [product.quantity * item.value] : [product.quantity],
            category: !!productById.category ? productById.category[0] : null,
          });
        });
      } else {
        // Product does not contain a productList, so just put the product into the products array;
        products.push({
          key: product.variantId,
          name: product.type ? product.name + " (" + product.type + ")" : product.name,
          type: product.type,
          quantity: product.quantity,
          weight: product.variation && product.variation.weight ? product.variation.weight : null,
          amountPerOrder: [product.quantity],
          category: product.category || null,
        });
      }
    });

    products.map((product) => {
      // For every product, check if it exists in the uniqueProducts array
      // Check for type, weight and name.
      // If it exists, increment the product based on quantity, if not add it to the array
      let uniqueProductIndex = _.findIndex(uniqueProducts, (o) => {
        if (product.type && product.weight)
          return o.type === product.type && o.weight === product.weight && o.name === product.name;
        else if (!product.type && product.weight) return o.weight === product.weight && o.name === product.name;
        else if (product.type && !product.weight) {
          return o.name === product.name;
        } else if (!product.type && !product.weight) return o.name === product.name;
      });
      if (uniqueProductIndex < 0) {
        uniqueProducts.push(product);
      } else {
        uniqueProducts[uniqueProductIndex].quantity += product.quantity;
        uniqueProducts[uniqueProductIndex].amountPerOrder.push(product.quantity);
      }
    });
  });

  return _.sortBy(uniqueProducts, (product) => product.category);
};

export const getSendListData = function (data) {
  let array = [];
  data.map((order) => {
    let customer = order.customer;
    let deliveryAddress = customer.address.delivery;
    let obj = {
      name: customer.firstName + " " + customer.lastName,
      street_and_number:
        deliveryAddress.street +
        " " +
        deliveryAddress.houseNumber +
        (!!deliveryAddress.addition ? deliveryAddress.addition : ""),
      zipcode_and_city: deliveryAddress.zipCode + " " + deliveryAddress.city,
      phone: customer.phoneNumber,
      deliveryType: order.delivery.type === DELIVERY_OPTIONS.SEND ? i18n.t("shipping") : i18n.t("pickup"),
      deliveryDate: order.delivery.date,
      orderId: order.orderId,
      orderNotes: order.orderNotes,
    };
    array.push(obj);
  });
  return array;
};

export const getBarcodeData = function (orders, products) {
  let array = [];
  orders.map((order) => {
    // Loop over products
    for (let product of order.products) {
      const dbProduct = products.find((dbProduct) => dbProduct.id === product.productId);
      if (dbProduct) {
        let obj = {
          name: dbProduct.name,
          code: dbProduct.code,
          barcode: dbProduct.barcode,
        };
        const amountInOuterBox = dbProduct.amountInOuterBox || 1;
        const amount = product.quantity / amountInOuterBox;
        for (let i = 0; i < amount; i++) {
          array.push(obj);
        }
      }
    }
  });
  return array;
};

export const getBarcodeDataExact = function (orders, products) {
  let array = [];
  orders.map((order) => {
    // Loop over products
    for (let product of order.SalesOrderLines.results) {
      const dbProduct = products.find((dbProduct) => dbProduct.code?.toLowerCase() === product.ItemCode?.toLowerCase());
      if (dbProduct) {
        let obj = {
          name: dbProduct.name,
          code: dbProduct.code,
          barcode: dbProduct.barcode,
        };
        const amountInOuterBox = dbProduct.amountInOuterBox || 1;
        const amount = product.Quantity / amountInOuterBox;
        for (let i = 0; i < amount; i++) {
          array.push(obj);
        }
      }
    }
  });
  return array;
};

export const calculateTaxFromExVAT = (
  products,
  deliveryCost,
  discountPercentage,
  discountAmount,
  discountCategories,
) => {
  let totalPriceIncludingTax = 0;
  let totalTax = 0;
  let taxRates = [];
  if (!!discountCategories) {
    products.forEach((item) => {
      let itemTaxRate = item.taxRate;
      const discountCategoriesContainsProductCategory = !!discountCategories
        ? discountCategories.filter((element) => item.category.includes(element))
        : false;

      const paymentPrice = item.paymentPrice * item.quantity;
      let paymentPriceMinusDiscount = paymentPrice;
      if (discountCategoriesContainsProductCategory)
        paymentPriceMinusDiscount = !!discountPercentage
          ? paymentPrice - (paymentPrice * discountPercentage) / 100
          : !!discountAmount
            ? paymentPrice - discountAmount * item.quantity
            : paymentPrice;

      const priceIncludingTax = (paymentPriceMinusDiscount * (itemTaxRate + 100)) / 100;
      const taxPrice = paymentPriceMinusDiscount * (itemTaxRate / 100);
      totalPriceIncludingTax += priceIncludingTax;
      totalTax += taxPrice;
      if (!taxRates.includes(itemTaxRate)) taxRates.push(itemTaxRate);
    });
  } else {
    products.forEach((item) => {
      let itemTaxRate = item.taxRate;
      const paymentPrice = item.paymentPrice * item.quantity;
      const paymentPriceMinusDiscount = !!discountPercentage
        ? paymentPrice - (paymentPrice * discountPercentage) / 100
        : paymentPrice;

      const priceIncludingTax = (paymentPriceMinusDiscount * (itemTaxRate + 100)) / 100;
      const taxPrice = paymentPriceMinusDiscount * (itemTaxRate / 100);
      totalPriceIncludingTax += priceIncludingTax;
      totalTax += taxPrice;
      if (!taxRates.includes(itemTaxRate)) taxRates.push(itemTaxRate);
    });
  }

  if (!!deliveryCost) {
    const deliveryTaxRate = taxRates.sort().reverse()[0];
    const priceIncludingTax = (deliveryCost * (deliveryTaxRate + 100)) / 100;
    const deliveryTax = deliveryCost * (deliveryTaxRate / 100);

    totalPriceIncludingTax += priceIncludingTax;
    totalTax += deliveryTax;
  }

  if (!discountCategories && !!discountAmount) {
    const amountTaxRate = taxRates.sort().reverse()[0];
    const priceIncludingTax = (discountAmount * (amountTaxRate + 100)) / 100;
    const discountAmountTax = discountAmount * (amountTaxRate / 100);

    totalPriceIncludingTax -= priceIncludingTax;
    totalTax -= discountAmountTax;
  }

  return { taxPrice: totalTax, totalPriceInclTax: totalPriceIncludingTax };
};
