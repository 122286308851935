import urls from "../../urls";

export const createExactClient = (account) => {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(account),
    };
    fetch(urls.exactAccount, requestOptions)
      .then((response) => {
        if (!response.ok) {
          response.text().then((text) => reject({ code: null, message: text }));
        } else resolve();
      })
      .catch((error) => {
        if (typeof error.json === "function") {
          error
            .json()
            .then((jsonError) => {
              reject({ code: null, message: jsonError });
            })
            .catch((genericError) => {
              reject({ code: null, message: genericError });
            });
        } else {
          reject({ code: 503, message: "Fetch error" });
        }
      });
  });
};

export const sendOrderToExact = (order, token) => {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: "POST",
      headers: { Authorization: token, "Content-Type": "application/json" },
      body: JSON.stringify(order),
    };
    fetch(urls.exactOrder, requestOptions)
      .then((response) => {
        if (!response.ok) {
          response.text().then((text) => reject({ code: null, message: text }));
        } else resolve();
      })
      .catch((error) => {
        if (typeof error.json === "function") {
          error
            .json()
            .then((jsonError) => {
              reject({ code: null, message: jsonError });
            })
            .catch((genericError) => {
              reject({ code: null, message: genericError });
            });
        } else {
          reject({ code: 503, message: "Fetch error" });
        }
      });
  });
};
