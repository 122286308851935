import React, { useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Menu,
  message,
  Modal,
  notification,
  Popconfirm,
  Select,
  Table,
  Tooltip,
} from "antd";
import { DeleteOutlined, EditOutlined, InfoCircleOutlined, PlusCircleOutlined, UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Flex from "components/shared-components/Flex";
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import { useFirestore } from "react-redux-firebase";
import _ from "lodash";
import urls from "urls";
import roles from "roles";

const { Option } = Select;

const Users = () => {
  const firestore = useFirestore();
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const cmsUsers = useSelector((state) => state.firestoreReducer.ordered.cmsUsers);
  const webshopUsers = useSelector((state) => state.firestoreReducer.ordered.webshopUsers);
  const [addUserForm] = Form.useForm();
  const [editUserForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [users, setUsers] = useState(cmsUsers);
  const [addUserModalVisible, setAddUserModalVisible] = useState(false);
  const [editUserModalVisible, setEditUserModalVisible] = useState(false);
  const [formEmail, setFormEmail] = useState("");
  const [userToEdit, setUserToEdit] = useState(null);
  const { t } = useTranslation();

  const rules = {
    email: [
      {
        required: true,
        type: "email",
        message: t("form.enter_user_email"),
      },
    ],
    role: [
      {
        required: true,
        message: t("form.enter_role"),
      },
    ],
  };

  const addUser = () => {
    setAddUserModalVisible(true);
  };

  const openEditModal = (user) => {
    setUserToEdit(user);
    setEditUserModalVisible(true);
  };

  const editUser = () => {
    let role = editUserForm.getFieldValue("editRole");
    if (!!role && userToEdit.role !== role) {
      firestore
        .collection("users")
        .doc(userToEdit.id)
        .update({ role: role })
        .then(() => {
          closeEditModal();
          messageApi.success(t("user_role_saved"), 2);
        });
    } else {
      closeEditModal();
    }
  };

  const closeAddModal = () => {
    setAddUserModalVisible(false);
    addUserForm.resetFields();
  };

  const closeEditModal = () => {
    setEditUserModalVisible(false);
    setUserToEdit(null);
    editUserForm.resetFields();
  };

  const deleteUser = (user) => {
    // Set user role to null and remove user from table
    firestore
      .collection("users")
      .doc(user.id)
      .update({ role: null, status: "deleted" })
      .then(() => {
        setUsers(users.filter((item) => item.id !== user.id));
        messageApi.success(t("user_deleted"), 2);
      });
  };

  const formatUserRole = (role) => {
    switch (role) {
      case roles.admin:
        return t("admin");
      case roles.user:
        return t("user");
    }
  };

  /**
   * Create the invite link for the user by encoding it.
   * @param id Id of the user.
   * @param email Email of invited user
   * @returns {string} The encoded invite link.
   */
  const getInviteLink = (id, email) => {
    let uri = id + "/" + email;
    let encodedUrl = btoa(uri);
    return urls.base + urls.acceptInvite + encodedUrl;
  };

  const handleInviteUser = () => {
    addUserForm.validateFields().then((values) => {
      let cmsUserExists = _.find(cmsUsers, ["email", values.email]);
      let webshopUserExists = _.find(webshopUsers, ["email", values.email]);
      if (!!cmsUserExists) {
        messageApi.error({ content: t("user_already_exists"), duration: 4 });
      } else if (!!webshopUserExists) {
        messageApi.error({
          content: t("user_already_exists_webshop"),
          duration: 6,
        });
      } else {
        firestore
          .collection("invitedUsers")
          .add(values)
          .then((response) => {
            let inviteLink = getInviteLink(response.id, values.email);
            fetch(urls.sendInvite, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: values.email,
                inviteLink: inviteLink,
              }),
            }).then((response) => {
              if (response.ok) {
                messageApi.success(t("user_received_email"));
              } else {
                messageApi.error(t("user_invite_email_failed"));
                showInviteLink(inviteLink);
              }
            });
            addUserForm.resetFields();
            closeAddModal();
          })
          .catch((err) => messageApi.error(err.message));
      }
    });
  };

  const showInviteLink = (inviteLink) => {
    const args = {
      message: t("user_invited"),
      description: t("user_invited_description", { link: inviteLink }),
      duration: 0,
    };
    notification.open(args);
  };

  const tableColumns = [
    {
      title: t("user"),
      dataIndex: "email",
      sorter: {
        compare: (a, b) => a.role.length - b.role.length,
      },
    },
    {
      title: t("role"),
      dataIndex: "role",
      render: (role) => <span>{formatUserRole(role)}</span>,
      sorter: {
        compare: (a, b) => a.role.length - b.role.length,
      },
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, row) => (
        <div className="text-right">
          <EllipsisDropdown
            menu={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    if (row.id !== auth.uid) openEditModal(row);
                    else messageApi.warning(t("notifications.cannot_edit_self"), 4);
                  }}
                >
                  <Flex alignItems="center">
                    <EditOutlined />
                    <span style={{ marginLeft: 4 }}>{t("edit")}</span>
                  </Flex>
                </Menu.Item>
                {row.id !== auth.uid && (
                  <Menu.Item>
                    <Flex alignItems="center">
                      <DeleteOutlined />
                      <Popconfirm
                        placement="bottom"
                        title={t("confirm_delete_user", { email: row.email })}
                        onConfirm={() => deleteUser(row)}
                        okText={t("yes")}
                        cancelText={t("no")}
                      >
                        <span style={{ marginLeft: 4 }}>{t("delete")}</span>
                      </Popconfirm>
                    </Flex>
                  </Menu.Item>
                )}
              </Menu>
            }
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Card>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 8 }}>
          <div>
            <Button onClick={addUser} type="primary" icon={<PlusCircleOutlined />} block>
              {t("add_user")}
            </Button>
          </div>
        </div>
        <div className="table-responsive">
          <Table columns={tableColumns} dataSource={users} rowKey="id" />
        </div>
        <Modal
          title={t("add_user")}
          visible={addUserModalVisible}
          onCancel={() => closeAddModal()}
          onOk={handleInviteUser}
          okText={t("invite")}
          cancelText={t("close")}
        >
          <Form
            layout="vertical"
            form={addUserForm}
            name="advanced_search"
            className="ant-advanced-search-form"
            initialValues={{
              role: roles.user,
            }}
          >
            <Form.Item name="email" label={t("email")} rules={rules.email}>
              <Input
                placeholder={t("email")}
                value={formEmail}
                onChange={(e) => setFormEmail(e.target.value)}
                prefix={<UserOutlined className="site-form-item-icon" />}
                suffix={
                  <Tooltip title={t("form.add_user_email_tooltip")}>
                    <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item name="role" label={t("role")} rules={rules.role}>
              <Select className="mr-2" defaultValue={roles.user} style={{ width: 120 }}>
                <Option value={roles.user}>{t("user")}</Option>
                <Option value={roles.admin}>{t("admin")}</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>

        {editUserModalVisible && (
          <Modal
            title={t("edit_user", { name: userToEdit.email })}
            visible={editUserModalVisible}
            onCancel={() => closeEditModal()}
            onOk={editUser}
            okText={t("save")}
            cancelText={t("close")}
          >
            <Form layout="vertical" form={editUserForm} name="advanced_search" className="ant-advanced-search-form">
              <Form.Item name="editEmail" label={t("email")} rules={rules.email}>
                <Input
                  placeholder={t("email")}
                  disabled
                  defaultValue={userToEdit.email}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
              <Form.Item name="editRole" label={t("role")} rules={rules.role}>
                <Select className="mr-2" style={{ width: 120 }} defaultValue={userToEdit.role}>
                  <Option value={roles.user}>{t("user")}</Option>
                  <Option value={roles.admin}>{t("admin")}</Option>
                </Select>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </Card>
    </>
  );
};

export default Users;
